/*
| Developed by Starton
| Filename : LogoType.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { StartonType } from '@starton/react-ui-iconography'
import { SxProps, Theme, useTheme } from '@mui/material'
import { useWhiteLabel } from '@/hooks/white-labelling/useWhiteLabel'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface LogoTypeProps {
	onClick?: React.MouseEventHandler
	sx?: SxProps<Theme>
	style?: React.CSSProperties
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const LogoType: React.FC<LogoTypeProps> = ({ sx, onClick, style }) => {
	const { logoType, name } = useWhiteLabel()
	const theme = useTheme()

	if (logoType === 'default') {
		return (
			<StartonType
				fill={theme.palette.text.primary}
				onClick={onClick}
				sx={{
					width: 'auto',
					...(sx || {}),
					...(onClick
						? {
								cursor: 'pointer',
							}
						: {}),
				}}
			/>
		)
	}

	return (
		<img
			src={logoType}
			alt={name}
			style={{
				width: 'auto',
				height: 'inherit',
				...(style || {}),
				...(onClick
					? {
							cursor: 'pointer',
						}
					: {}),
			}}
			onClick={onClick}
		/>
	)
}
