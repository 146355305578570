/*
| Developed by Starton
| Filename : DashboardProjectMenuItem.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, MenuItem, MenuItemProps, Skeleton, styled, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { Project } from '@/services/api/starton/model'
import { SessionStorageService } from '@/services/api/sessionStorage/sessionStorage.service'
import { axiosInstance } from '@/services/api/mutators/axios-custom-instance'
import { BillingPlanNames, useBillingPlans } from '@/config/billing/billing.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DashboardProjectMenuItemProps {
	project: Project
	handleCloseProjectMenu: VoidFunction
}
interface StartonDropdownItemStyled extends MenuItemProps {
	projectColor?: string
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const DashboardProjectMenuItemStyled = styled(MenuItem)<StartonDropdownItemStyled>(
	({ theme, projectColor }) => ({
		transition: 'all 0.2s',
		position: 'relative',
		...(projectColor
			? {
					'&:before': {
						position: 'absolute',
						left: 0,
						top: 0,
						bottom: 0,
						display: 'block',
						backgroundColor: projectColor,
						content: '""',
						width: 4,
					},
				}
			: {}),
		'&.Mui-selected': {
			backgroundColor: theme.palette.background.default,
			'&:hover': { backgroundColor: theme.palette.background.paper2 },
		},
		'&:hover': { backgroundColor: theme.palette.background.paper2 },
		'&:active': { backgroundColor: theme.palette.divider },
	}),
)

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardProjectMenuItem: React.FC<DashboardProjectMenuItemProps> = (props) => {
	const { project, handleCloseProjectMenu } = props
	const router = useRouter()
	const { projectId } = router.query
	const billingPlans = useBillingPlans()

	// Memoization
	//--------------------------------------------------------------------------
	const { planColor, planName } = React.useMemo(() => {
		return {
			planColor: (project?.subscriptionPlan as BillingPlanNames) !== 'FREE' ? 'warning.main' : 'primary.main',
			planName: billingPlans[project?.subscriptionPlan as BillingPlanNames]?.name,
		}
	}, [billingPlans, project?.subscriptionPlan])

	// Methods
	// ----------------------------------------------------------------------------
	const handleChangeProject = (event: React.MouseEvent<HTMLElement>, pId: string) => {
		event.preventDefault()
		handleCloseProjectMenu()
		axiosInstance.defaults.headers.common['x-project-id'] = pId
		SessionStorageService.setItem('starton.current-project-id', pId)
		void router.push(`/projects/${pId}/dashboard`)
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<DashboardProjectMenuItemStyled
			key={project.id}
			onClick={(event) => {
				handleChangeProject(event, project.id)
			}}
			selected={project.id === projectId}
			projectColor={project?.color ?? '#fff'}
			data-testid={`project-menu-item-${project.name}`}
		>
			{project?.name ? (
				<Box display="flex" flexDirection="column">
					<Typography variant="caption" color={planColor}>
						{planName}
					</Typography>
					<Typography overflow="hidden" textOverflow="ellipsis" maxWidth={300} data-dd-privacy="mask">
						{project.name}
					</Typography>
				</Box>
			) : (
				<Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
			)}
		</DashboardProjectMenuItemStyled>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
DashboardProjectMenuItem.displayName = 'DashboardProjectMenuItem'
