/*
| Developed by Starton
| Filename: PoweredByStarton.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PoweredByStartonProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PoweredByStarton: React.FC<PoweredByStartonProps> = (props) => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<SvgIcon {...props}>
			<svg width="141" height="36" viewBox="0 0 141 36" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M48.3122 24.7658C48.3122 22.5371 47.1443 21.0873 44.614 20.4598L39.6616 19.2265C38.71 18.9668 38.4721 18.599 38.4721 18.1662C38.4721 17.3007 39.229 16.9328 40.678 16.9328C42.7542 16.9328 43.6841 17.6252 43.6841 19.2697L47.7282 19.2697C47.7282 15.9591 45.371 13.7087 40.6131 13.7087C36.6122 13.7087 34.4063 15.2883 34.4063 18.2528C34.4063 20.1785 35.2281 21.7149 37.8666 22.4289L42.9704 23.7705C43.8355 23.9869 44.268 24.398 44.268 24.9389C44.268 25.891 43.4462 26.2805 41.5214 26.2805C38.6884 26.2805 37.8449 25.4799 37.8449 23.3161H33.7792C33.7792 27.2759 36.05 29.5695 41.4349 29.5695C46.3225 29.5695 48.3122 27.6653 48.3122 24.7658Z"
					fill="white"
				/>
				<path
					d="M48.7077 14.0117V17.4521H53.6169V29.2666H57.5313V17.4521H62.4405V14.0117L48.7077 14.0117Z"
					fill="white"
				/>
				<path
					d="M60.9042 29.2666L65.0348 29.2666L66.4622 24.6576H72.2581L73.6854 29.2666H77.8593L72.9934 14.0117L65.7917 14.0117L60.9042 29.2666ZM67.5435 21.2172L69.3601 16.1485L71.1767 21.2172H67.5435Z"
					fill="white"
				/>
				<path
					d="M78.8404 29.2666H82.7981V24.0951L86.9752 24.0789C87.2643 24.0889 87.5989 24.0572 88.0407 24.2913C88.4825 24.5253 88.5604 25.0193 88.5604 25.1493C88.5604 26.6723 88.6124 29.2666 88.6124 29.2666H92.5732V27.8014C92.5732 26.5794 92.6363 24.9755 92.5732 24.1872C92.5213 23.5372 91.8608 22.0551 89.9637 21.9511L89.1995 21.9096C90.7782 21.5634 92.4002 20.5031 92.4002 18.058C92.4002 14.7041 89.0265 14.0117 85.5663 14.0117L78.8404 14.0117V29.2666ZM82.7981 20.6762V17.4521H85.5663C87.2315 17.4521 88.4858 17.4954 88.4858 19.075C88.4858 20.6113 87.318 20.6762 85.5663 20.6762H82.7981Z"
					fill="white"
				/>
				<path
					d="M93.5023 14.0117V17.4521H98.4115V29.2666H102.326V17.4521H107.235V14.0117L93.5023 14.0117Z"
					fill="white"
				/>
				<path
					d="M107.507 21.6283C107.507 26.1507 110.556 29.5695 115.53 29.5695C120.483 29.5695 123.51 26.1507 123.51 21.6283C123.51 17.1276 120.483 13.7087 115.53 13.7087C110.556 13.7087 107.507 17.1276 107.507 21.6283ZM111.572 21.6283C111.572 18.8803 112.892 17.279 115.53 17.279C118.147 17.279 119.466 18.8586 119.466 21.6283C119.466 24.398 118.169 25.9992 115.53 25.9992C112.892 25.9992 111.572 24.398 111.572 21.6283Z"
					fill="white"
				/>
				<path
					d="M125.367 29.2666H129.303V16.1009L133.585 29.2666L141.003 29.2666V14.0117L137.067 14.0117V27.2294L132.785 14.0117L125.367 14.0117V29.2666Z"
					fill="white"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.3715 8.48762C12.6193 8.06964 11.7045 8.07129 10.9537 8.49196L1.26961 13.9186C0.485584 14.3579 0 15.187 0 16.0862V26.9607C0 27.8457 0.470395 28.6638 1.2349 29.1085L10.9191 34.742C11.6876 35.189 12.6362 35.1908 13.4063 34.7466L23.18 29.1087C23.949 28.6652 24.4228 27.8446 24.4228 26.9564V16.0905C24.4228 15.1881 23.9338 14.3566 23.1454 13.9185L13.3715 8.48762ZM11.4591 9.81389L1.95329 15.1406C1.61125 15.3322 1.39941 15.6939 1.39941 16.0862V26.9607C1.39941 27.3468 1.60462 27.7037 1.93815 27.8977L11.6224 33.5312C11.8568 33.6676 12.1297 33.709 12.3875 33.6553C12.3946 33.6425 12.4017 33.6297 12.4089 33.6169C13.3125 31.9941 14.5584 29.7568 14.4888 27.1357C14.4101 24.1664 13.2149 22.8297 12.0213 21.4948C10.8483 20.1829 9.67692 18.8727 9.56862 16.0164C9.48013 13.6829 10.6606 11.1957 11.4591 9.81389Z"
					fill="white"
				/>
				<path
					d="M96.8044 2.42188L94.7496 8.22463C94.6254 8.57897 94.472 8.91505 94.2893 9.23286C94.1067 9.55067 93.8637 9.81916 93.5605 10.0383C93.2573 10.2612 92.8628 10.4018 92.377 10.4603L92.2564 9.86847C92.6582 9.79541 92.9724 9.68583 93.1989 9.53971C93.429 9.39359 93.608 9.20729 93.7359 8.9808C93.8674 8.75432 93.9843 8.48582 94.0866 8.17532H93.8619L91.7961 2.42188H92.5304L94.3441 7.5945L96.0975 2.42188H96.8044Z"
					fill="white"
				/>
				<path
					d="M86.532 3.21645C86.7512 2.92786 87.0014 2.70686 87.2827 2.55343C87.5676 2.39636 87.8818 2.31782 88.2251 2.31782C88.7439 2.31782 89.1621 2.44202 89.4799 2.69042C89.7978 2.93882 90.0279 3.28768 90.1704 3.737C90.3165 4.18266 90.3895 4.70321 90.3895 5.29865C90.3895 5.87582 90.3019 6.38906 90.1265 6.83838C89.9548 7.2877 89.701 7.64204 89.3649 7.9014C89.0288 8.15711 88.6142 8.28496 88.121 8.28496C87.4343 8.28496 86.8973 8.04204 86.5101 7.55619L86.4443 8.17537H85.8525V0.0931511L86.532 0V3.21645ZM87.9731 7.69318C88.5064 7.69318 88.9137 7.49409 89.195 7.09591C89.4799 6.69409 89.6224 6.09134 89.6224 5.28769C89.6224 4.52422 89.4964 3.93609 89.2443 3.5233C88.9959 3.10686 88.6233 2.89864 88.1265 2.89864C87.7685 2.89864 87.4544 3.0064 87.184 3.22193C86.9137 3.4338 86.6964 3.66029 86.532 3.90138V6.88222C86.6964 7.12696 86.9046 7.32423 87.1567 7.474C87.4124 7.62012 87.6845 7.69318 87.9731 7.69318Z"
					fill="white"
				/>
				<path
					d="M76.6283 0L77.3078 0.0931511V8.17537H76.716L76.6283 7.38085C76.3982 7.70962 76.1406 7.94341 75.8557 8.08222C75.5744 8.21738 75.284 8.28496 74.9845 8.28496C74.4767 8.28496 74.0584 8.16076 73.7297 7.91236C73.4045 7.6603 73.1634 7.31144 73.0064 6.86578C72.8493 6.41646 72.7708 5.89956 72.7708 5.31509C72.7708 4.74157 72.8584 4.23015 73.0338 3.78083C73.2128 3.32787 73.4685 2.9717 73.8009 2.71234C74.137 2.44932 74.5424 2.31782 75.0173 2.31782C75.368 2.31782 75.6749 2.37992 75.9379 2.50412C76.2009 2.62467 76.431 2.80366 76.6283 3.04111V0ZM75.1872 2.90412C74.6539 2.90412 74.2447 3.10686 73.9598 3.51234C73.6785 3.91417 73.5379 4.51326 73.5379 5.30961C73.5379 6.06577 73.6657 6.65208 73.9214 7.06852C74.1808 7.48496 74.5644 7.69318 75.0721 7.69318C75.4374 7.69318 75.7461 7.59089 75.9982 7.38633C76.2539 7.18176 76.4639 6.9571 76.6283 6.71235V3.72056C76.4603 3.4612 76.2502 3.26029 75.9982 3.11782C75.7498 2.97535 75.4794 2.90412 75.1872 2.90412Z"
					fill="white"
				/>
				<path
					d="M67.2655 5.57268C67.2765 6.05487 67.3623 6.45122 67.523 6.76172C67.6874 7.06857 67.903 7.29506 68.1696 7.44118C68.4363 7.5873 68.7267 7.66036 69.0409 7.66036C69.3441 7.66036 69.6126 7.61652 69.8463 7.52885C70.0838 7.44118 70.3322 7.30784 70.5916 7.12885L70.9477 7.63844C70.681 7.843 70.3797 8.00191 70.0436 8.11515C69.7112 8.22839 69.3861 8.28502 69.0683 8.28502C68.5386 8.28502 68.0838 8.16081 67.7039 7.91241C67.3276 7.66401 67.039 7.31697 66.8381 6.87131C66.6372 6.42199 66.5367 5.89962 66.5367 5.30418C66.5367 4.71971 66.639 4.20281 66.8436 3.75349C67.0482 3.30418 67.3313 2.95349 67.6929 2.70143C68.0582 2.44573 68.482 2.31787 68.9642 2.31787C69.6619 2.31787 70.208 2.5681 70.6025 3.06856C70.997 3.56536 71.1943 4.24299 71.1943 5.10144C71.1943 5.20007 71.1925 5.28957 71.1888 5.36994C71.1852 5.44665 71.1815 5.51423 71.1779 5.57268H67.2655ZM68.9806 2.95349C68.5167 2.95349 68.1258 3.11787 67.808 3.44664C67.4938 3.77541 67.3167 4.27952 67.2765 4.95898H70.5477C70.5331 4.28317 70.3815 3.78089 70.0929 3.45212C69.8043 3.1197 69.4336 2.95349 68.9806 2.95349Z"
					fill="white"
				/>
				<path
					d="M60.4672 8.1754V7.60553H61.4042V2.9973H60.4672V2.42195H61.9247L62.0507 3.80278C62.2772 3.3352 62.5475 2.9699 62.8617 2.70689C63.1795 2.44022 63.6069 2.30688 64.1439 2.30688C64.2937 2.30688 64.427 2.31784 64.5439 2.33976C64.6644 2.36168 64.7905 2.38908 64.922 2.42195L64.8453 4.23018H64.1877V3.10689L64.1987 2.96442C64.1548 2.96077 64.1092 2.95894 64.0617 2.95894C63.5978 2.95894 63.2032 3.11785 62.8781 3.43566C62.553 3.74981 62.2882 4.24844 62.0836 4.93155V7.60553H63.2672V8.1754H60.4672Z"
					fill="white"
				/>
				<path
					d="M54.5454 5.57268C54.5564 6.05487 54.6422 6.45122 54.803 6.76172C54.9673 7.06857 55.1829 7.29506 55.4495 7.44118C55.7162 7.5873 56.0066 7.66036 56.3208 7.66036C56.624 7.66036 56.8925 7.61652 57.1263 7.52885C57.3637 7.44118 57.6121 7.30784 57.8715 7.12885L58.2276 7.63844C57.961 7.843 57.6596 8.00191 57.3235 8.11515C56.9911 8.22839 56.666 8.28502 56.3482 8.28502C55.8185 8.28502 55.3637 8.16081 54.9838 7.91241C54.6075 7.66401 54.3189 7.31697 54.118 6.87131C53.9171 6.42199 53.8167 5.89962 53.8167 5.30418C53.8167 4.71971 53.9189 4.20281 54.1235 3.75349C54.3281 3.30418 54.6112 2.95349 54.9728 2.70143C55.3381 2.44573 55.7619 2.31787 56.2441 2.31787C56.9418 2.31787 57.4879 2.5681 57.8824 3.06856C58.2769 3.56536 58.4742 4.24299 58.4742 5.10144C58.4742 5.20007 58.4724 5.28957 58.4687 5.36994C58.4651 5.44665 58.4614 5.51423 58.4578 5.57268H54.5454ZM56.2605 2.95349C55.7966 2.95349 55.4057 3.11787 55.0879 3.44664C54.7737 3.77541 54.5966 4.27952 54.5564 4.95898H57.8276C57.813 4.28317 57.6614 3.78089 57.3728 3.45212C57.0842 3.1197 56.7135 2.95349 56.2605 2.95349Z"
					fill="white"
				/>
				<path
					d="M51.7689 8.17532H50.6456L49.8237 3.84654L48.9305 8.17532H47.8401L46.7278 2.42188H47.4785L48.3223 7.75888L49.336 3.15612H50.3168L51.2812 7.75888L52.1196 2.42188H52.8319L51.7689 8.17532Z"
					fill="white"
				/>
				<path
					d="M43.4362 2.31787C44.2252 2.31787 44.8152 2.58819 45.206 3.12883C45.6005 3.66582 45.7978 4.38911 45.7978 5.2987C45.7978 5.86857 45.7083 6.37816 45.5293 6.82748C45.3503 7.27679 45.0836 7.63296 44.7293 7.89597C44.3786 8.15533 43.9421 8.28502 43.4197 8.28502C42.8937 8.28502 42.4535 8.16081 42.0992 7.91241C41.7485 7.66036 41.4836 7.3115 41.3046 6.86583C41.1293 6.41652 41.0416 5.89779 41.0416 5.30966C41.0416 4.72153 41.1311 4.20281 41.3101 3.75349C41.4891 3.30418 41.7558 2.95349 42.1101 2.70143C42.4681 2.44573 42.9101 2.31787 43.4362 2.31787ZM43.4362 2.91513C42.8955 2.91513 42.4846 3.11057 42.2033 3.50144C41.9256 3.89231 41.7868 4.49505 41.7868 5.30966C41.7868 6.8914 42.3311 7.68227 43.4197 7.68227C44.5083 7.68227 45.0526 6.88775 45.0526 5.2987C45.0526 4.49139 44.9156 3.89231 44.6416 3.50144C44.3713 3.11057 43.9695 2.91513 43.4362 2.91513Z"
					fill="white"
				/>
				<path
					d="M39.8104 2.91499C39.8104 3.50311 39.6862 3.9707 39.4378 4.31773C39.1894 4.66476 38.8478 4.91499 38.4131 5.06842C37.9784 5.21819 37.4816 5.29308 36.9227 5.29308H35.9145V8.17528H35.2131V0.646484H36.9994C37.8615 0.646484 38.5447 0.83096 39.0488 1.19991C39.5565 1.56521 39.8104 2.1369 39.8104 2.91499ZM39.0433 2.93142C39.0433 2.50402 38.9574 2.16795 38.7857 1.9232C38.6177 1.67845 38.3894 1.50493 38.1008 1.40265C37.8159 1.29671 37.4981 1.24375 37.1474 1.24375H35.9145V4.68485H37.0707C37.4469 4.68485 37.783 4.63737 38.0789 4.54239C38.3748 4.44376 38.6086 4.26841 38.7803 4.01636C38.9556 3.76065 39.0433 3.399 39.0433 2.93142Z"
					fill="white"
				/>
			</svg>
		</SvgIcon>
	)
}
