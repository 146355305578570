/*
| Developed by Starton
| Filename : useTipsLoad.tsx
| Author : Calixte De Tourris (calixte@starton.io)
*/

import React from 'react'
import { useAppDispatch, useAppSelector } from '../../stores/hooks'
import { tipsGetAllCached, tipsSetMany } from '../../stores/tips/tipsSlice'
import { useTips } from '@/services/fapi/hooks/useTips'
import { triggerNotification } from '@/components//layouts'

/*
|--------------------------------------------------------------------------
| Hooks
|--------------------------------------------------------------------------
*/
export const useTipsLoad = () => {
	const dispatch = useAppDispatch()
	const [alertDisplayed, setAlertDisplayed] = React.useState(false)

	// SWR
	//--------------------------------------------------------------------------
	const { data: tips, error } = useTips()

	// Effects
	//--------------------------------------------------------------------------
	React.useEffect(() => {
		if (tips?.data && tips.data.length > 0) {
			dispatch(
				tipsSetMany(
					tips?.data?.map((item) => ({
						id: item.id,
						title: item.attributes.title,
						description: item.attributes.description,
					})),
				),
			)
		}
	}, [tips?.data, dispatch])

	React.useEffect(() => {
		if (error?.name && !alertDisplayed) {
			setAlertDisplayed(true)
			triggerNotification('error', 'An unexpected error occurred. Please reload.')
		}
	}, [error, alertDisplayed])

	return { isLoaded: Boolean(tips?.data) }
}

export const useTipGetAllCached = () => useAppSelector(tipsGetAllCached)
