/*
| Developed by Starton
| Filename : ProductUpdatesBody.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import Link from 'next/link'
import moment from 'moment/moment'
import { useVersionReleaseNotes } from '@/services/fapi'
import { LocalStorageService } from '@/services/localStorage/localStorage.service'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ProductUpdatesBodyProps {
	setShowBadge: React.Dispatch<React.SetStateAction<boolean>>
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ProductUpdatesBodyStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	overflow: 'initial',
	width: '100%',
	flex: 0,
	border: '1px solid',
	borderColor: theme.palette.divider,
	padding: theme.spacing(3, 4),
	maxHeight: '60vh',
	overflowY: 'scroll',
	'&.MuiDialogContent-root': {
		boxSizing: 'border-box',
		width: '100%',
		padding: theme.spacing(3),
	},
	'& div:not(:last-child)': {
		borderBottom: `1px solid ${theme.palette.divider}`,
		marginBottom: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ProductUpdatesBody: React.FC<ProductUpdatesBodyProps> = (props) => {
	const { data } = useVersionReleaseNotes()

	// Get last product id for save in local storage
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		const lastProductUpdateId = data?.data?.[0].id ?? null

		if (lastProductUpdateId) {
			LocalStorageService.setItem('starton.product-updates.last-viewed', String(lastProductUpdateId))
			props.setShowBadge(false)
		}
	}, [data, props])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<ProductUpdatesBodyStyled>
			{data?.data?.map?.((item) => (
				<Box key={item.id}>
					<Typography variant={'h3'} textTransform={'uppercase'} marginBottom={1}>
						{item.attributes.name}
					</Typography>
					<Typography color={'text.secondary'} marginBottom={1}>
						{item.attributes.description}
					</Typography>
					<Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
						<Link
							href={item.attributes.doc_link}
							title={'Read more'}
							color={'secondary'}
							passHref
							legacyBehavior
						>
							<a target={'_blank'} onClick={(event) => event.stopPropagation()}>
								<Typography color={'secondary.main'}>Read more</Typography>
							</a>
						</Link>
						<Typography color={'text.secondary'}>
							{moment(item.attributes.release_date).format('L')}
						</Typography>
					</Box>
				</Box>
			)) ?? 'No new product update'}
		</ProductUpdatesBodyStyled>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
ProductUpdatesBody.displayName = 'ProductUpdatesBody'
