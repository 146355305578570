// GET /auth/logout
//--------------------------------------------------------------------------
import { AuthApi } from '../auth-api-instance'
import { User } from '../contracts/users'

// POST /login
//--------------------------------------------------------------------------
export const authLogin = (data: { email: string; password: string }) => {
	return AuthApi<User>({ url: '/login', method: 'POST', data })
}

// GET /logout
//--------------------------------------------------------------------------
export const authLogout = () => {
	return AuthApi({ url: '/logout', method: 'GET' })
}

// POST /register
//--------------------------------------------------------------------------
export const authRegister = (data: { email: string; password: string; name: string }) => {
	return AuthApi<User>({ url: '/register', method: 'POST', data })
}

// GET /github/redirect
//--------------------------------------------------------------------------
export const authGithubRedirect = () => {
	return AuthApi<User>({ url: '/github/redirect', method: 'GET' })
}
