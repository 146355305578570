/*
| Developed by Starton
| Filename : useNetworkLoad.tsx
| Author : Calixte De Tourris (calixte@starton.io)
*/

import React from 'react'
import { useAppDispatch, useAppSelector } from '../../stores/hooks'
import { networksSetMany, networksGetAllCached, networksGetOneCached } from '../../stores/networks/networksSlice'
import { triggerNotification } from '@/components//layouts'
import { useGetAllNetwork } from '@/services/api/starton/endpoints/network/network'

/*
|--------------------------------------------------------------------------
| Hooks
|--------------------------------------------------------------------------
*/
export const useNetworksLoad = (projectId?: string | Array<string>) => {
	const dispatch = useAppDispatch()
	const [alertDisplayed, setAlertDisplayed] = React.useState(false)

	// SWR
	//--------------------------------------------------------------------------
	const { data, error } = useGetAllNetwork(
		// TODO: Check about this chain id
		// @ts-ignore
		{
			limit: 2500,
		},
		{
			swr: {
				revalidateOnFocus: false,
				revalidateOnMount: true,
				revalidateOnReconnect: false,
				refreshWhenOffline: false,
				refreshWhenHidden: false,
				refreshInterval: 300000,
				errorRetryCount: 0,
				shouldRetryOnError: false,
				enabled: Boolean(projectId),
			},
			request: {
				headers: {
					// This call may happen before the project id is set up by the layout
					'x-project-id': String(projectId),
				},
			},
		},
	)

	// Effects
	//--------------------------------------------------------------------------
	React.useEffect(() => {
		if (data?.items && data.items.length > 0) {
			dispatch(networksSetMany(data.items))
		}
	}, [data?.items, dispatch])

	React.useEffect(() => {
		if (error?.name && !alertDisplayed) {
			setAlertDisplayed(true)
			triggerNotification('error', 'An unexpected error occurred. Please reload.')
		}
	}, [error, alertDisplayed])

	return { isLoaded: Boolean(data) }
}

export const useNetworkGetOneCached = (networkName: string) => useAppSelector(networksGetOneCached(networkName))
export const useNetworkGetAllCached = () => useAppSelector(networksGetAllCached)
