/*
| Developed by Starton
| Filename: PGNavbar.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { StartonButton, StartonIconButton } from '@starton/react-ui'
import { HomeOutlined, RestartAltOutlined } from '@mui/icons-material'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { usePostHog } from 'posthog-js/react'
import { StartonPlaygroundLogo } from '../svgs/StartonPlaygroundLogo'
import { useAppDispatch } from '../../../../../stores/hooks'
import { playgroundRestartExercise } from '../../../../../stores/playground/playgroundSlice'
import { exerciseIds } from '../../pages/PGHome'
import { projectUrl } from '@/config/urls/project-builder'
import { useWhiteLabel } from '@/hooks/white-labelling/useWhiteLabel'
import { StartonNavBarLinks } from '@/components/common/StartonNavBarLinks'
import { GtagService } from '@/services/gtag/gtag.service'
import { useWhoami } from '@/services/auth-api/endpoints/users'
import { LogoType } from '@/components/white-labelling/components/LogoType'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PGNavbarProps {
	variant?: 'logo' | 'navigation'
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const NavbarStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	backgroundColor: theme.palette.background.paper2,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: theme.palette.divider,
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PGNavbar: React.FC<PGNavbarProps> = ({ variant = 'logo' }) => {
	const router = useRouter()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const posthog = usePostHog()
	const { projectId } = router.query
	const { isStarton } = useWhiteLabel()
	const { data: user } = useWhoami()

	// Memoization
	//--------------------------------------------------------------------------
	const { isExercise, exerciseName } = React.useMemo(() => {
		return {
			isExercise: router.pathname.includes('exercises'),
			exerciseName: router.pathname.split('/exercises/')?.[1],
		}
	}, [router])

	// Methods
	//--------------------------------------------------------------------------
	const handleRestartExercise = () => {
		dispatch(playgroundRestartExercise())
	}

	const handleStopPlaygroundEvent = () => {
		if (isExercise) {
			const payload = {
				...(user?.id && {
					userId: user.id,
				}),
				...(projectId && {
					projectId: String(projectId),
				}),
				metadata: {
					exerciseId: exerciseIds.mintNft,
				},
			}
			GtagService.createUserEvent(
				{
					name: 'playground-stop',
					...payload,
				},
				posthog,
			)
		}
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<NavbarStyled>
			<Box display="flex">
				{variant === 'logo' ? (
					<Box paddingX={2} paddingY={1.5} display="flex" alignItems="center">
						<Link href={projectUrl.create((urls) => urls.project.dashboard)} data-testid="navbar-logo">
							{isStarton ? (
								<StartonPlaygroundLogo sx={{ width: 142, height: 28 }} />
							) : (
								<LogoType sx={{ width: 142, height: 28 }} style={{ width: 142, height: 28 }} />
							)}
						</Link>
					</Box>
				) : null}
				{variant === 'navigation' ? (
					<Box borderRight="1px solid" borderColor="divider">
						<Box padding={1.5}>
							<Link
								href={projectUrl.create((urls) => urls.project.playground.home)}
								onClick={handleStopPlaygroundEvent}
								data-testid="navbar-home"
							>
								<StartonIconButton>
									<HomeOutlined />
								</StartonIconButton>
							</Link>
						</Box>
					</Box>
				) : null}
				{isExercise ? (
					<Box display="flex" alignItems="center" gap={5}>
						<Box paddingY={1.5} paddingX={2} width="fit-content">
							<Typography
								color="text.secondary"
								sx={(theme) => ({
									...theme.typography.nav.body.xs,
								})}
							>
								{t('common:common.playground')}
							</Typography>
							<Typography
								sx={(theme) => ({
									...theme.typography.nav.title.md,
								})}
							>
								{t(`playground:${exerciseName}.title`)}
							</Typography>
						</Box>
						<StartonButton
							startIcon={<RestartAltOutlined />}
							size="small"
							variant="outlined"
							color="secondary"
							onClick={handleRestartExercise}
							data-testid="navbar-restart"
						>
							{t('common:common.restart')}
						</StartonButton>
					</Box>
				) : null}
			</Box>
			<StartonNavBarLinks />
		</NavbarStyled>
	)
}
