/*
| Developed by Starton
| Filename : PremiumDialog
| Author : Calixte DE TOURRIS (calixte@starton.io)
*/

import React from 'react'
import {
	StartonButton,
	StartonDialog,
	StartonDialogBody,
	StartonDialogFooter,
	StartonDialogHeader,
} from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import { StarOutlined } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import { selectOpenPremiumDialog, setOpenPremiumDialog } from '@/stores/common/commonSlice'
import { useAppDispatch } from '@/stores/hooks'
import { projectUrl } from '@/config/urls/project-builder'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PremiumDialogProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PremiumDialog: React.FC<PremiumDialogProps> = () => {
	const { t } = useTranslation()
	const openPremiumDialog = useSelector(selectOpenPremiumDialog)
	const dispatch = useAppDispatch()

	// Methods
	//--------------------------------------------------------------------------
	const handleClose = () => {
		dispatch(setOpenPremiumDialog(false))
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<React.Fragment>
			<StartonDialog open={openPremiumDialog} fullWidth onClose={handleClose}>
				<StartonDialogHeader icon={<StarOutlined color="warning" />} onClose={handleClose}>
					{t('common:premiumDialog.title')}
				</StartonDialogHeader>
				<StartonDialogBody>{t('common:premiumDialog.description')}</StartonDialogBody>
				<StartonDialogFooter>
					<StartonButton color="secondary" onClick={handleClose} data-testid="premium-dialog-close">
						{t('common:common.cancel')}
					</StartonButton>
					<Link
						href={projectUrl.create((urls) => urls.billing)}
						onClick={handleClose}
						data-testid="premium-dialog-upgrade"
					>
						<StartonButton variant="contained">{t('common:common.upgradeNow')}</StartonButton>
					</Link>
				</StartonDialogFooter>
			</StartonDialog>
		</React.Fragment>
	)
}
