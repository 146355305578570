/*
| Developed by Starton
| Filename : useMaintenances.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import useSwr from 'swr'
import { FAPIGetParams, SecondAxiosParameter, MaintenanceDTO } from '../contracts'
import { fapiAxiosInstance } from '../fapi-instance'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UseMaintenancesParams extends FAPIGetParams {}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const FAPI_MAINTENANCES = '/api/maintenance?populate=*'

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const maintenancesGetAll = (
	params?: UseMaintenancesParams,
	options?: SecondAxiosParameter<typeof fapiAxiosInstance>,
) => {
	return fapiAxiosInstance<MaintenanceDTO>(
		{
			url: FAPI_MAINTENANCES,
			method: 'GET',
			params: params,
		},
		options,
	)
}
export const useMaintenances = (params?: UseMaintenancesParams) => {
	return useSwr<Awaited<MaintenanceDTO>>(FAPI_MAINTENANCES, () => maintenancesGetAll(params), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		refreshInterval: 3600000, // 1 hour
		revalidateOnMount: true,
	})
}
