/*
| Developed by Starton
| Filename : NotAuthLeftSide.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { Box, Grid, GridProps, styled, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { LogoType } from '@/components/white-labelling/components/LogoType'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface NotAuthLeftSideProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
	height: `calc(100vh)`,
	backgroundColor: theme.palette.background.paper2,
	borderRight: `1px solid ${theme.palette.divider}`,
	alignItems: 'center',
	justifyContent: 'center',
	[theme.breakpoints.down('lg')]: {
		display: 'none',
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const NotAuthLeftSide: React.FC<NotAuthLeftSideProps> = () => {
	const { t } = useTranslation()

	return (
		<StyledGrid item xs={0} lg={5}>
			<Box
				marginX="auto"
				height="100%"
				display="flex"
				justifyContent="center"
				flexDirection="column"
				gap={6}
				width="100%"
				maxWidth="50ch"
			>
				<LogoType
					sx={{
						width: 250,
						height: 'auto',
						objectFit: 'contain',
						objectPosition: 'center',
					}}
					style={{
						width: 250,
						height: 'auto',
						objectFit: 'contain',
						objectPosition: 'center',
					}}
				/>
				<Typography variant="subtitle2" color="text.secondary" fontWeight={'500'}>
					{t('auth/common-no-auth:leftSide.hookCitation')}
				</Typography>
			</Box>
		</StyledGrid>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
NotAuthLeftSide.displayName = 'NotAuthLeftSide'
