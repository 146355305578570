/*
| Developed by Starton
| Filename: TezosSmallLogo.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface TezosSmallLogoProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const TezosSmallLogo: React.FC<TezosSmallLogoProps> = (props) => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<SvgIcon {...props}>
			<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M33.6849 29.0815C33.2905 22.0665 24.5102 18.8779 23.6699 18.5504C23.6356 18.5331 23.6356 18.4987 23.6527 18.4642L32.7074 9.32921V8.34676C32.7023 8.18647 32.5754 8.05893 32.4159 8.05375H15.7298V2.55552V0.900879L9.57332 2.19357V3.1243H9.9163C9.9163 3.1243 11.4254 3.1243 11.4254 4.64106V8.03652H6.65797C6.56537 8.04341 6.49334 8.1158 6.48648 8.20888V10.3634H11.4426V22.0665C11.4426 25.7377 13.792 28.2886 17.9078 27.9439C18.7549 27.8595 19.5695 27.563 20.2743 27.0821C20.5847 26.8822 20.7717 26.5392 20.7717 26.1686V25.0138C19.434 25.9101 18.3022 25.8584 18.3022 25.8584C15.6955 25.8584 15.747 22.5319 15.747 22.5319V10.3634H27.7514L19.1082 19.1192C19.0911 20.274 19.0739 21.1702 19.0739 21.1875C19.0705 21.2133 19.0893 21.2357 19.1151 21.2392C19.1185 21.2392 19.1219 21.2392 19.1254 21.2392C27.0482 22.5836 29.1919 27.7371 29.1919 29.2366C29.1919 29.2366 30.0493 36.5274 22.7953 37.0272C22.7953 37.0272 18.045 37.234 17.2047 35.3209C17.1738 35.2588 17.2012 35.183 17.263 35.1537C17.2664 35.152 17.2698 35.1502 17.2733 35.1485C18.081 34.809 18.604 34.0127 18.5937 33.1319C18.5937 31.7186 17.7363 30.5638 15.9356 30.5638C14.478 30.5638 13.2775 31.7186 13.2775 33.1319C13.2775 33.1319 12.5916 39.2679 22.7781 39.0955C34.4052 38.8887 33.6849 29.0815 33.6849 29.0815Z"
					fill="white"
				/>
			</svg>
		</SvgIcon>
	)
}
