/*
| Developed by Starton
| Filename : StartonDialogHeaderFullscreen
| Author : Calixte DE TOURRIS (calixte@starton.io)
*/

import React from 'react'
import { Avatar, Box, styled, Typography } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import { StartonIconButton } from '@starton/react-ui'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonDialogHeaderFullscreenProps {
	icon: React.ReactNode
	title: string
	onClose?: VoidFunction
	children?: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DialogHeaderFullscreenStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	width: '100%',
	alignItems: 'center',
	justifyContent: 'space-between',
	gap: theme.spacing(3),
	padding: theme.spacing(2.5),
	backgroundColor: theme.palette.background.paper2,
}))

const IconStyled = styled(Avatar)(({ theme }) => ({
	backgroundColor: 'transparent',
	'&, & svg': {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}))

const CloseButton = styled(StartonIconButton)(({ theme }) => ({
	'&.MuiButtonBase-root.MuiIconButton-root .MuiSvgIcon-root': {
		width: theme.spacing(5),
		height: theme.spacing(5),
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonDialogHeaderFullscreen: React.FC<StartonDialogHeaderFullscreenProps> = (props) => {
	const { onClose, icon, title, children } = props

	// Render
	//--------------------------------------------------------------------------
	return (
		<DialogHeaderFullscreenStyled>
			<Box display="flex" alignItems="center" gap={3}>
				<IconStyled variant="square">{icon}</IconStyled>
				<Typography variant="h2">{title}</Typography>
			</Box>
			<Box display="flex" alignItems="center" gap={5}>
				{children}
				<CloseButton onClick={onClose} color="secondary" size="large" data-testid={'close-dialog'}>
					<CloseOutlined />
				</CloseButton>
			</Box>
		</DialogHeaderFullscreenStyled>
	)
}
