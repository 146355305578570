/*
| Developed by Starton
| Filename : errors.service.ts
| Author : Calixte De Tourris (calixte@starton.com)
*/

import { AxiosError } from 'axios'
import { Translate } from 'next-translate'
import { triggerNotification } from '@/components/layouts'

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export const ApiCommonErrorsHandler = (error: any, t: Translate, title?: string) => {
	/** Unknown error **/
	if (!(error instanceof AxiosError)) {
		triggerNotification('error', title ?? t('common:errors.unknown.title'), t('common:errors.unknown.description'))
		return
	}

	/** Authorization error **/
	if (error.response?.status === 403) {
		triggerNotification(
			'error',
			title ?? t('common:errors.unauthorized.title'),
			t('common:errors.unauthorized.description'),
		)
		return
	}

	/** Bad request error **/
	if (error.response?.status === 400 || error.response?.status === 402) {
		if (!error.response?.data?.message) {
			triggerNotification(
				'error',
				title ?? t('common:errors.badRequest.title'),
				t('common:errors.badRequest.description'),
			)
			return
		}
		if (Array.isArray(error.response?.data?.message)) {
			triggerNotification(
				'error',
				title ?? t('common:errors.badRequest.title'),
				error.response?.data?.message[0] as string,
			)
			return
		}
		if (typeof error.response?.data?.message === 'string') {
			triggerNotification('error', title ?? t('common:errors.badRequest.title'), error.response?.data?.message)
			return
		}
	}

	if (error.response?.status === 500) {
		if (typeof error.response?.data?.message === 'string') {
			triggerNotification('error', title ?? t('common:errors.500.title'), error.response?.data?.message)
			return
		}
	}
	if (error.response?.status === 404) {
		if (typeof error.response?.data?.message === 'string') {
			triggerNotification('error', title ?? t('common:errors.500.title'), error.response?.data?.message)
			return
		}
	}
}

export const ApiInteractErrorHandler = (error: any) => {
	if (error instanceof AxiosError && error?.response?.data) {
		if (error.response.data.context && error.response.data.message) {
			return {
				error: error.response.data.message,
				...error.response.data.context,
			}
		}
		return error.response.data
	}
}
