/*
| Developed by Dirupt
| Filename : whitelabel.context.ts
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { PaletteMode, ThemeOptions } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type WhiteLabel = {
	name: string
	slug: string
	isStarton: boolean
	colorMode: PaletteMode
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	logo: 'default' | string
	// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
	logoType: 'default' | string
	featuredFlags: {
		poweredBy: boolean
		enableRelayer: boolean
		enableListener: boolean
		enablePlayground: boolean
	}
}

export type WhiteLabelConfiguration = WhiteLabel & {
	theme?: ThemeOptions
}

export type WhiteLabelContextProps = WhiteLabelConfiguration & {
	defaultConfiguration: WhiteLabelConfiguration
	onUpdate: (config: WhiteLabelConfiguration, doNotSave?: boolean) => void
	canReset: boolean
	onReset: VoidFunction
	open: boolean
	onToggle: VoidFunction
	onClose: VoidFunction
}

/*
|--------------------------------------------------------------------------
| Context
|--------------------------------------------------------------------------
*/
export const WhiteLabelContext = React.createContext<WhiteLabelContextProps>({} as WhiteLabelContextProps)

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useWhiteLabel = () => {
	const context = React.useContext(WhiteLabelContext)

	if (!context) {
		throw new Error('useWhiteLabel must be used within a WhiteLabelProvider')
	}

	return context
}
