/*
| Developed by Starton
| Filename : DashboardAppBar.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { AppBar, AppBarProps, Box, Divider, styled, Toolbar, useTheme, Theme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { DashboardProjectMenu } from './DashboardProjectMenu'
import { DashboardBreadcrumbs } from './DashboardBreadcrumbs'
import { AppBarInfos } from './AppBarInfos'
import { StartonUserMenu } from '@/components/common'
import { NavDefaultConfig } from '@/config/common/nav.config'
import { StartonNavBarLinks } from '@/components/common/StartonNavBarLinks'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DashboardAppBarProps extends AppBarProps {
	open: boolean
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DashboardAppBarStyled = styled(AppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})<Pick<DashboardAppBarProps, 'open'>>(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	backgroundColor: theme.palette.background.paper2,
	backgroundImage: 'none',
	height: `${NavDefaultConfig.sizes.appbar.height}px`,
	borderBottom: `1px solid ${theme.palette.divider}`,
	...(open
		? {
				marginLeft: `${NavDefaultConfig.sizes.drawer.widthOpened}px`,
				width: `calc(100% - ${NavDefaultConfig.sizes.drawer.widthOpened}px)`,
				transition: theme.transitions.create(['width', 'margin'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
			}
		: {
				width: `calc(100% - ${NavDefaultConfig.sizes.drawer.widthClosed}px)`,
				transition: theme.transitions.create(['width', 'margin'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
			}),

	[theme.breakpoints.down('md')]: {
		overflowX: 'auto',
		overflowY: 'hidden',
	},
}))

export const DashboardToolbar = styled(Toolbar)(({ theme }) => ({
	minHeight: `${NavDefaultConfig.sizes.appbar.height}px`,
	justifyContent: 'space-between',
	[theme.breakpoints.up('sm')]: {
		minHeight: `${NavDefaultConfig.sizes.appbar.height}px`,
	},
}))

const DashboardAppBarSide = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	height: '100%',
	flex: 1,
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardAppBar: React.FC<DashboardAppBarProps> = (props) => {
	const theme = useTheme()
	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), { noSsr: true })

	// Render
	//--------------------------------------------------------------------------
	return (
		<DashboardAppBarStyled position={'absolute'} open={props.open} elevation={0}>
			<DashboardToolbar disableGutters>
				<DashboardAppBarSide>
					<DashboardProjectMenu />
					<Divider orientation={'vertical'} flexItem />
					{!isTablet ? <DashboardBreadcrumbs /> : null}
					<AppBarInfos />
				</DashboardAppBarSide>
				<DashboardAppBarSide sx={{ justifyContent: 'flex-end' }}>
					<StartonNavBarLinks />
					<Divider orientation={'vertical'} flexItem />
					<StartonUserMenu
						buttonProps={{
							sx: {
								height: 'calc(100% - 2px)',
								borderBottomWidth: 1,
								borderBottomStyle: 'solid',
								borderBottomColor: `${theme.palette.divider}`,
							},
						}}
					/>
					<Divider orientation={'vertical'} flexItem />
				</DashboardAppBarSide>
			</DashboardToolbar>
		</DashboardAppBarStyled>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
DashboardAppBar.displayName = 'DashboardAppBar'
