/*
| Developed by Starton
| Filename : EmptyTemplate.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { LayoutTemplateProps } from '../layout-template.contract'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const EmptyTemplate: React.FC<LayoutTemplateProps> = (props) => {
	return <main id={'starton-template-empty'}>{props.children}</main>
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
EmptyTemplate.displayName = 'EmptyTemplate'
