/*
| Developed by Starton
| Filename : ProductUpdatesFooter.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, Checkbox, styled } from '@mui/material'
import { StartonButton, StartonCheckboxFormControlLabelStyled } from '@starton/react-ui'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ProductUpdatesFooterProps {
	setTurnOffCheck: React.Dispatch<React.SetStateAction<boolean>>
	turnOffCheck: boolean
	handleClose: VoidFunction
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ProductUpdatesFooterStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	padding: theme.spacing(3),
	boxSizing: 'border-box',
	borderWidth: '0px 1px 1px 1px',
	borderStyle: 'solid',
	borderColor: theme.palette.divider,
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ProductUpdatesFooter: React.FC<ProductUpdatesFooterProps> = (props) => {
	return (
		<ProductUpdatesFooterStyled>
			<StartonCheckboxFormControlLabelStyled
				control={<Checkbox disableRipple />}
				label={'Turn off product updates'}
				onChange={() => props.setTurnOffCheck((prevState) => !prevState)}
				checked={props.turnOffCheck}
				size="medium"
			/>
			<StartonButton size="large" variant={'contained'} color={'primary'} onClick={props.handleClose}>
				Got it !
			</StartonButton>
		</ProductUpdatesFooterStyled>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
ProductUpdatesFooter.displayName = 'ProductUpdatesFooter'
