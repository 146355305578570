/*
| Developed by Starton
| Filename : WhiteLabelPresetList.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import useTranslate from 'next-translate/useTranslation'
import { Box, Divider, Typography } from '@mui/material'
import { StartonButton } from '@starton/react-ui'
import { mutate } from 'swr'
import { useWhiteLabel } from '@/components/white-labelling/context/whitelabel.context'
import { WhiteLabelPresetListCard } from '@/components/white-labelling/drawer/views/list/WhiteLabelPresetListCard'
import { usePlatforms } from '@/services/fapi/hooks/usePlatform'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface WhiteLabelPresetListProps {
	handleNew: VoidFunction
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const WhiteLabelPresetList: React.FC<WhiteLabelPresetListProps> = ({ handleNew }) => {
	const { t } = useTranslate()
	const whiteLabel = useWhiteLabel()
	const { data } = usePlatforms()

	return (
		<Box display={'flex'} flexDirection={'column'} gap={2} flex={1}>
			<Box display={'flex'} flexDirection={'column'} gap={1}>
				<Typography variant="subtitle2">{t('common:whitelabel.drawer.list.title')}</Typography>
				<Typography>{t('common:whitelabel.drawer.list.description')}</Typography>
			</Box>
			<Divider />
			<WhiteLabelPresetListCard
				whiteLabelConfig={{
					name: whiteLabel.defaultConfiguration.name,
					slug: whiteLabel.defaultConfiguration.slug,
					colorMode: whiteLabel.defaultConfiguration.colorMode,
					featuredFlags: whiteLabel.defaultConfiguration.featuredFlags,
					logo: whiteLabel.defaultConfiguration.logo,
					logoType: whiteLabel.defaultConfiguration.logoType,
					theme: whiteLabel.defaultConfiguration.theme,
					isStarton: whiteLabel.defaultConfiguration.isStarton,
				}}
				selected={whiteLabel.name === 'Starton'}
				onClick={() => {
					whiteLabel.onReset()

					void mutate((key) => {
						if (Array.isArray(key)) {
							return key[0].startsWith('/v3/network')
						}

						return typeof key === 'string' && key.startsWith('/v3/network')
					})
				}}
			/>
			{data
				? data.data.map((platform) => (
						<WhiteLabelPresetListCard
							key={platform.id}
							whiteLabelConfig={{
								name: platform.attributes.name,
								slug: platform.attributes.slug,
								colorMode: platform.attributes.colorMode,
								featuredFlags: {
									enablePlayground: platform.attributes.featuredFlags.enablePlayground,
									enableListener: platform.attributes.featuredFlags.enableListener,
									enableRelayer: platform.attributes.featuredFlags.enableRelayer,
									poweredBy: platform.attributes.featuredFlags.poweredBy,
								},
								logo: platform.attributes.logo.data.attributes.url,
								logoType: platform.attributes.logoType.data.attributes.url,
								theme: {
									palette: {
										primary: {
											main: platform.attributes.theme.primary.main,
											dark: platform.attributes.theme.primary.dark,
											light: platform.attributes.theme.primary.light,
											hover: platform.attributes.theme.primary.hover,
											contrastText: platform.attributes.theme.primary.contrastText,
										},
									},
								},
								isStarton: false,
							}}
							selected={platform.attributes.name === whiteLabel.name}
							onClick={() => {
								whiteLabel.onUpdate({
									name: platform.attributes.name,
									slug: platform.attributes.slug,
									colorMode: platform.attributes.colorMode,
									featuredFlags: {
										enablePlayground: platform.attributes.featuredFlags.enablePlayground,
										enableListener: platform.attributes.featuredFlags.enableListener,
										enableRelayer: platform.attributes.featuredFlags.enableRelayer,
										poweredBy: platform.attributes.featuredFlags.poweredBy,
									},
									logo: platform.attributes.logo.data.attributes.url,
									logoType: platform.attributes.logoType.data.attributes.url,
									theme: {
										palette: {
											primary: {
												main: platform.attributes.theme.primary.main,
												dark: platform.attributes.theme.primary.dark,
												light: platform.attributes.theme.primary.light,
												hover: platform.attributes.theme.primary.hover,
												contrastText: platform.attributes.theme.primary.contrastText,
											},
										},
									},
									isStarton: false,
								})

								void mutate((key) => {
									if (Array.isArray(key)) {
										return key[0].startsWith('/v3/network')
									}

									return typeof key === 'string' && key.startsWith('/v3/network')
								})
							}}
						/>
					))
				: null}
			<StartonButton fullWidth variant={'contained'} size={'medium'} onClick={handleNew}>
				{t('common:whitelabel.drawer.list.ctaNew')}
			</StartonButton>
		</Box>
	)
}
