/*
| Developed by Starton
| Filename : DashboardDrawer.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Drawer, styled } from '@mui/material'
import { KeyboardDoubleArrowRightOutlined, KeyboardDoubleArrowLeftOutlined } from '@mui/icons-material'
import Link from 'next/link'
import { StartonIconButton } from '@starton/react-ui'
import { useWhiteLabel } from '@/hooks/white-labelling/useWhiteLabel'
import { NavDefaultConfig } from '@/config/common/nav.config'
import { DashboardToolbar } from '@/components/auth/pages/settings'
import { DashboardDrawerMenu } from '@/components/layouts/pages/DashboardLayout/templates/DefaultTemplate/DashboardDrawer/TopMenu/DashboardDrawerMenu'
import { PoweredByStarton } from '@/components/white-labelling'
import { LogoType } from '@/components/white-labelling/components/LogoType'
import { Logo } from '@/components/white-labelling/components/Logo'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DashboardDrawerProps {
	open: boolean
	toggleDrawer: VoidFunction
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DashboardDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	position: 'relative',
	'& .MuiDrawer-paper': {
		gap: theme.spacing(1),
		position: 'relative',
		whiteSpace: 'nowrap',
		width: `${NavDefaultConfig.sizes.drawer.widthOpened}px`,
		backgroundColor: theme.palette.background.paper2,
		borderRight: `1px solid ${theme.palette.divider}`,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		overflow: 'initial',
		...(!open && {
			// overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: `${NavDefaultConfig.sizes.drawer.widthClosed}px`,
		}),
	},
}))

const LogoLink = styled('a', { shouldForwardProp: (prop) => prop !== 'open' })<Pick<DashboardDrawerProps, 'open'>>(
	({ theme, open }) => ({
		width: '100%',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		...(open
			? {
					height: '30px',
					'& svg': {
						height: '30px',
					},
					'& img': {
						// TODO: Need optimize this integration with webapp v4
						height: '30px !important',
					},
				}
			: {
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'center',
					alignItems: 'center',
					maxHeight: `calc(${NavDefaultConfig.sizes.appbar.height}px - ${theme.spacing(2)})`,
					'& svg': {
						maxHeight: `calc(${NavDefaultConfig.sizes.appbar.height}px - ${theme.spacing(2)})`,
					},
				}),
	}),
)

const CollapseButton = styled(StartonIconButton)(({ theme }) => ({
	'&.MuiIconButton-root': {
		margin: theme.spacing('auto', 1, 2),
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardDrawer: React.FC<DashboardDrawerProps> = (props) => {
	const { featuredFlags } = useWhiteLabel()

	// Render
	//--------------------------------------------------------------------------
	return (
		<DashboardDrawerStyled variant="permanent" open={props.open}>
			<DashboardToolbar
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					px: [1],
					cursor: 'pointer',
					mb: (theme) => theme.spacing(5),
				}}
			>
				<Link href={'/projects'} legacyBehavior data-testid="dashboard-drawer-logo">
					<LogoLink open={props.open}>
						{props.open ? (
							<LogoType sx={{ height: 30 }} style={{ height: 30 }} />
						) : (
							<Logo width={30} height={30} />
						)}
					</LogoLink>
				</Link>
			</DashboardToolbar>
			<DashboardDrawerMenu open={props.open} />
			{!featuredFlags.poweredBy ? (
				<CollapseButton
					color="secondary"
					variant="contained"
					size="large"
					onClick={props.toggleDrawer}
					data-testid="dashboard-drawer-collapse"
				>
					{props.open ? (
						<KeyboardDoubleArrowLeftOutlined sx={{ color: 'text.primary' }} />
					) : (
						<KeyboardDoubleArrowRightOutlined sx={{ color: 'text.primary' }} />
					)}
				</CollapseButton>
			) : (
				<PoweredByStarton sx={{ width: 135, height: 40, marginTop: 'auto', marginX: 2, marginBottom: 2 }} />
			)}
		</DashboardDrawerStyled>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
DashboardDrawer.displayName = 'DashboardDrawer'
