/*
| Developed by Starton
| Filename : SettingsDrawerItem.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, styled } from '@mui/material'
import Link from 'next/link'
import { StartonTooltip } from '@starton/react-ui'
import { APP_BAR_WEIGHT, SettingsDrawerNavItem } from './SettingsDrawer'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SettingsDrawerItemProps {
	open: boolean
	data: SettingsDrawerNavItem
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const ListItemButtonStyled = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'component',
})<ListItemButtonProps>(({ theme }) => ({
	// borderLeft: selected ? `4px solid ${theme.palette.secondary.main}` : `4px solid transparent`,
	boxSizing: 'border-box',
	paddingLeft: `calc(${theme.spacing(3)} - 5px)`,
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	display: 'flex',
	justifyContent: 'flex-start',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	'& .MuiListItemIcon-root': {
		minWidth: `${APP_BAR_WEIGHT - 13}px`,
	},
	'& .MuiTypography-root': {
		fontWeight: 600,
	},
	'&:hover': {
		backgroundColor: theme.palette.background.paper,
		'& .MuiListItemButton-root': {
			backgroundColor: theme.palette.background.paper,
		},
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SettingsDrawerItem: React.FC<SettingsDrawerItemProps> = (props) => {
	return (
		<Link href={props.data.href}>
			<StartonTooltip arrow title={!props.open ? props.data.name : ''} placement="right" color={'default'}>
				{/* @ts-ignore */}
				<ListItemButtonStyled>
					<ListItemIcon color={'primary'} sx={{ color: 'text.primary' }}>
						{props.data.icon}
					</ListItemIcon>
					<ListItemText primary={props.data.name} />
				</ListItemButtonStyled>
			</StartonTooltip>
		</Link>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
SettingsDrawerItem.displayName = 'SettingsDrawerItem'
