/*
| Developed by Starton
| Filename : gtag.service.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { PostHog } from 'posthog-js'
import { GTM_IS_ACTIVATED } from './GoogleTagManager'
import { CustomEventData, DataLayerObject, PageEventData, UserEventData } from './types'

/*
|--------------------------------------------------------------------------
| Type declaration
|--------------------------------------------------------------------------
*/
declare global {
	interface Window {
		// dataLayer from gtag
		dataLayer?: Array<object>
	}
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const IS_DEV_ENV = false //process.env.NODE_ENV === 'development'

/*
|--------------------------------------------------------------------------
| Class
|--------------------------------------------------------------------------
*/

/**
 * Google Tag Manager service for send data with dataLayer.
 * @class GtagService
 */
export class GtagService {
	/**
	 * Method for push data into dataLayer and send to Google Tag manager.
	 * @param obj
	 * @private
	 */
	private static pushData(obj: DataLayerObject) {
		if (IS_DEV_ENV) {
			console.log(`[GTAG Services] New event pushed from ${obj.event}`, obj)
		}

		if (GTM_IS_ACTIVATED && typeof window !== 'undefined' && window.dataLayer) {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push(obj)
		}
	}

	/**
	 * Send user data to Google Tag Manager.
	 * @param userData
	 * @param posthog
	 */
	public static createUserEvent(userData: UserEventData, posthog?: PostHog) {
		this.pushData({
			event: 'User Event',
			...userData,
		})
		if (posthog) {
			const { name, ...payload } = userData
			// Ced made me do it...
			let _name = name
			if (_name === 'switch-project') {
				_name = 'on-project'
			}
			const eventName = _name
				.split('-')
				.map((item) => {
					return item[0].toUpperCase() + item.slice(1)
				})
				.join(' ')
			posthog.capture(eventName, payload)
		}
	}

	/**
	 * Create new generic event and send to Google Tag Manager.
	 * @param eventData
	 */
	public static createGenericEvent(eventData: CustomEventData) {
		this.pushData({
			event: 'Custom Event',
			...eventData,
		})
	}

	/**
	 * Create new page event and send to Google Tag Manager.
	 * @param pageData
	 */
	public static createPageEvent(pageData: PageEventData) {
		this.pushData({
			event: 'Page Event',
			...pageData,
		})
	}
}
