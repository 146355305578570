/*
| Developed by Starton
| Filename : ProductUpdates.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Backdrop, Badge, Box, BoxProps, ListItemIcon, ListItemText, Slide, styled, useTheme } from '@mui/material'
import { ClickAwayListener, Portal } from '@mui/base'
import { AnnouncementOutlined } from '@mui/icons-material'
import { StartonTooltip } from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import { DashboardDrawerMenuItemStyled } from '../TopMenu/DashboardDrawerMenuItem'
import { ProductUpdatesHeader } from './ProductUpdatesHeader'
import { ProductUpdatesBody } from './ProductUpdatesBody'
import { ProductUpdatesFooter } from './ProductUpdatesFooter'
import { useVersionReleaseNotes } from '@/services/fapi'
import { LocalStorageService } from '@/services/localStorage/localStorage.service'
import { NavDefaultConfig } from '@/config/common/nav.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ProductUpdatesProps {
	open: boolean
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ProductUpdatesContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<
	BoxProps & { open: boolean }
>(({ theme, open }) => ({
	position: 'fixed',
	bottom: 0,
	left: open ? `${NavDefaultConfig.sizes.drawer.widthOpened}px` : `${NavDefaultConfig.sizes.drawer.widthClosed}px`,
	transformOrigin: 'bottom left',
	minWidth: '444px',
	maxWidth: '444px',
	zIndex: 999,
	borderRadius: '2px 2px 0 0',
	backgroundColor: theme.palette.background.default,
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',
	transition: 'all 0.2s ease-in-out',
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ProductUpdates: React.FC<ProductUpdatesProps> = (props) => {
	const { open } = props
	const [showBadge, setShowBadge] = React.useState(false)
	const [openProductUpdate, setOpenProductUpdate] = React.useState(false)
	const [turnOffCheck, setTurnOffCheck] = React.useState(false)
	const theme = useTheme()
	const { t } = useTranslation()

	// SWR
	//--------------------------------------------------------------------------
	const { data } = useVersionReleaseNotes()

	// Memoization
	//--------------------------------------------------------------------------
	const lastProductUpdateHasBeenSeen = React.useMemo(() => {
		const lastProductUpdateId = data?.data?.[0].id ?? null
		const lastProductUpdateIdCached = LocalStorageService?.getItem('starton.product-updates.last-viewed') ?? null
		return lastProductUpdateIdCached !== String(lastProductUpdateId)
	}, [data?.data])

	// Do we open product updates ?
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		const alwaysHide = LocalStorageService?.getItem('starton.product-updates.always-hide') === 'true' ?? null

		if (alwaysHide) {
			setOpenProductUpdate(false)
			setTurnOffCheck(true)
		} else {
			// If we already see last release not then we hide
			setOpenProductUpdate(lastProductUpdateHasBeenSeen)
		}
	}, [lastProductUpdateHasBeenSeen])

	// Do we show badge ?
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		setShowBadge(lastProductUpdateHasBeenSeen)
	}, [lastProductUpdateHasBeenSeen])

	// Methods
	// ----------------------------------------------------------------------------
	const handleClose = React.useCallback(() => {
		if (!openProductUpdate) return null

		LocalStorageService.setItem('starton.product-updates.always-hide', String(turnOffCheck))

		setOpenProductUpdate(false)
	}, [openProductUpdate, turnOffCheck])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<ClickAwayListener onClickAway={handleClose}>
			<Box>
				<StartonTooltip
					arrow
					title={!open ? t('common:productUpdate.menuItemName') : ''}
					placement="right"
					color="default"
				>
					<DashboardDrawerMenuItemStyled
						onClick={() => setOpenProductUpdate((prevState) => !prevState)}
						data-testid="product-updates"
					>
						<ListItemIcon color="primary" sx={{ color: 'text.primary' }}>
							<Badge color={'error'} variant={'dot'} invisible={!showBadge}>
								<AnnouncementOutlined />
							</Badge>
						</ListItemIcon>
						<ListItemText
							primary={t('common:productUpdate.menuItemName')}
							primaryTypographyProps={{
								fontWeight: theme.typography.nav.body.md.fontWeight,
								fontSize: theme.typography.nav.body.md.fontSize,
								lineHeight: theme.typography.nav.body.md.lineHeight,
							}}
						/>
					</DashboardDrawerMenuItemStyled>
				</StartonTooltip>
				<Portal>
					<Backdrop open={openProductUpdate} onClick={handleClose}>
						<Slide direction={'right'} in={openProductUpdate} mountOnEnter>
							<ProductUpdatesContainer open={props.open}>
								<ProductUpdatesHeader setOpenProductUpdate={setOpenProductUpdate} />
								<ProductUpdatesBody setShowBadge={setShowBadge} />
								<ProductUpdatesFooter
									handleClose={handleClose}
									turnOffCheck={turnOffCheck}
									setTurnOffCheck={setTurnOffCheck}
								/>
							</ProductUpdatesContainer>
						</Slide>
					</Backdrop>
				</Portal>
			</Box>
		</ClickAwayListener>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
ProductUpdates.displayName = 'ProductUpdates'
