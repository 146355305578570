/*
| Developed by Starton
| Filename : ProductUpdatesHeader.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Avatar, Box, Grid, styled, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { AnnouncementOutlined } from '@mui/icons-material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ProductUpdatesHeaderProps {
	setOpenProductUpdate: React.Dispatch<React.SetStateAction<boolean>>
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ProductUpdatesHeaderStyled = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	boxSizing: 'border-box',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'space-between',
	backgroundColor: theme.palette.background.default,
	borderRadius: '2px 2px 0 0',
	padding: theme.spacing(3, 4),
	border: `1px solid ${theme.palette.divider}`,
	gap: theme.spacing(2),
	'& h2': {
		padding: 0,
	},
	'&::before': {
		content: '""',
		width: 4,
		height: '100%',
		backgroundColor: 'transparent',
		position: 'absolute',
		left: -1,
		borderRadius: '2px 0 0 0',
	},
}))

const CloseIconStyled = styled(Avatar)(({ theme }) => ({
	color: theme.palette.text.secondary,
	backgroundColor: 'transparent',

	'& svg': {
		width: 24,
		height: 24,
	},

	'&:hover': {
		cursor: 'pointer',
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ProductUpdatesHeader: React.FC<ProductUpdatesHeaderProps> = (props) => {
	return (
		<ProductUpdatesHeaderStyled>
			<Grid container gap={2}>
				<Grid item height={24}>
					<AnnouncementOutlined width={24} height={24} />
				</Grid>
				<Grid item xs>
					<Typography variant={'h2'}>Product updates</Typography>
				</Grid>
			</Grid>
			<CloseIconStyled onClick={() => props.setOpenProductUpdate(false)}>
				<CloseIcon />
			</CloseIconStyled>
		</ProductUpdatesHeaderStyled>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
ProductUpdatesHeader.displayName = 'ProductUpdatesHeader'
