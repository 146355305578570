/*
| Developed by Starton
| Filename : useVersionReleaseNotes.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import useSwr from 'swr'
import { FAPIGetParams, SecondAxiosParameter, VersionReleaseNotes } from '../contracts'
import { fapiAxiosInstance } from '@/services/fapi'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UseVersionReleaseNotesParams extends FAPIGetParams {}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const FAPI_VERSION_RELEASE_NOTES = '/api/version-release-notes'

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const versionReleaseNotesGetAll = (
	params?: UseVersionReleaseNotesParams,
	options?: SecondAxiosParameter<typeof fapiAxiosInstance>,
) => {
	return fapiAxiosInstance<VersionReleaseNotes>(
		{
			url: FAPI_VERSION_RELEASE_NOTES,
			method: 'get',
			params: {
				sort: 'release_date:desc',
				...params,
			},
		},
		options,
	)
}
export const useVersionReleaseNotes = (params?: UseVersionReleaseNotesParams) => {
	return useSwr<Awaited<VersionReleaseNotes>>(FAPI_VERSION_RELEASE_NOTES, () => versionReleaseNotesGetAll(params), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		refreshInterval: 3600000, // 1 hour
		revalidateOnMount: true,
	})
}
