/*
| Developed by Starton
| Filename: SettingsPassword.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Box, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { StartonButton, StartonTextField } from '@starton/react-ui'
import { triggerNotification } from '@/components/layouts'
import { User } from '@/services/auth-api/contracts/users'
import { usersUpdateMe, useWhoami } from '@/services/auth-api/endpoints/users'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SettingsProfileProps {}

interface FormikInitialValues extends Pick<User, 'name'> {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SettingsProfile: React.FC<SettingsProfileProps> = () => {
	const { t } = useTranslation()
	const { data: user, mutate: mutateUser } = useWhoami()

	// Formik & Yup
	//--------------------------------------------------------------------------
	const initialValues: FormikInitialValues = React.useMemo(
		() => ({
			name: user?.name ?? '',
			// termsConsent: user.termsConsent,
		}),
		[user],
	)

	const validationSchema = React.useMemo(
		() =>
			Yup.object().shape({
				name: Yup.string()
					.min(3, 'Name must be at least 3 characters long')
					.max(255, 'Name must be at most 255 characters long')
					.required('Name is required'),
			}),
		[],
	)

	// Methods
	//--------------------------------------------------------------------------
	const handleSubmit = async (values: FormikInitialValues) => {
		const { name } = values
		try {
			await usersUpdateMe({ name })
			await mutateUser()
			triggerNotification('success', t('auth/settings:update.success'))
		} catch (error) {
			triggerNotification('error', t('auth/settings:update.error'))
		}
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<Box display="flex" flexDirection="column" gap={1}>
			<Typography variant={'h2'} marginBottom={1}>
				{t('auth/settings:page.sections.profile.title')}
			</Typography>
			<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
				{() => {
					return (
						<Form
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: 32,
								maxWidth: 500,
								alignItems: 'flex-start',
							}}
						>
							<Field
								name="name"
								component={StartonTextField}
								label="Name"
								placeholder="Satoshi Nakamoto"
							/>
							<StartonButton type="submit" variant="contained">
								Save
							</StartonButton>
						</Form>
					)
				}}
			</Formik>
		</Box>
	)
}
