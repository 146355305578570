/*
| Developed by Starton
| Filename : useSupportTickets.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { SecondAxiosParameter, VersionReleaseNotes } from '../contracts'
import { fapiAxiosInstance } from '../fapi-instance'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UseSupportTicketParams {
	name: string
	email: string
	subject: string
	content: string
	from: 'app' | 'website' | 'auth'
	projectId: string
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const FAPI_TICKET = '/api/tickets'

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const supportTicketCreate = (
	params: UseSupportTicketParams,
	options?: SecondAxiosParameter<typeof fapiAxiosInstance>,
) => {
	return fapiAxiosInstance<VersionReleaseNotes>(
		{
			url: FAPI_TICKET,
			method: 'POST',
			data: {
				data: params,
			},
			headers: {
				'Content-Type': 'application/json',
			},
		},
		options,
	)
}
