/*
| Developed by Dirupt
| Filename : StartonSwitchField.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import {
	FormControl,
	FormControlLabel,
	FormControlLabelProps,
	FormHelperText,
	styled,
	Switch,
	SwitchProps,
} from '@mui/material'
import { useField } from 'formik'
import _ from 'lodash'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SwitchFieldProps extends Omit<SwitchProps, 'form' | 'name' | 'value' | 'checked' | 'defaultChecked'> {
	name: string
	label?: string
	labelPlacement?: FormControlLabelProps['labelPlacement']
	formControlLabelProps?: Omit<FormControlLabelProps, 'label' | 'labelPlacement'>
	helperText?: string
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const FormControlStyled = styled(FormControl)(({ theme }) => ({
	'& .MuiFormHelperText-root': {
		marginLeft: theme.spacing(6.375),
	},
}))

const StartonSwitchStyled = styled(FormControlLabel)(({ theme }) => ({
	// Track
	//--------------------------------------------------------------------------
	'& .MuiSwitch-root .MuiSwitch-thumb': {
		backgroundColor: theme.palette.component.uiLight,
		opacity: 1,
	},
	'& .MuiSwitch-root .MuiSwitch-track': {
		backgroundColor: theme.palette.component.uiWhite,
		opacity: 1,
	},

	'& .MuiSwitch-root .Mui-checked': {
		'& .MuiSwitch-thumb': {
			backgroundColor: theme.palette.primary.dark,
			opacity: 1,
		},
		'& + .MuiSwitch-track': {
			backgroundColor: theme.palette.primary.light,
			opacity: 1,
		},
	},

	'&.Mui-disabled': {
		opacity: 0.5,
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonSwitchField: React.FC<SwitchFieldProps> = ({
	name,
	label,
	labelPlacement = 'end',
	formControlLabelProps,
	helperText,
	...props
}) => {
	const [field, meta, helpers] = useField(name)
	const { error, showError } = React.useMemo(() => {
		const showError = meta.touched
		const error = typeof meta.error === 'string' ? meta.error : _.get(meta.error, name)
		return { error, showError }
	}, [meta.error, meta.touched, name])

	// Control
	// ----------------------------------------------------------------------------
	const switchControl = (
		<Switch
			{...field}
			{...props}
			type={'checkbox'}
			checked={field.value}
			onChange={(event, value) => {
				void helpers.setValue(value)

				if (props.onChange) {
					props.onChange(event, value)
				}
			}}
		/>
	)

	// Render
	// ----------------------------------------------------------------------------
	return (
		<FormControlStyled error={showError ? Boolean(error) : undefined}>
			{label ? (
				<StartonSwitchStyled
					control={switchControl}
					label={label}
					labelPlacement={labelPlacement}
					{...formControlLabelProps}
				/>
			) : (
				switchControl
			)}
			{(showError && error) || helperText ? <FormHelperText>{error || helperText}</FormHelperText> : null}
		</FormControlStyled>
	)
}
