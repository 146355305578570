/*
| Developed by Starton
| Filename : StartonLoadingScreen.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonLoadingScreenProps {
	loadingIcon?: 'icon' | 'logo'
	text?: boolean
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonLoadingScreen: React.FC<StartonLoadingScreenProps> = () => {
	const { t } = useTranslation()
	// const time = useTime()
	// const rotate = useTransform(time, [0, 3000], [0, 360], { clamp: false })

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			width={'100%'}
			height={'100%'}
		>
			<Grid container spacing={2} width={'auto'} direction={'column'} alignItems={'center'}>
				{/*{props.loadingIcon === 'icon' ? (*/}
				{/*	<LogoType*/}
				{/*		sx={{ width: 200, maxWidth: 200, height: 'auto', marginBottom: 2 }}*/}
				{/*		style={{*/}
				{/*			width: 200,*/}
				{/*			maxWidth: 200,*/}
				{/*			height: 'auto',*/}
				{/*			marginBottom: 2,*/}
				{/*		}}*/}
				{/*	/>*/}
				{/*) : null}*/}
				<Grid item>
					{/*{props.loadingIcon === 'icon' ? (*/}
					<CircularProgress />
					{/*) : (*/}
					{/*	<motion.div style={{ rotate }}>*/}
					{/*		<Logo width={100} height={100} />*/}
					{/*	</motion.div>*/}
					{/*)}*/}
				</Grid>
				{/*{props.text ? (*/}
				<Grid item>
					<Typography variant={'body2'}>{t('common:common.loadingText')}</Typography>
				</Grid>
				{/*) : null}*/}
			</Grid>
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
StartonLoadingScreen.displayName = 'StartonLoadingScreen'
StartonLoadingScreen.defaultProps = {
	loadingIcon: 'logo',
	text: true,
}
