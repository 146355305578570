/*
| Developed by Starton
| Filename : NotAuthRightSide.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { Box, BoxProps, Grid, GridProps, styled, Theme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { StartonType } from '@starton/react-ui-iconography'
import Link from 'next/link'
import { StartonLink } from '@/components/auth/common/StartonLink'
import { UrlsConfig } from '@/config/urls/urls.config'
import { LayoutTemplateProps } from '@/components/layouts/pages/DashboardLayout/templates/layout-template.contract'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface NotAuthRightSideProps extends LayoutTemplateProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
	height: `calc(100vh)`,
	backgroundColor: theme.palette.background.paper,
	position: 'relative',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
}))

const LogoContainer = styled(Box)<BoxProps>(({ theme }) => ({
	position: 'absolute',
	left: theme.spacing(4),
	top: theme.spacing(4),
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const NotAuthRightSide: React.FC<NotAuthRightSideProps> = (props) => {
	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

	// Render
	//--------------------------------------------------------------------------
	return (
		<StyledGrid item xs={12} lg={7}>
			<Box
				height="100%"
				display="flex"
				flexDirection="column"
				alignItems="center"
				paddingX={{ xs: 4, md: 0 }}
				width="100%"
			>
				<Box
					height="100%"
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					marginBottom={-3}
					width="100%"
				>
					{isTablet ? (
						<LogoContainer>
							<Link href={UrlsConfig.auth.login}>
								<StartonType fill={'#fff'} sx={{ width: 140, height: 30 }} />
							</Link>
						</LogoContainer>
					) : null}
					{props.children}
				</Box>
				<Box display="flex" gap={1} marginBottom={3}>
					<StartonLink href={'https://www.starton.com/terms-and-conditions'} aProps={{ target: '_blank' }}>
						Terms and conditions
					</StartonLink>
				</Box>
			</Box>
		</StyledGrid>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
NotAuthRightSide.displayName = 'NotAuthRightSide'
