/*
| Developed by Starton
| Filename : CreateKmsDialog
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import {
	StartonButton,
	StartonDialog,
	StartonDialogFooter,
	StartonDialogBody,
	StartonDialogHeader,
	StartonTextField,
} from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import { Box, Typography, useTheme } from '@mui/material'
import { Formik, Form, Field, FormikHelpers } from 'formik'
import { MenuBookOutlined } from '@mui/icons-material'
import * as Yup from 'yup'
import Link from 'next/link'
import { StartonUploadFooter } from '../../StartonUploadFooter'
import { startonScrollbar } from '../../../../styles/startonScrollbar'
import { UrlsConfig } from '../../../../config/urls/urls.config'
import { CreateKmsDto, Kms } from '@/services/api/starton/model'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
interface FormikInitialValues {
	name: string
	accountId: string
	accessKeyId: string
	region: string
	secret: string
}

export interface CreateKmsDialogProps {
	children: React.FC<{ setOpenCreateKmsDialog: React.Dispatch<React.SetStateAction<boolean>> }>
	onSubmit: (
		values: CreateKmsDto,
		formikHelpers: FormikHelpers<FormikInitialValues>,
		closeDialog: VoidFunction,
	) => void
	localeScope: 'create' | 'edit'
	kms?: Kms
}

export interface KmsImportFormikInitialValues extends FormikInitialValues {}

interface FormikFields {
	name: keyof CreateKmsDto
	label: string
	placeholder: string
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const CreateKmsDialog: React.FC<CreateKmsDialogProps> = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const { children, onSubmit, kms, localeScope } = props
	const [open, setOpen] = React.useState(false)

	// Formik
	//--------------------------------------------------------------------------
	const initialValues: FormikInitialValues = {
		name: kms?.name ?? '',
		accountId: kms?.credentials?.accountId ?? '',
		accessKeyId: kms?.credentials?.accessKeyId ?? '',
		region: kms?.credentials?.region ?? '',
		secret: '',
	}
	const formikFields = React.useMemo<Record<keyof FormikInitialValues, FormikFields>>(() => {
		const fields: Record<keyof FormikInitialValues, Omit<FormikFields, 'name'>> = t(
			'common:kms.create.fields',
			null,
			{ returnObjects: true },
		)
		return Object.entries<Omit<FormikFields, 'name'>>(fields).reduce((acc, [key, value]) => {
			// @ts-ignore
			acc[key] = { ...value, name: key }
			return acc
		}, {}) as Record<keyof FormikInitialValues, FormikFields>
	}, [t])

	// Validation
	//--------------------------------------------------------------------------
	// TODO add conditional validation to make Cedric API
	const validationSchema = Yup.object().shape({
		name: Yup.string().required(),
		accountId: Yup.string().required(),
		accessKeyId: Yup.string().required(),
		secret: Yup.string().required(),
		region: Yup.string().required(),
	})

	// Methods
	//--------------------------------------------------------------------------
	const handleSubmit = (createKmsDto: FormikInitialValues, formikHelpers: FormikHelpers<FormikInitialValues>) => {
		const { accessKeyId, accountId, region, ...restDto } = createKmsDto
		onSubmit(
			{ ...restDto, credentials: { accessKeyId, accountId, region }, provider: 'AWS' },
			formikHelpers,
			handleClose,
		)
	}

	const handleClose = () => {
		setOpen(false)
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<React.Fragment>
			{children({ setOpenCreateKmsDialog: setOpen })}
			<StartonDialog open={open} fullWidth onClose={handleClose}>
				<Formik<FormikInitialValues>
					initialValues={initialValues}
					onSubmit={handleSubmit}
					validationSchema={validationSchema}
				>
					<Form style={{ width: '100%', ...startonScrollbar(theme) }}>
						<StartonDialogHeader onClose={handleClose} data-testid="create-kms-dialog-header">
							{t(`common:kms.${localeScope}.title`)}
						</StartonDialogHeader>
						<StartonDialogBody>
							<Box width="100%" display="flex" flexDirection="column" gap={3}>
								{t(`common:kms.${localeScope}.description`) ? (
									<Typography variant="body1" color="text.secondary">
										{t(`common:kms.${localeScope}.description`)}
									</Typography>
								) : null}
								<Link
									href={UrlsConfig.doc.wallet.connectKms}
									passHref
									legacyBehavior
									data-testid="create-kms-doc"
								>
									<a target="_blank">
										<StartonButton startIcon={<MenuBookOutlined />} variant="outlined">
											{t('common:common.documentation')}
										</StartonButton>
									</a>
								</Link>
								{Object.values(formikFields).map((field) => (
									<Field
										component={StartonTextField}
										{...field}
										key={field.name}
										fullWidth
										data-testid={`create-kms-dialog-${field.name}`}
									/>
								))}
							</Box>
						</StartonDialogBody>
						<StartonDialogFooter>
							<StartonUploadFooter
								display="flex"
								width="100%"
								justifyContent="center"
								margin={0}
								previousText={t('common:common.cancel')}
								previousButtonProps={{ startIcon: null, variant: 'text' }}
								submitText={t(`common:kms.${localeScope}.submitText`)}
								onPrevious={handleClose}
							/>
						</StartonDialogFooter>
					</Form>
				</Formik>
			</StartonDialog>
		</React.Fragment>
	)
}
