/*
| Developed by Starton
| Filename: HomeVideo.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Box, BoxProps } from '@mui/material'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import { useGlobalTranslation } from '@/hooks/i18n/useGlobalTranslation'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface HomeVideoProps {
	url: string
	boxProps?: BoxProps
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const HomeVideo: React.FC<HomeVideoProps> = ({ url, boxProps }) => {
	const { t } = useGlobalTranslation()

	// Memoization
	//--------------------------------------------------------------------------
	const { videoId, playlistId } = React.useMemo(() => {
		const _url = new URL(url)
		return { videoId: _url.searchParams.get('v') ?? '', playlistId: _url.searchParams.get('list') ?? '' }
	}, [url])

	// Render
	//--------------------------------------------------------------------------
	return (
		<Box
			width={{ width: '100%' }}
			sx={{
				aspectRatio: '16/9',
				backgroundColor: 'background.paper',
			}}
			padding={3}
			{...boxProps}
		>
			<LiteYouTubeEmbed
				id={videoId}
				playlistCoverId={playlistId}
				title={t('dashboard:homeOverview.video.videoTitle')}
				poster="hqdefault"
				data-testid="home-video"
			/>
		</Box>
	)
}
