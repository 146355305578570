/*
| Developed by Starton
| Filename : StartonUploadFooter.tsx
| Author : Calixte De Tourris (calixte@starton.com)
*/

import React from 'react'
import { styled, Box, BoxProps } from '@mui/material'
import { StartonButton, StartonButtonProps } from '@starton/react-ui'
import { ArrowBack } from '@mui/icons-material'
import useTranslation from 'next-translate/useTranslation'
import { useFormikContext } from 'formik'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonUploadFooterProps extends Omit<BoxProps, 'onSubmit'> {
	submitText?: string
	submitButtonProps?: StartonButtonProps
	previousText?: string
	previousButtonProps?: StartonButtonProps
	onSubmit?: (event: React.MouseEvent<HTMLButtonElement>) => void
	onPrevious?: (event: React.MouseEvent<HTMLButtonElement>) => void
	noSubmit?: boolean
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const StartonUploadFooterStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	gap: theme.spacing(1.5),
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonUploadFooter: React.FC<StartonUploadFooterProps> = (props) => {
	const {
		submitText,
		submitButtonProps,
		previousText,
		previousButtonProps,
		onSubmit,
		onPrevious,
		noSubmit,
		...boxProps
	} = props
	const { t } = useTranslation()
	const formikProps = useFormikContext()

	// Render
	//--------------------------------------------------------------------------
	return (
		<StartonUploadFooterStyled marginY={5} {...boxProps}>
			{onPrevious ? (
				<StartonButton
					onClick={onPrevious}
					color="secondary"
					startIcon={<ArrowBack />}
					size="large"
					data-testid={'form-footer-previous-button'}
					{...previousButtonProps}
				>
					{previousText ?? t('common:common.previous')}
				</StartonButton>
			) : null}
			{!noSubmit ? (
				<StartonButton
					type={'submit'}
					variant={'contained'}
					onClick={onSubmit}
					size="large"
					disabled={formikProps?.isSubmitting}
					data-testid={'form-footer-submit-button'}
					{...submitButtonProps}
				>
					{submitText ?? t('common:common.submit')}
				</StartonButton>
			) : null}
		</StartonUploadFooterStyled>
	)
}

export const StartonUploadFooterNoFormik: React.FC<StartonUploadFooterProps> = (props) => {
	const {
		submitText,
		submitButtonProps,
		previousText,
		previousButtonProps,
		onSubmit,
		onPrevious,
		noSubmit,
		...boxProps
	} = props
	const { t } = useTranslation()

	// Render
	//--------------------------------------------------------------------------
	return (
		<StartonUploadFooterStyled marginY={5} {...boxProps}>
			{onPrevious ? (
				<StartonButton
					onClick={onPrevious}
					color="secondary"
					startIcon={<ArrowBack />}
					size="large"
					data-testid={'form-footer-previous-button'}
					{...previousButtonProps}
				>
					{previousText ?? t('common:common.previous')}
				</StartonButton>
			) : null}
			{!noSubmit ? (
				<StartonButton
					type="submit"
					variant="contained"
					onClick={onSubmit}
					size="large"
					data-testid={'form-footer-submit-button'}
					{...submitButtonProps}
				>
					{submitText ?? t('common:common.submit')}
				</StartonButton>
			) : null}
		</StartonUploadFooterStyled>
	)
}
