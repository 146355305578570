/*
| Developed by Starton
| Filename : localStorage.service.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type LocalStorageName =
	| 'starton.third-party.wallet-warning-state'
	| 'starton.dev-mode.open'
	| 'starton.nav-drawer.state'
	| 'starton.product-updates.last-viewed'
	| 'starton.product-updates.always-hide'
	| 'starton.cache.dashboard'
	| 'starton.white-label.settings'

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export class LocalStorageService {
	// Utils
	// ----------------------------------------------------------------------------
	private static isClientSide(): boolean {
		return typeof window !== 'undefined'
	}

	// Common
	// ----------------------------------------------------------------------------
	static getItem(key: LocalStorageName) {
		if (this.isClientSide()) {
			return window.localStorage.getItem(key)
		}
	}

	static setItem<T extends string>(key: LocalStorageName, data: T) {
		if (this.isClientSide()) {
			window.localStorage.setItem(key, data)
		}
	}

	static removeItem(key: LocalStorageName) {
		if (this.isClientSide()) {
			window.localStorage.removeItem(key)
		}
	}

	static clear() {
		if (this.isClientSide()) {
			window.localStorage.clear()
		}
	}
}
