/*
| Developed by Starton
| Filename : StartonUnderConstructionPage.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Box, Grid, Typography } from '@mui/material'
import { UnderConstructionIcon } from '@/components/common/icons/UnderConstructionIcon'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonUnderConstructionPageProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonUnderConstructionPage: React.FC<StartonUnderConstructionPageProps> = () => {
	const { t } = useTranslation()

	return (
		<Box
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'center'}
			alignItems={'center'}
			width={'100%'}
			height={'100%'}
			padding={4}
		>
			<Grid container spacing={2} width={'auto'} direction={'column'} alignItems={'center'}>
				<Grid item justifyContent={'center'} height={200}>
					<Box display="flex" width="50%" maxWidth={200}>
						<UnderConstructionIcon />
					</Box>
				</Grid>
				<Grid item textAlign={'center'}>
					<Typography variant={'body2'} color={'primary'}>
						{t('common:common.responsiveUnderConstruction')}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
StartonUnderConstructionPage.displayName = 'StartonUnderConstructionPage'
