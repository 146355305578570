/*
| Developed by Starton
| Filename : commonSlice.ts
*/

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/stores/store'
import { StartonAddressFieldProps } from '@/components/common/StartonAddressField'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface CommonAppBarBreadcrumbs {
	name: string
	href?: string
	alt?: string
}
export interface CommonsState {
	openSupportDialog: boolean
	appBar: {
		breadcrumbs: Array<CommonAppBarBreadcrumbs>
		addressFieldProps: Pick<StartonAddressFieldProps, 'address' | 'href'> | null
	}
	openPremiumDialog: boolean
}

/*
|--------------------------------------------------------------------------
| INIT STATE
|--------------------------------------------------------------------------
*/
const initialState: CommonsState = {
	openSupportDialog: false,
	appBar: {
		breadcrumbs: [
			{
				name: 'IPFS',
			},
		],
		addressFieldProps: null,
	},
	openPremiumDialog: false,
}

/*
|--------------------------------------------------------------------------
| SLICE
|--------------------------------------------------------------------------
*/
export const commonsSlice = createSlice({
	name: 'commons',
	initialState,
	reducers: {
		setAppBarBreadcrumbs: (state, action: PayloadAction<Array<CommonAppBarBreadcrumbs>>) => {
			state.appBar.breadcrumbs = action.payload
		},
		setAppBarInfos: (state, action: PayloadAction<StartonAddressFieldProps | null>) => {
			state.appBar.addressFieldProps = action.payload
		},
		setOpenPremiumDialog: (state, action: PayloadAction<boolean>) => {
			state.openPremiumDialog = action.payload
		},
		setOpenSupportDialog: (state, action: PayloadAction<boolean>) => {
			state.openSupportDialog = action.payload
		},
	},
})

/*
|--------------------------------------------------------------------------
| EXPORT SELECTORS
|--------------------------------------------------------------------------
*/
export const selectAppBarBreadcrumbs = (state: RootState) => state.common.appBar.breadcrumbs
export const selectAppBarInfos = (state: RootState) => state.common.appBar.addressFieldProps
export const selectOpenPremiumDialog = (state: RootState) => state.common.openPremiumDialog
export const selectOpenSupportDialog = (state: RootState) => state.common.openSupportDialog

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export const { setAppBarBreadcrumbs, setAppBarInfos, setOpenPremiumDialog, setOpenSupportDialog } = commonsSlice.actions
export default commonsSlice.reducer
