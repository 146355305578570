/*
| Developed by Starton
| Filename : support.config.ts
| Author : Calixte De Tourris (calixte@starton.com)
*/

/*
|--------------------------------------------------------------------------
| Config
|--------------------------------------------------------------------------
*/
// These ids match the locales in `common:support`
export const supportOptionsNames = ['bug', 'plan', 'product', 'other'] as const
export type SupportOptionName = (typeof supportOptionsNames)[number]
