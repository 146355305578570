/*
| Developed by Starton
| Filename : auth-api-instance.ts
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'

/*
|--------------------------------------------------------------------------
| Auth API instance
|--------------------------------------------------------------------------
*/
export const AuthApi = axios.create({
	baseURL: process?.env?.NEXT_PUBLIC_AUTH_API_URL as string,
	withCredentials: true,
})

export const authApi = async <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	const { data } = await AuthApi({
		...config,
		...options,
	})
	return data
}

/*
|--------------------------------------------------------------------------
| Interceptors
|--------------------------------------------------------------------------
*/
const commonResponseInterceptorSuccess = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
	return response
}
const commonResponseInterceptorError = (error: AxiosError): any => {
	if (error?.response) {
		// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION, {
		// 	extra: {
		// 		data: error.response.data,
		// 	},
		// })
		return Promise.reject(error)
	} else if (error?.request) {
		console.error('==============================')
		console.error('[Starton API] Request problem.')
		console.error('> Code: ', error.code)
		console.error('> Message: ', error.message)
		console.error('> Status: ', error.status)
		console.error('==============================\n\n')
	} else {
		// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION)
	}

	return Promise.reject(error)
}
AuthApi.interceptors.response.use(commonResponseInterceptorSuccess, commonResponseInterceptorError)
