/*
| Developed by Starton
| Filename : billing.config.ts
| Author : Calixte De Tourris (calixte@starton.com)
*/

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
import { Translate } from 'next-translate'
import { Theme, useTheme } from '@mui/material'
import { useGlobalTranslation } from '@/hooks/i18n/useGlobalTranslation'

export type BillingPayablePlanNames = 'TIER_1' | 'TIER_2'
export type BillingPlanNames = BillingPayablePlanNames | 'FREE' | 'CUSTOM'
export interface BillingPlan {
	name: string
	price: string
	description: string
	color: string
	specs: Array<string>
	disabled: boolean
	order: number
}

/*
|--------------------------------------------------------------------------
| Config
|--------------------------------------------------------------------------
*/
export const billingPaidPlans = (t: Translate, theme: Theme): Record<BillingPlanNames, BillingPlan> => ({
	FREE: {
		price: '0€',
		name: t('common:billingPlans.FREE.name'),
		description: t('common:billingPlans.FREE.description'),
		color: theme.palette.warning.main,
		specs: t('common:billingPlans.FREE.specs', {}, { returnObjects: true }),
		disabled: false,
		order: 0,
	},
	TIER_1: {
		price: '50€',
		name: t('common:billingPlans.TIER_1.name'),
		description: t('common:billingPlans.TIER_1.description'),
		color: theme.palette.warning.main,
		specs: t('common:billingPlans.TIER_1.specs', {}, { returnObjects: true }),
		disabled: true,
		order: 1,
	},
	TIER_2: {
		price: '99€',
		name: t('common:billingPlans.TIER_2_V2.name'),
		description: t('common:billingPlans.TIER_2_V2.description'),
		color: theme.palette.warning.main,
		specs: t('common:billingPlans.TIER_2_V2.specs', {}, { returnObjects: true }),
		disabled: false,
		order: 1,
	},
	CUSTOM: {
		price: 'CUSTOM',
		name: t('common:billingPlans.CUSTOM.name'),
		description: t('common:billingPlans.CUSTOM.description'),
		color: theme.palette.warning.main,
		specs: t('common:billingPlans.CUSTOM.specs', {}, { returnObjects: true }),
		disabled: false,
		order: 2,
	},
})

export const stripeTaxIds = {
	'Australia-au_abn': { countryName: 'Australia', taxType: 'au_abn' },
	'Australia-au_arn': { countryName: 'Australia', taxType: 'au_arn' },
	'Austria-eu_vat': { countryName: 'Austria', taxType: 'eu_vat' },
	'Belgium-eu_vat': { countryName: 'Belgium', taxType: 'eu_vat' },
	'Brazil-br_cnpj': { countryName: 'Brazil', taxType: 'br_cnpj' },
	'Brazil-br_cpf': { countryName: 'Brazil', taxType: 'br_cpf' },
	'Bulgaria-bg_uic': { countryName: 'Bulgaria', taxType: 'bg_uic' },
	'Bulgaria-eu_vat': { countryName: 'Bulgaria', taxType: 'eu_vat' },
	'Canada-ca_bn': { countryName: 'Canada', taxType: 'ca_bn' },
	'Canada-ca_gst_hst': { countryName: 'Canada', taxType: 'ca_gst_hst' },
	'Canada-ca_pst_bc': { countryName: 'Canada', taxType: 'ca_pst_bc' },
	'Canada-ca_pst_mb': { countryName: 'Canada', taxType: 'ca_pst_mb' },
	'Canada-ca_pst_sk': { countryName: 'Canada', taxType: 'ca_pst_sk' },
	'Canada-ca_qst': { countryName: 'Canada', taxType: 'ca_qst' },
	'Chile-cl_tin': { countryName: 'Chile', taxType: 'cl_tin' },
	'Croatia-eu_vat': { countryName: 'Croatia', taxType: 'eu_vat' },
	'Cyprus-eu_vat': { countryName: 'Cyprus', taxType: 'eu_vat' },
	'Czech-Republic-eu_vat': { countryName: 'Republic Czech', taxType: 'eu_vat' },
	'Denmark-eu_vat': { countryName: 'Denmark', taxType: 'eu_vat' },
	'Egypt-eg_tin': { countryName: 'Egypt', taxType: 'eg_tin' },
	'Estonia-eu_vat': { countryName: 'Estonia', taxType: 'eu_vat' },
	'EU-eu_oss_vat': { countryName: 'EU', taxType: 'eu_oss_vat' },
	'Finland-eu_vat': { countryName: 'Finland', taxType: 'eu_vat' },
	'France-eu_vat': { countryName: 'France', taxType: 'eu_vat' },
	'Georgia-ge_vat': { countryName: 'Georgia', taxType: 'ge_vat' },
	'Germany-eu_vat': { countryName: 'Germany', taxType: 'eu_vat' },
	'Greece-eu_vat': { countryName: 'Greece', taxType: 'eu_vat' },
	'Hong Kong-hk_br': { countryName: 'Hong Kong', taxType: 'hk_br' },
	'Hungary-eu_vat': { countryName: 'Hungary', taxType: 'eu_vat' },
	'Hungary-hu_tin': { countryName: 'Hungary', taxType: 'hu_tin' },
	'Iceland-is_vat': { countryName: 'Iceland', taxType: 'is_vat' },
	'India-in_gst': { countryName: 'India', taxType: 'in_gst' },
	'Indonesia-id_npwp': { countryName: 'Indonesia', taxType: 'id_npwp' },
	'Ireland-eu_vat': { countryName: 'Ireland', taxType: 'eu_vat' },
	'Israel-il_vat': { countryName: 'Israel', taxType: 'il_vat' },
	'Italy-eu_vat': { countryName: 'Italy', taxType: 'eu_vat' },
	'Japan-jp_cn': { countryName: 'Japan', taxType: 'jp_cn' },
	'Japan-jp_rn': { countryName: 'Japan', taxType: 'jp_rn' },
	'Japan-jp_trn': { countryName: 'Japan', taxType: 'jp_trn' },
	'Kenya-ke_pin': { countryName: 'Kenya', taxType: 'ke_pin' },
	'Latvia-eu_vat': { countryName: 'Latvia', taxType: 'eu_vat' },
	'Liechtenstein-li_uid': { countryName: 'Liechtenstein', taxType: 'li_uid' },
	'Lithuania-eu_vat': { countryName: 'Lithuania', taxType: 'eu_vat' },
	'Luxembourg-eu_vat': { countryName: 'Luxembourg', taxType: 'eu_vat' },
	'Malaysia-my_frp': { countryName: 'Malaysia', taxType: 'my_frp' },
	'Malaysia-my_itn': { countryName: 'Malaysia', taxType: 'my_itn' },
	'Malaysia-my_sst': { countryName: 'Malaysia', taxType: 'my_sst' },
	'Malta-eu_vat': { countryName: 'Malta', taxType: 'eu_vat' },
	'Mexico-mx_rfc': { countryName: 'Mexico', taxType: 'mx_rfc' },
	'Netherlands-eu_vat': { countryName: 'Netherlands', taxType: 'eu_vat' },
	'New Zealand-nz_gst': { countryName: 'New Zealand', taxType: 'nz_gst' },
	'Norway-no_vat': { countryName: 'Norway', taxType: 'no_vat' },
	'Philippines-ph_tin': { countryName: 'Philippines', taxType: 'ph_tin' },
	'Poland-eu_vat': { countryName: 'Poland', taxType: 'eu_vat' },
	'Portugal-eu_vat': { countryName: 'Portugal', taxType: 'eu_vat' },
	'Romania-eu_vat': { countryName: 'Romania', taxType: 'eu_vat' },
	'Russia-ru_inn': { countryName: 'Russia', taxType: 'ru_inn' },
	'Russia-ru_kpp': { countryName: 'Russia', taxType: 'ru_kpp' },
	'Saudi Arabia-sa_vat': { countryName: 'Saudi Arabia', taxType: 'sa_vat' },
	'Singapore-sg_gst': { countryName: 'Singapore', taxType: 'sg_gst' },
	'Singapore-sg_uen': { countryName: 'Singapore', taxType: 'sg_uen' },
	'Slovakia-eu_vat': { countryName: 'Slovakia', taxType: 'eu_vat' },
	'Slovenia-eu_vat': { countryName: 'Slovenia', taxType: 'eu_vat' },
	'Slovenia-si_tin': { countryName: 'Slovenia', taxType: 'si_tin' },
	'South-Africa-za_vat': { countryName: 'South Africa', taxType: 'za_vat' },
	'South-Korea-kr_brn': { countryName: 'South Korea', taxType: 'kr_brn' },
	'Spain-es_cif': { countryName: 'Spain', taxType: 'es_cif' },
	'Spain-eu_vat': { countryName: 'Spain', taxType: 'eu_vat' },
	'Sweden-eu_vat': { countryName: 'Sweden', taxType: 'eu_vat' },
	'Switzerland-ch_vat': { countryName: 'Switzerland', taxType: 'ch_vat' },
	'Taiwan-tw_vat': { countryName: 'Taiwan', taxType: 'tw_vat' },
	'Thailand-th_vat': { countryName: 'Thailand', taxType: 'th_vat' },
	'Turkey-tr_tin': { countryName: 'Turkey', taxType: 'tr_tin' },
	'Ukraine-ua_vat': { countryName: 'Ukraine', taxType: 'ua_vat' },
	'United-Arab-Emirates-ae_trn': { countryName: 'United Arab Emirates', taxType: 'ae_trn' },
	'United-Kingdom-eu_vat': { countryName: 'United Kingdom', taxType: 'eu_vat' },
	'United-Kingdom-gb_vat': { countryName: 'United Kingdom', taxType: 'gb_vat' },
	'United-States-us_ein': { countryName: 'United States', taxType: 'us_ein' },
}

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useBillingPlans = (): Record<BillingPlanNames, BillingPlan> => {
	const { t } = useGlobalTranslation()
	const theme = useTheme()
	return billingPaidPlans(t, theme)
}
