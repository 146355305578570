/*
| Developed by Starton
| Filename: StartonPlaygroundLogo.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonPlaygroundLogoProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonPlaygroundLogo: React.FC<StartonPlaygroundLogoProps> = (props) => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<SvgIcon width="143" height="28" viewBox="0 0 143 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M49.7373 13.4579C49.7373 11.2255 48.565 9.77341 46.0251 9.14488L41.0538 7.9095C40.0986 7.64942 39.8598 7.28098 39.8598 6.84751C39.8598 5.98058 40.6196 5.61213 42.0741 5.61213C44.1581 5.61213 45.0916 6.30568 45.0916 7.95285L49.1511 7.95285C49.1511 4.63684 46.7849 2.38281 42.009 2.38281C37.9929 2.38281 35.7786 3.96496 35.7786 6.93421C35.7786 8.86313 36.6035 10.4019 39.252 11.1172L44.3752 12.4609C45.2436 12.6776 45.6777 13.0894 45.6777 13.6313C45.6777 14.5849 44.8528 14.975 42.9207 14.975C40.0769 14.975 39.2303 14.1731 39.2303 12.0058H35.149C35.149 15.972 37.4285 18.2693 42.8339 18.2693C47.7401 18.2693 49.7373 16.3621 49.7373 13.4579Z"
				fill="white"
			/>
			<path
				d="M50.1343 2.68624V6.13229L55.0622 6.13229V17.9659H58.9914V6.13229L63.9193 6.13229V2.68624L50.1343 2.68624Z"
				fill="white"
			/>
			<path
				d="M62.3771 17.9659L66.5235 17.9659L67.9563 13.3495H73.7742L75.2069 17.9659H79.3967L74.5123 2.68624L67.2833 2.68624L62.3771 17.9659ZM69.0417 9.90345L70.8652 4.82656L72.6887 9.90345L69.0417 9.90345Z"
				fill="white"
			/>
			<path
				d="M80.3816 17.9659H84.3543V12.786L88.5473 12.7698C88.8374 12.7798 89.1733 12.7481 89.6168 12.9825C90.0603 13.2169 90.1385 13.7117 90.1385 13.8419C90.1385 15.3674 90.1907 17.9659 90.1907 17.9659H94.1666V16.4984C94.1666 15.2743 94.2299 13.6679 94.1666 12.8783C94.1144 12.2273 93.4515 10.7428 91.5472 10.6386L90.78 10.597C92.3648 10.2502 93.9929 9.18823 93.9929 6.73915C93.9929 3.37978 90.6064 2.68624 87.133 2.68624L80.3816 2.68624V17.9659ZM84.3543 9.36161V6.13229L87.133 6.13229C88.8045 6.13229 90.0636 6.17564 90.0636 7.75779C90.0636 9.29659 88.8914 9.36161 87.133 9.36161H84.3543Z"
				fill="white"
			/>
			<path
				d="M95.0992 2.68624V6.13229L100.027 6.13229V17.9659H103.956V6.13229L108.884 6.13229V2.68624L95.0992 2.68624Z"
				fill="white"
			/>
			<path
				d="M109.157 10.3152C109.157 14.845 112.218 18.2693 117.211 18.2693C122.182 18.2693 125.221 14.845 125.221 10.3152C125.221 5.80719 122.182 2.38281 117.211 2.38281C112.218 2.38281 109.157 5.80719 109.157 10.3152ZM113.238 10.3152C113.238 7.56273 114.562 5.95891 117.211 5.95891C119.837 5.95891 121.162 7.54106 121.162 10.3152C121.162 13.0894 119.859 14.6932 117.211 14.6932C114.562 14.6932 113.238 13.0894 113.238 10.3152Z"
				fill="white"
			/>
			<path
				d="M127.085 17.9659H131.036V4.77881L135.334 17.9659L142.78 17.9659V2.68624L138.829 2.68624V15.9254L134.531 2.68624L127.085 2.68624V17.9659Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.9166 0.325061C13.1338 -0.109956 12.1816 -0.108246 11.4003 0.329578L1.32137 5.97743C0.505381 6.43468 0 7.29754 0 8.23346V19.5512C0 20.4723 0.489572 21.3238 1.28524 21.7866L11.3643 27.6498C12.1641 28.115 13.1513 28.1168 13.9528 27.6545L24.125 21.7869C24.9253 21.3252 25.4184 20.4711 25.4184 19.5467V8.23794C25.4184 7.2987 24.9095 6.43332 24.089 5.97739L13.9166 0.325061ZM11.9262 1.7054L2.03293 7.24923C1.67694 7.44871 1.45646 7.82515 1.45646 8.23346V19.5512C1.45646 19.9531 1.67004 20.3245 2.01717 20.5265L12.0962 26.3896C12.3402 26.5316 12.6242 26.5746 12.8925 26.5187C12.8999 26.5054 12.9073 26.4921 12.9147 26.4788C13.8552 24.7899 15.1519 22.4613 15.0795 19.7334C14.9975 16.6431 13.7536 15.2518 12.5114 13.8625C11.2906 12.4971 10.0714 11.1336 9.9587 8.16079C9.86661 5.7321 11.0952 3.14356 11.9262 1.7054Z"
				fill="white"
			/>
			<path
				d="M40.7255 24.1433C40.7255 24.5657 40.6338 24.9015 40.4506 25.1507C40.2673 25.4 40.0153 25.5797 39.6945 25.6898C39.3738 25.7974 39.0072 25.8512 38.5948 25.8512H37.8509V27.921H37.3334V22.5142H38.6514C39.2875 22.5142 39.7915 22.6467 40.1635 22.9117C40.5382 23.174 40.7255 23.5846 40.7255 24.1433ZM40.1595 24.1552C40.1595 23.8482 40.0961 23.6069 39.9694 23.4311C39.8454 23.2553 39.677 23.1307 39.4641 23.0573C39.2538 22.9812 39.0193 22.9431 38.7606 22.9431H37.8509V25.4144H38.704C38.9816 25.4144 39.2296 25.3803 39.4479 25.3121C39.6662 25.2412 39.8387 25.1153 39.9654 24.9343C40.0948 24.7507 40.1595 24.4909 40.1595 24.1552Z"
				fill="white"
			/>
			<path d="M42.6326 27.921V22.5142H43.1501V27.4331H45.7255L45.6608 27.921H42.6326Z" fill="white" />
			<path
				d="M50.1878 26.4769H47.8186L47.3294 27.921H46.8443L48.6838 22.5142H49.3671L51.2107 27.921H50.6771L50.1878 26.4769ZM47.9925 26.0204H50.018L49.0113 22.9825L47.9925 26.0204Z"
				fill="white"
			/>
			<path
				d="M54.4561 25.8197V27.921H53.9305V25.8276L52.0343 22.5142H52.6084L54.2054 25.3357L55.8227 22.5142H56.3523L54.4561 25.8197Z"
				fill="white"
			/>
			<path
				d="M59.44 27.9997C59.0276 27.9997 58.6651 27.8987 58.3524 27.6967C58.0397 27.4921 57.7958 27.1839 57.6206 26.772C57.4454 26.3601 57.3578 25.842 57.3578 25.2176C57.3578 24.7507 57.4212 24.344 57.5478 23.9977C57.6745 23.6488 57.8443 23.359 58.0573 23.1281C58.2729 22.8972 58.5141 22.7254 58.781 22.6126C59.0505 22.4972 59.3254 22.4395 59.6057 22.4395C59.9561 22.4395 60.2445 22.4867 60.471 22.5811C60.6974 22.6756 60.9197 22.8198 61.1381 23.014L60.7944 23.3603C60.4656 23.056 60.064 22.9038 59.5896 22.9038C59.3847 22.9038 59.1826 22.9431 58.9831 23.0218C58.7864 23.0979 58.6085 23.2238 58.4494 23.3996C58.2904 23.5754 58.1624 23.8115 58.0653 24.1079C57.971 24.4044 57.9238 24.773 57.9238 25.2137C57.9238 26.0217 58.06 26.6106 58.3322 26.9805C58.6044 27.3504 59.0006 27.5354 59.5208 27.5354C59.9359 27.5354 60.3241 27.4383 60.6852 27.2442V25.4734H59.4764L59.4036 25.0051H61.2189V27.5511C60.9763 27.6718 60.7189 27.7767 60.4467 27.8659C60.1745 27.9551 59.8389 27.9997 59.44 27.9997Z"
				fill="white"
			/>
			<path
				d="M66.3888 23.9899C66.3888 24.2653 66.3254 24.5054 66.1987 24.71C66.0721 24.912 65.9009 25.0786 65.6853 25.2098C65.4697 25.3383 65.2271 25.4301 64.9575 25.4852L66.6637 27.921H66.0168L64.4077 25.5246H63.5425V27.921H63.025V22.5142H64.3551C65.0209 22.5142 65.5263 22.6401 65.8713 22.892C66.2163 23.1438 66.3888 23.5098 66.3888 23.9899ZM65.8187 23.9899C65.8187 23.6174 65.7082 23.3511 65.4872 23.1911C65.2689 23.0284 64.9265 22.9471 64.4602 22.9471H63.5425V25.0917H64.4441C64.878 25.0917 65.2149 25.0091 65.4548 24.8438C65.6974 24.6759 65.8187 24.3913 65.8187 23.9899Z"
				fill="white"
			/>
			<path
				d="M71.684 25.2255C71.684 25.7633 71.6098 26.2421 71.4616 26.6618C71.3133 27.0789 71.091 27.4068 70.7945 27.6456C70.5007 27.8817 70.1328 27.9997 69.6907 27.9997C69.2514 27.9997 68.8835 27.8843 68.587 27.6534C68.2905 27.42 68.0681 27.096 67.9199 26.6815C67.7716 26.267 67.6975 25.7856 67.6975 25.2373C67.6975 24.6969 67.7716 24.2168 67.9199 23.7971C68.0681 23.3747 68.2905 23.0428 68.587 22.8015C68.8835 22.5601 69.2514 22.4395 69.6907 22.4395C70.1328 22.4395 70.5007 22.5575 70.7945 22.7936C71.091 23.0271 71.3133 23.3537 71.4616 23.7734C71.6098 24.1906 71.684 24.6746 71.684 25.2255ZM71.1341 25.2255C71.1341 24.4883 71.0222 23.9177 70.7985 23.5137C70.5748 23.1071 70.2056 22.9038 69.6907 22.9038C69.1759 22.9038 68.8067 23.1084 68.5829 23.5177C68.3592 23.9269 68.2474 24.5001 68.2474 25.2373C68.2474 25.9719 68.3633 26.5385 68.5951 26.9373C68.8269 27.336 69.1921 27.5354 69.6907 27.5354C70.2029 27.5354 70.5708 27.336 70.7945 26.9373C71.0209 26.5359 71.1341 25.9653 71.1341 25.2255Z"
				fill="white"
			/>
			<path
				d="M76.6234 22.5142V26.1857C76.6234 26.5503 76.5479 26.8691 76.3969 27.1419C76.246 27.4121 76.0371 27.6233 75.7703 27.7754C75.5034 27.925 75.1948 27.9997 74.8444 27.9997C74.4886 27.9997 74.1787 27.9237 73.9145 27.7715C73.6531 27.6167 73.4496 27.4029 73.304 27.1301C73.1612 26.8572 73.0897 26.5424 73.0897 26.1857V22.5142H73.5992V26.3588C73.5992 26.7471 73.7137 27.0383 73.9428 27.2324C74.1719 27.4239 74.4725 27.5197 74.8444 27.5197C75.2218 27.5197 75.525 27.4239 75.7541 27.2324C75.9859 27.0383 76.1018 26.7471 76.1018 26.3588V22.5142H76.6234Z"
				fill="white"
			/>
			<path
				d="M80.8997 27.921L78.7973 23.0455C78.7946 23.0874 78.796 23.1753 78.8014 23.3091C78.8068 23.4403 78.8135 23.6003 78.8216 23.7892C78.8324 23.9781 78.8405 24.1788 78.8458 24.3913C78.8539 24.6011 78.858 24.8071 78.858 25.0091V27.921H78.3486V22.5142H79.0884L81.2474 27.4213C81.2528 27.3741 81.2501 27.2521 81.2393 27.0553C81.2285 26.8586 81.2164 26.6159 81.2029 26.3273C81.1922 26.0387 81.1868 25.7331 81.1868 25.4104V22.5142H81.6962V27.921H80.8997Z"
				fill="white"
			/>
			<path
				d="M87.1773 25.1901C87.1773 25.6466 87.1275 26.0348 87.0278 26.3549C86.928 26.6723 86.7933 26.9346 86.6235 27.1419C86.4563 27.3465 86.2663 27.5052 86.0534 27.618C85.8405 27.7308 85.6181 27.8095 85.3863 27.8541C85.1572 27.8987 84.9348 27.921 84.7192 27.921H83.5144V22.5142H84.6343C84.9496 22.5142 85.2583 22.5496 85.5601 22.6205C85.862 22.6887 86.1342 22.8185 86.3768 23.01C86.6221 23.1989 86.8162 23.4691 86.959 23.8207C87.1046 24.1696 87.1773 24.6261 87.1773 25.1901ZM86.6154 25.1901C86.6154 24.6864 86.5574 24.2863 86.4415 23.9899C86.3283 23.6908 86.1801 23.4678 85.9968 23.3209C85.8135 23.174 85.6154 23.0769 85.4025 23.0297C85.1922 22.9825 84.9887 22.9589 84.792 22.9589H84.0278V27.4882H84.8041C85.0089 27.4882 85.2165 27.4567 85.4267 27.3937C85.6396 27.3308 85.8351 27.2167 86.013 27.0514C86.1935 26.8861 86.3391 26.6539 86.4496 26.3549C86.5601 26.0532 86.6154 25.6649 86.6154 25.1901Z"
				fill="white"
			/>
		</SvgIcon>
	)
}
