/*
| Developed by Starton
| Filename : axios-custom-instance.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

// eslint-disable-next-line import/no-named-as-default
import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import getConfig from 'next/config'
import { SessionStorageService } from '../sessionStorage/sessionStorage.service'
import { CookiesService } from '@/services/cookies/cookies.service'
import { SSROptions } from '@/services/api/api.contract'
import { commonResponseInterceptorError, commonResponseInterceptorSuccess } from '@/services/api/api.interceptors'
import { getStorage } from '@/hooks/useLocalStorage'

/*
|--------------------------------------------------------------------------
| Get public runtime env
|--------------------------------------------------------------------------
*/
const {
	publicRuntimeConfig: { processEnv },
} = getConfig()

/*
|--------------------------------------------------------------------------
| Create axios instance
|--------------------------------------------------------------------------
*/
export const axiosInstance = Axios.create({
	baseURL: processEnv.NEXT_PUBLIC_API_HOST as string,
	withCredentials: true,
	headers: {
		'Content-type': 'application/json',
		// 'x-api-key': processEnv.NEXT_PUBLIC_API_KEY as string,
		// Authorization: `Bearer ${processEnv.NEXT_PUBLIC_API_AUTH_TOKEN as string}`,
	},
})

/*
|--------------------------------------------------------------------------
| Default response interceptor for error
|--------------------------------------------------------------------------
*/
axiosInstance.interceptors.request.use((config) => {
	const controller = new AbortController()

	if (config.url?.includes('/address/placeholder/')) {
		controller.abort()
	}

	if (typeof window !== 'undefined') {
		const spaceSlugFromWhiteLabel = getStorage('starton.white-label.settings')?.slug

		if (spaceSlugFromWhiteLabel && !spaceSlugFromWhiteLabel.includes('starton')) {
			config.headers['x-tenant-name'] = spaceSlugFromWhiteLabel
		} else {
			config.headers['x-tenant-name'] = window.location.hostname.split('.')[0]
		}
	}

	return {
		...config,
		signal: controller.signal,
	}
})
axiosInstance.interceptors.response.use(commonResponseInterceptorSuccess, commonResponseInterceptorError())

/*
|--------------------------------------------------------------------------
| Create custom instance for orval and SWR
|--------------------------------------------------------------------------
*/
export const axiosCustomInstance = <T>(
	config: AxiosRequestConfig,
	options?: AxiosRequestConfig,
	ssrOptions?: SSROptions,
): Promise<T> => {
	// Get Starton Auth session cookie
	// ----------------------------------------------------------------------------
	const authCookie =
		// @ts-ignore
		options?.headers?.get?.('Cookie') ??
		CookiesService.getAuthCookie({
			res: ssrOptions?.res,
			req: ssrOptions?.req,
		})

	// Get project id
	// ----------------------------------------------------------------------------
	const projectIdFromSSR = CookiesService.getSSRProjectId({
		res: ssrOptions?.res,
		req: ssrOptions?.req,
	})
	const projectId = SessionStorageService.getItem('starton.current-project-id') ?? projectIdFromSSR

	if (projectIdFromSSR && !ssrOptions?.req) {
		CookiesService.deleteSSRProjectId({
			res: ssrOptions?.res,
			req: ssrOptions?.req,
		})
	}

	// Add cancel token to axios
	// ----------------------------------------------------------------------------
	// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
	const source = Axios.CancelToken.source()
	const promise = axiosInstance({
		...config,
		...options,
		cancelToken: source.token,
		headers: {
			...(authCookie && {
				Cookie: `${processEnv.NEXT_PUBLIC_AUTH_SESSION_NAME as string}=${authCookie as string}`,
			}),
			...(projectId && {
				'x-project-id': projectId,
			}),
			...options?.headers,
		},
	}).then(({ data }) => data)

	// Add interceptors
	// ----------------------------------------------------------------------------
	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled')
	}

	return promise
}

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>
