/*
| Developed by Starton
| Filename : useWhiteLabel.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { useTheme } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useWhiteLabel = () => {
	const { whiteLabelling } = useTheme()

	return whiteLabelling
}
