/*
| Developed by Starton
| Filename : StartonEmptyPage.tsx
| Author : Calixte De Tourris (calixte@starton.com)
*/

import React from 'react'
import { Box, Typography } from '@mui/material'
import { StartonButton } from '@starton/react-ui'
import Link from 'next/link'
import { MenuBookOutlined } from '@mui/icons-material'
import { HomeVideo } from '../../../pages/dashboard/HomeOverview/HomeVideo'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonEmptyPageProps {
	title: string
	description?: string
	docsButton: {
		title: string
		url: string
	}
	videoUrl: string
	children?: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonEmptyPage: React.FC<StartonEmptyPageProps> = (props) => {
	const { title, description, docsButton, videoUrl, children } = props

	// Render
	//--------------------------------------------------------------------------
	return (
		<Box
			paddingY={6}
			paddingX={{ xs: 3, md: 0 }}
			display="flex"
			flexDirection="column"
			maxWidth={800}
			marginX="auto"
			gap={3}
		>
			<Box display="flex" flexDirection="column" gap={1.5}>
				<Box display="flex" justifyContent="space-between">
					<Typography variant="h1">{title}</Typography>
					<Link href={docsButton.url} target="_blank" data-testid="starton-empty-docs-button">
						<StartonButton
							color="primary"
							variant="outlined"
							size="medium"
							startIcon={<MenuBookOutlined />}
						>
							{docsButton.title}
						</StartonButton>
					</Link>
				</Box>
				{description ? <Typography color="text.secondary">{description}</Typography> : null}
			</Box>
			<HomeVideo url={videoUrl} />
			{children}
		</Box>
	)
}
