/*
| Developed by Starton
| Filename : DetailsPageLayout
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { StartonIconButton, StartonTab, StartonTabProps, StartonTabs } from '@starton/react-ui'
import { Box, BoxProps, styled } from '@mui/material'
import { useRouter } from 'next/router'
import { ArrowBackOutlined, Stars } from '@mui/icons-material'
import Link from 'next/link'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/

interface ChildrenProps<T extends string> {
	activeTab: T
	setActiveTab: React.Dispatch<React.SetStateAction<T>>
}

export interface DetailsPageLayoutProps<T extends string> {
	children?: React.FC<ChildrenProps<T>>
	defaultTab?: T
	tabs?: Array<StartonTabProps & { isPremium?: boolean }>
	navigation?: React.ReactNode
	backRoute?: string
	containerProps?: BoxProps
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ActionBarStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spacing(2),
	backgroundColor: theme.palette.background.paper2,
	// padding: theme.spacing(0, 2),
	padding: theme.spacing(0, 2, 0, 0),
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: theme.palette.divider,
}))

const BackButtonStyled = styled(Box)(({ theme }) => ({
	borderRightWidth: 1,
	borderRightStyle: 'solid',
	borderRightColor: theme.palette.divider,
	padding: theme.spacing(1),
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DetailsPageLayout = <T extends string>(props: DetailsPageLayoutProps<T>) => {
	const { children, defaultTab, tabs, navigation, backRoute } = props
	const router = useRouter()
	const { projectId, activeTab: defaultActiveTab } = router.query
	const [activeTab, setActiveTab] = React.useState<T>((defaultActiveTab as T) ?? defaultTab)

	// Methods
	//--------------------------------------------------------------------------
	const handleTabChange = async (event: React.SyntheticEvent, newTab: T) => {
		setActiveTab(newTab)
		await router.replace({ query: { ...router.query, activeTab: newTab } }, undefined, { shallow: true })
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<React.Fragment>
			<ActionBarStyled>
				{backRoute ? (
					<BackButtonStyled>
						<Link href={`/projects/${String(projectId)}/${backRoute}`} data-testid="back-button">
							<StartonIconButton size="large">
								<ArrowBackOutlined />
							</StartonIconButton>
						</Link>
					</BackButtonStyled>
				) : null}
				{tabs?.length && tabs?.length > 0 ? (
					<StartonTabs
						value={activeTab}
						onChange={handleTabChange}
						sx={{ paddingTop: 1 }}
						data-testid="details-page-tabs"
					>
						{tabs.map(({ isPremium, ...tab }, index) => (
							<StartonTab
								key={index}
								{...tab}
								icon={isPremium ? <Stars color="warning" /> : undefined}
								iconPosition="end"
								sx={{
									position: 'relative',
									height: 48,
									minHeight: 48,
									'& .MuiSvgIcon-root': {
										width: 16,
										height: 16,
										position: 'absolute',
										top: 4,
										right: 4,
									},
								}}
								data-testid={`details-page-tab-${tab.value}`}
							/>
						))}
					</StartonTabs>
				) : null}
				{navigation}
			</ActionBarStyled>
			<Box maxHeight="calc(100vh - 113px)" overflow="auto" {...props.containerProps}>
				{children?.({ activeTab, setActiveTab })}
			</Box>
		</React.Fragment>
	)
}
