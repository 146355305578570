/*
| Developed by Starton
| Filename : api.interceptors.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { AxiosError, AxiosResponse } from 'axios'
import { NextRouter } from 'next/router'
import { CookiesService } from '../cookies/cookies.service'

/*
|--------------------------------------------------------------------------
| Response interceptor
|--------------------------------------------------------------------------
*/
export const commonResponseInterceptorSuccess = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
	return response
}
export const commonResponseInterceptorError =
	(router?: NextRouter) =>
	(error: AxiosError): any => {
		if (error?.response) {
			// If we catch error 401 then we logout user and redirect to login (without Sentry error)
			if (error.response.status === 401) {
				console.error(error)
				// authLogout()
				// 	.then(() => {})
				// 	.catch(() => {})
				// 	.finally(() => {
				// 		if (router) {
				// 			void router.push(UrlsConfig.auth.login)
				// 		} else if (typeof window !== 'undefined' && window?.location?.href) {
				// 			window.location.href = UrlsConfig.auth.login
				// 		} else {
				// 			console.log('[Starton Auth] Session expired.')
				// 		}
				// 	})
				//
				// // TODO check error.config.url is equal to logout endpoint url ?
				// if (router) {
				// 	void router.push(UrlsConfig.auth.login)
				// 	return
				// } else if (typeof window !== 'undefined' && window?.location?.href) {
				// 	window.location.href = UrlsConfig.auth.login
				// 	return
				// } else {
				// 	console.log('[Starton Auth] Session expired.')
				// }
			}

			// If we catch error 403 then we redirect user to /projects
			else if (error.response.status === 403) {
				CookiesService.deleteSSRProjectId()

				if (router) {
					void router.push('/projects')
				} else {
					console.log('[Starton Auth] Session expired.')
				}
			}

			// If we catch error 404 then we redirect to 404 page
			else if (error?.response?.status === 404 || error?.code === 'ERR_NETWORK') {
				// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION, {
				// 	extra: {
				// 		data: error.response.data,
				// 	},
				// })
				// if (router) {
				// 	void router.push('/404')
				// }
			}

			// If we catch error 500 then we redirect to 500 page
			else if (error?.response?.status === 500) {
				// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION, {
				// 	extra: {
				// 		data: error.response.data,
				// 	},
				// })
				// if (router) {
				// 	void router.push('/500')
				// }
			}

			// If we catch error 522 then we redirect to maintenance page
			else if (error?.response?.status === 522) {
				// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION, {
				// 	extra: {
				// 		data: error.response.data,
				// 	},
				// })
				if (router) {
					void router.push('/maintenance')
				}
			}

			// Otherwise we reject error
			else {
				// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION, {
				// 	extra: {
				// 		data: error.response.data,
				// 	},
				// })
				return Promise.reject(error)
			}
		} else if (error?.request) {
			console.error('[Starton API] Request problem.', error.request)
		} else {
			// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION)
		}

		return Promise.reject(error)
	}
