/*
| Developed by Starton
| Filename: StartonNavBarLinks.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Box } from '@mui/material'
import { StartonButton, StartonIconButton } from '@starton/react-ui'
import { ContactSupportOutlined, ExitToApp, MenuBookOutlined, PlayLesson, YouTube } from '@mui/icons-material'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { SocialDiscord } from '@starton/react-ui-iconography'
import { usePostHog } from 'posthog-js/react'
import { useAppDispatch } from '../../../stores/hooks'
import { setOpenSupportDialog } from '../../../stores/common/commonSlice'
import { GtagService } from '@/services/gtag/gtag.service'
import { useWhoami } from '@/services/auth-api/endpoints/users'
import { UrlsConfig } from '@/config/urls/urls.config'
import { projectUrl } from '@/config/urls/project-builder'
import { useWhiteLabel } from '@/components/white-labelling/context/whitelabel.context'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonNavBarLinksProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonNavBarLinks: React.FC<StartonNavBarLinksProps> = () => {
	const { t } = useTranslation()
	const router = useRouter()
	const isPlayground = router.pathname.includes('playground')
	const dispatch = useAppDispatch()
	const { projectId } = router.query
	const { data: user } = useWhoami()
	const posthog = usePostHog()
	const { featuredFlags, isStarton } = useWhiteLabel()

	// Methods
	//--------------------------------------------------------------------------
	const handleOpenSupportDialog = () => {
		dispatch(setOpenSupportDialog(true))
	}

	// Memoization
	//--------------------------------------------------------------------------
	const { isExercise, exerciseId } = React.useMemo(() => {
		return {
			isExercise: router.pathname.includes('exercises'),
			exerciseId: router.pathname.split('/exercises/')?.[1],
		}
	}, [router])

	const handleStopPlaygroundEvent = () => {
		if (isExercise) {
			const payload = {
				...(user?.id && {
					userId: user.id,
				}),
				...(projectId && {
					projectId: String(projectId),
				}),
				metadata: {
					exerciseId,
				},
			}
			GtagService.createUserEvent(
				{
					name: 'playground-stop',
					...payload,
				},
				posthog,
			)
		}
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<Box
			display="flex"
			gap={{
				xs: 1,
				md: 2,
			}}
			paddingX={2}
			paddingY={1.5}
		>
			<StartonIconButton variant="outlined" onClick={handleOpenSupportDialog} data-testid="support-button">
				<ContactSupportOutlined />
			</StartonIconButton>
			{isStarton ? (
				<React.Fragment>
					<Link href={UrlsConfig.social.discord} target="_blank" data-testid="discord-button">
						<StartonIconButton variant="outlined">
							<SocialDiscord fill="primary" />
						</StartonIconButton>
					</Link>
					<Link href={UrlsConfig.social.youtube} target="_blank" data-testid="youtube-button">
						<StartonIconButton variant="outlined">
							<YouTube />
						</StartonIconButton>
					</Link>
				</React.Fragment>
			) : null}
			<Link href={UrlsConfig.doc.home} target="_blank" data-testid="doc-button">
				<StartonIconButton variant="outlined">
					<MenuBookOutlined />
				</StartonIconButton>
			</Link>
			{featuredFlags?.enablePlayground ? (
				!isPlayground ? (
					<Link
						href={projectUrl.create((urls) => urls.project.playground.home)}
						data-testid="playground-button"
					>
						<StartonButton variant="outlined" sx={{ display: { xs: 'none', md: 'block' } }}>
							{t('common:common.openPlayground')}
						</StartonButton>
						<StartonIconButton variant="outlined" sx={{ display: { xs: 'block', md: 'none' } }}>
							<PlayLesson />
						</StartonIconButton>
					</Link>
				) : (
					<Link
						href={projectUrl.create((urls) => urls.project.dashboard)}
						onClick={handleStopPlaygroundEvent}
						data-testid="leave-playground-button"
					>
						<StartonButton variant="outlined" sx={{ display: { xs: 'none', md: 'block' } }}>
							{t('playground:navbar.leavePlayground')}
						</StartonButton>
						<StartonIconButton variant="outlined" sx={{ display: { xs: 'block', md: 'none' } }}>
							<ExitToApp />
						</StartonIconButton>
					</Link>
				)
			) : null}
		</Box>
	)
}
