/*
| Developed by Starton
| Filename : DefaultTemplate.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, styled, Theme } from '@mui/material'
import { useRouter } from 'next/router'
import useMediaQuery from '@mui/material/useMediaQuery'
import { StartonLoadingScreen } from '../../../../../common'
import { DashboardAppBar } from './DashboardAppBar'
import { projectUrl } from '@/config/urls/project-builder'
import { useNetworksLoad } from '@/hooks/networks/networksHooks'
import { useTipsLoad } from '@/hooks/tips/tips'
import { useWhiteLabel } from '@/hooks/white-labelling/useWhiteLabel'
import { LocalStorageService } from '@/services/localStorage/localStorage.service'
import { NavDefaultConfig } from '@/config/common/nav.config'
import { SupportDialog } from '@/components/dialogs/SupportDialog'
import { DashboardDrawer } from '@/components/layouts/pages/DashboardLayout/templates/DefaultTemplate/DashboardDrawer'
import { LayoutTemplateProps } from '@/components/layouts/pages/DashboardLayout/templates/layout-template.contract'
import { WhiteLabelConfigDrawer } from '@/components/white-labelling'
import { PremiumDialog } from '@/components/common/dialogs/PremiumDialog'

/*
|--------------------------------------------------------------------------
| Styled
|--------------------------------------------------------------------------
*/
const MainContainer = styled(Box)(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	flexGrow: 1,
	height: `calc(100vh - ${NavDefaultConfig.sizes.appbar.height}px)`,
	overflow: 'auto',
	marginTop: `${NavDefaultConfig.sizes.appbar.height}px`,
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DefaultTemplate: React.FC<LayoutTemplateProps> = (props) => {
	const router = useRouter()
	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), { noSsr: true })
	const [open, setOpen] = React.useState(
		!(LocalStorageService.getItem('starton.nav-drawer.state') === 'true' ?? isTablet),
	)
	const [whiteLabelOpen, setWhiteLabelOpen] = React.useState(false)
	const [isSpace, setIsSpace] = React.useState(false)
	const { featuredFlags } = useWhiteLabel()

	// Keyboard shortcuts for white label configuration panel
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.metaKey && event.altKey && event.shiftKey && event.key === 'ArrowLeft') {
				setWhiteLabelOpen(true)
			} else if (event.metaKey && event.altKey && event.shiftKey && event.key === 'ArrowRight') {
				setWhiteLabelOpen(false)
			}
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	// Memoization
	//--------------------------------------------------------------------------
	const projectId = React.useMemo(() => {
		const _projectId = router.query?.projectId ?? undefined

		// Initialize Project Url builder
		projectUrl.setProjectId(String(_projectId))
		return _projectId
	}, [router.query?.projectId])

	// Loading data to be cached
	//--------------------------------------------------------------------------
	useTipsLoad()
	const { isLoaded } = useNetworksLoad(projectId)

	// Get nav drawer open
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			const state = LocalStorageService.getItem('starton.nav-drawer.state')

			if (state) {
				setOpen(state === 'true')
			} else {
				setOpen(true)
			}
		}
	}, [router])

	// If current project is not Starton, then we need to retrieve the white label configuration
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		// Check if window is defined
		if (typeof window !== 'undefined') {
			// Get base path from the window location
			const spaceTenant = window.location.hostname.split('.')[0]

			// If spaceTenant is contain subdomain on app.starton*.com then we need retrieve the slug from the subdomain
			if (spaceTenant && spaceTenant !== 'app') {
				setWhiteLabelOpen(false)
				setIsSpace(true)
			} else {
				setIsSpace(false)
			}
		}
	}, [])

	// Methods
	// ----------------------------------------------------------------------------
	const toggleDrawer = () => {
		setOpen(!open)
		LocalStorageService.setItem('starton.nav-drawer.state', String(!open))
	}

	// Guardian
	// ----------------------------------------------------------------------------
	if (!router.isReady || !projectId || !isLoaded) {
		return (
			<Box height={'100vh'}>
				<StartonLoadingScreen loadingIcon={'icon'} text={false} />
			</Box>
		)
	}

	// Render
	// ----------------------------------------------------------------------------
	if (!projectId) return null

	return (
		<Box display={'flex'}>
			<SupportDialog />
			<PremiumDialog />
			<DashboardAppBar open={featuredFlags.poweredBy === true && !isTablet ? true : open} />
			<DashboardDrawer
				open={featuredFlags.poweredBy === true && !isTablet ? true : open}
				toggleDrawer={toggleDrawer}
			/>
			<MainContainer component="main">{props.children}</MainContainer>
			{!isTablet || !isSpace ? (
				<WhiteLabelConfigDrawer open={whiteLabelOpen} handleClose={() => setWhiteLabelOpen(false)} />
			) : null}
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
DefaultTemplate.displayName = 'DefaultTemplate'
