import { AuthApi } from '../auth-api-instance'

// POST /password/forgot
//--------------------------------------------------------------------------
export const passwordForgot = (data: { email: string }) => {
	return AuthApi({ url: '/password/forgot', method: 'POST', data })
}

// POST /password/reset
//--------------------------------------------------------------------------
export const passwordReset = (data: { token: string; password: string; password_confirmation: string }) => {
	return AuthApi({ url: '/password/reset', method: 'POST', data })
}

// GET /password/update
//--------------------------------------------------------------------------
export const passwordUpdate = () => {
	return AuthApi({ url: '/password/update', method: 'GET' })
}
