/*
| Developed by Starton
| Filename : NotAuthTemplate.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { Grid } from '@mui/material'
import { NotAuthLeftSide } from './NotAuthLeftSide'
import { NotAuthRightSide } from './NotAuthRightSide'
import { LayoutTemplateProps } from '@/components/layouts/pages/DashboardLayout/templates/layout-template.contract'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface NotAuthTemplateProps extends LayoutTemplateProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const NotAuthTemplate: React.FC<NotAuthTemplateProps> = (props) => {
	return (
		<Grid container direction={'row'} height={'100vh'}>
			<NotAuthLeftSide />
			<NotAuthRightSide>{props.children}</NotAuthRightSide>
		</Grid>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
NotAuthTemplate.displayName = 'NotAuthTemplate'
