/*
| Developed by Starton
| Filename : networksSlice.ts
| Author : Calixte De Tourris (calixte@starton.io)
*/

import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Network } from '@/services/api/starton/model'

/*
|--------------------------------------------------------------------------
| Entity adapter
|--------------------------------------------------------------------------
*/
// @ts-ignore
export const networkAdapter = createEntityAdapter<Network>({
	selectId: (network) => network.name,
	sortComparer: (a, b) => a.name.localeCompare(b.name),
})

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/
export const networkSlice = createSlice({
	name: 'networks',
	initialState: networkAdapter.getInitialState(),
	reducers: {
		networksSetMany: networkAdapter.setMany,
	},
})

/*
|--------------------------------------------------------------------------
| Export selectors
|--------------------------------------------------------------------------
*/
export const networksSelectors = networkAdapter.getSelectors<RootState>((state) => state.networks)
export const networksGetAllCached = (state: RootState) => networksSelectors.selectAll(state)
export const networksGetOneCached = (networkId: string) => (state: RootState) =>
	networksSelectors.selectById(state, networkId)

/*
|--------------------------------------------------------------------------
| Export
|--------------------------------------------------------------------------
*/
export const { networksSetMany } = networkSlice.actions
export default networkSlice.reducer
