/*
| Developed by Starton
| Filename: PGCard.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { StartonButton, StartonIconButton } from '@starton/react-ui'
import { Box, Divider, styled, Typography } from '@mui/material'
import {
	EmojiEventsOutlined,
	KeyboardArrowDownOutlined,
	KeyboardArrowUpOutlined,
	PlayArrowOutlined,
} from '@mui/icons-material'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { usePostHog } from 'posthog-js/react'
import { GtagService } from '@/services/gtag/gtag.service'
import { useWhoami } from '@/services/auth-api/endpoints/users'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PGCardProps {
	title: string
	description: string
	children: React.ReactNode
	variant: 'ready' | 'done' | 'disabled' | 'soon'
	href?: string
	id?: string
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const CardStyled = styled(Box, {
	shouldForwardProp: (propName) => propName !== 'variant',
})<Pick<PGCardProps, 'variant'>>(({ theme, variant }) => ({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(2),
	padding: theme.spacing(5),
	background: theme.palette.gradient?.dark ?? 'linear-gradient(135deg, #1E242A 0%, #161B1F 100%)',
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: theme.palette.divider,
	cursor: !['disabled', 'soon'].includes(variant) ? 'pointer' : 'default',
	...(variant === 'ready' && {
		color: theme.palette.text.secondary,
	}),
	...(variant === 'done' && {
		color: theme.palette.text.primary,
		position: 'relative',
		'&:before': {
			content: '""',
			background: theme.palette.gradient.turblue,
			position: 'absolute',
			top: -2,
			left: -2,
			width: 'calc(100% + 4px)',
			height: 'calc(100% + 4px)',
			zIndex: -1,
		},
	}),
}))

const MotionBox = motion(Box)

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PGCard: React.FC<PGCardProps> = ({ id, title, description, variant, href, children }) => {
	const [open, setOpen] = React.useState(false)
	const { data: user } = useWhoami()
	const router = useRouter()
	const { projectId } = router.query
	const posthog = usePostHog()

	// Methods
	//--------------------------------------------------------------------------
	const handleToggle = () => {
		if (['disabled', 'soon'].includes(variant)) return
		setOpen((state) => !state)
	}

	const handleToggleCollapse = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (['disabled', 'soon'].includes(variant)) return
		event.stopPropagation()
		setOpen((state) => !state)
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<CardStyled variant={variant} onClick={handleToggle}>
			<Box display="flex" gap={1} alignItems="center" width="100%" justifyContent="space-between">
				<Box display="flex" flexDirection="column" gap={1}>
					<Box display="flex" alignItems="center" gap={1}>
						{variant === 'done' ? <EmojiEventsOutlined color="primary" /> : null}
						<Typography variant="h3" color="text.primary">
							{title}
						</Typography>
					</Box>
					<Typography variant="body2" maxWidth="75ch" color="text.secondary">
						{description}
					</Typography>
				</Box>
				<Box display="flex" gap={5}>
					<Link
						href={href as string}
						onClick={(event) => {
							event.stopPropagation()
							if (['disabled', 'soon'].includes(variant)) return
							const payload = {
								...(user?.id && {
									userId: user.id,
								}),
								...(projectId && {
									projectId: String(projectId),
								}),
								metadata: {
									exerciseId: id,
								},
							}
							GtagService.createUserEvent(
								{
									name: 'playground-start',
									...payload,
								},
								posthog,
							)
						}}
					>
						<StartonButton
							size="large"
							variant={variant === 'ready' ? 'contained' : variant === 'done' ? 'outlined' : 'text'}
							startIcon={!['disabled', 'soon'].includes(variant) ? <PlayArrowOutlined /> : undefined}
							disabled={['disabled', 'soon'].includes(variant)}
							color={['disabled', 'soon'].includes(variant) ? 'secondary' : 'primary'}
						>
							{variant === 'done' ? 'Restart' : null}
							{variant === 'ready' ? 'Start' : null}
							{variant === 'soon' ? 'Coming soon' : null}
							{variant === 'disabled' ? 'Not available' : null}
						</StartonButton>
					</Link>
					<StartonIconButton
						onClick={handleToggleCollapse}
						color="secondary"
						size="large"
						disabled={['disabled', 'soon'].includes(variant)}
					>
						{open ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
					</StartonIconButton>
				</Box>
			</Box>
			<MotionBox
				initial={{ height: open ? 'auto' : 0, overflow: 'hidden' }}
				animate={{ height: open ? 'auto' : 0, overflow: 'hidden' }}
				style={{ marginBottom: -16 }}
			>
				<Divider sx={{ marginBottom: 2 }} />
				{children}
			</MotionBox>
		</CardStyled>
	)
}
