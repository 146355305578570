/*
| Developed by Starton
| Filename : UnderConstructionIcon
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { SvgIconProps, useTheme } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UnderConstructionIconProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const UnderConstructionIcon: React.FC<UnderConstructionIconProps> = (props) => {
	const [state, setState] = React.useState(true)
	const theme = useTheme()

	React.useEffect(() => {
		const interval = setInterval(() => {
			setState((state) => !state)
		}, 500)
		return () => clearInterval(interval)
	}, [])

	// Render
	//--------------------------------------------------------------------------
	return (
		<svg
			id="Calque_2_00000040557418850214728380000015643609218631467669_"
			xmlns="http://www.w3.org/2000/svg"
			x={0}
			y={0}
			viewBox="0 0 368.3 469.7"
			xmlSpace="preserve"
			{...props}
		>
			<style>
				{
					'.st0{fill:#fff}.st1{fill:#262c33}.st2{fill:#37474f}.st3{opacity:.15;enable-background:new}.st4{fill:#263238}'
				}
			</style>
			<path
				className="st0"
				d="m245.8 274.1-13.1 7.6c-1.5.8-3.3.8-4.8 0l-4.1-2.4c-1.3-.9-2-2.5-1.8-4.1l43.4-205.6c.4-1.7 1.5-3.1 2.9-4.1l13.1-7.6c1.5-.8 3.3-.8 4.8 0l4.1 2.4c1.4.9 2.1 2.5 1.8 4.1l-43.4 205.7c-.5 1.7-1.5 3.1-2.9 4z"
			/>
			<path
				className="st1"
				d="m245.8 274.1-13.1 7.6c-1.3.8-2.1 0-1.8-1.3l43.4-205.6c.4-1.7 1.5-3.1 3-4.1l13-7.6c1.3-.8 2.2 0 1.8 1.3L248.7 270c-.5 1.7-1.5 3.2-2.9 4.1z"
			/>
			<path
				className="st0"
				d="M231.7 282.5c-.4 0-.7-.1-.8-.3-.5-.4-.7-1.1-.5-2l43.3-205.6c.5-1.8 1.6-3.4 3.2-4.4l13.1-7.6c.8-.5 1.6-.5 2.1-.1s.7 1.1.5 2l-43.4 205.6c-.5 1.8-1.5 3.3-3.1 4.4l-13.1 7.6c-.6.3-1 .4-1.3.4zm59.5-219.2c-.2 0-.4.1-.7.2l-13 7.6c-1.3.9-2.3 2.2-2.7 3.8l-43.3 205.6c-.1.4 0 .8.1.9.2.1.5.1 1-.2l13.1-7.5c1.3-.9 2.2-2.2 2.6-3.8l43.3-205.6c.1-.4.1-.8-.1-.9-.1-.1-.2-.1-.3-.1z"
			/>
			<path className="st1" d="m238.9 260.7 3.9-18.4 8.9-5.1 82.5 47.7 3.9 22.8-8.9 5.1z" />
			<path
				className="st0"
				d="m329.2 313.4-.2-.1-90.6-52.3 4-18.9 9.3-5.4 83 47.9 4 23.4-9.5 5.4zm-89.8-52.9 89.8 51.8 8.3-4.8-3.8-22.3-82-47.4-8.5 4.9-3.8 17.8z"
			/>
			<path className="st0" d="m329.2 312.8-90.3-52.1 3.9-18.4 82.5 47.7z" />
			<path
				className="st2"
				d="m343.9 330.8-13.1 7.5c-1.5.8-3.3.8-4.8 0l-4.1-2.4c-1.4-.9-2.4-2.4-2.8-4.1l-43.7-257c-.2-1.6.5-3.2 2-4.1l13-7.6c1.5-.8 3.3-.8 4.8 0l4.1 2.4c1.4.9 2.5 2.4 2.8 4.1l43.6 257c.3 1.7-.5 3.3-1.8 4.2z"
			/>
			<path
				className="st0"
				d="m275.4 74.8 1.7 9.7L319 331.8c.4 1.7 1.4 3.1 2.8 4.1l4.1 2.4c1.5.7 3.3.7 4.8 0l13-7.6c1.4-.9 2.1-2.4 2-4.1l-43.5-256v-.9c-.4-1.7-1.4-3.1-2.8-4.1l-4.1-2.4c-1.5-.7-3.3-.7-4.8 0l-13.1 7.6c-1.4 1-2.1 2.4-2 4z"
			/>
			<path
				className="st1"
				d="m302.2 69.6 43.6 257c.2 1.6-.6 3.2-1.9 4.1l-13.1 7.5c-.8.6-1.9.4-2.5-.4-.2-.3-.3-.6-.3-.9l-43.7-257c-.2-1.6.6-3.2 1.9-4.1l13.1-7.6c.8-.6 1.9-.4 2.5.4.2.3.3.7.4 1z"
			/>
			<path
				className="st0"
				d="M329.7 339.1h-.3c-.6-.1-1.1-.4-1.5-.9-.3-.3-.4-.8-.4-1.2L283.9 80c-.2-1.8.6-3.6 2.1-4.6l13.1-7.6c.4-.3 1-.5 1.7-.4.6.1 1.1.4 1.5.9.3.4.4.8.4 1.2l43.6 257c.2 1.8-.6 3.6-2.1 4.6l-13.1 7.5c-.4.3-.9.5-1.4.5zM300.4 68.4c-.3 0-.5.1-.8.2l-13.1 7.6c-1.2.8-1.8 2.1-1.7 3.6l43.6 257.1c0 .3.1.5.3.7.2.3.5.5.8.5.3.1.7 0 1-.2l13.1-7.5c1.2-.8 1.8-2.2 1.7-3.6L301.7 69.7c0-.3-.1-.5-.2-.7-.2-.3-.5-.5-.8-.5-.2-.1-.3-.1-.3-.1z"
			/>
			<path
				className="st3"
				d="m263.4 79.6 6.8 4.1c2.1 1.1 4.5 1.4 6.9.9.9-.2 1.8-.5 2.6-.9l22.6-13v-.9c-.4-1.7-1.4-3.1-2.8-4.1l-4.1-2.4c-1.5-.7-3.3-.7-4.8 0 .3-1.5 1.3-2 0-2.7l-4.1-2.4a5.1 5.1 0 0 0-4.8 0l-13.4 7.4c-1.5.9-2.5 2.4-2.9 4.1l-2 9.9z"
			/>
			<path
				className="st1"
				d="M255.4 63.6c.1-1.2.8-2.3 2-2.8l26.2-15.1c3-1.5 6.5-1.5 9.5 0l12.8 7.4c1.1.5 1.8 1.5 2 2.7v6c-.1 1.2-.9 2.2-2 2.7l-26.2 15.1c-3 1.5-6.5 1.5-9.5 0l-12.8-7.4c-1.1-.5-1.9-1.5-2-2.7v-5.9z"
			/>
			<path
				className="st0"
				d="M274.9 81.2c-1.7 0-3.4-.4-5-1.2l-12.8-7.4c-1.2-.5-2.1-1.7-2.2-3.1v-6c.1-1.4.9-2.7 2.2-3.2l26.1-15c3.1-1.6 6.9-1.6 10 0l12.8 7.4c1.2.5 2.1 1.7 2.2 3.1v6c-.1 1.4-1 2.6-2.2 3.2l-26.1 15.1c-1.5.7-3.3 1.1-5 1.1zm-19-17.6V69.5c.1 1 .7 1.9 1.6 2.3l12.9 7.4c2.8 1.4 6.2 1.4 9 0l26.1-15.1c1-.4 1.6-1.3 1.7-2.3v-5.9c-.1-1-.7-1.8-1.6-2.2l-12.9-7.4c-2.8-1.5-6.2-1.5-9 0l-26.1 15.1c-1 .3-1.6 1.2-1.7 2.2z"
			/>
			<path
				className="st0"
				d="M255.4 63.6c.1-1.2.8-2.3 2-2.8L275 50.7v30.1c-1.7 0-3.3-.4-4.8-1.1l-12.8-7.4c-1.1-.5-1.9-1.5-2-2.7v-6z"
			/>
			<path
				className="st1"
				d="m279.7 73.7 26.2-15.1c1.5-.5 2.3-2.1 1.8-3.6-.3-.9-1-1.6-1.8-1.8l-12.8-7.4c-3-1.5-6.5-1.5-9.5 0l-26.2 15.1c-1.5.5-2.3 2.1-1.8 3.6.3.9 1 1.6 1.8 1.8l12.8 7.4c2.9 1.5 6.5 1.5 9.5 0z"
			/>
			<path
				className="st0"
				d="M274.9 75.3c-1.7 0-3.4-.4-5-1.2l-12.7-7.4c-1-.3-1.8-1.1-2.1-2.2-.3-.9-.2-1.8.2-2.6s1.1-1.4 1.9-1.7l26.1-15c3.1-1.6 6.9-1.6 10 0l12.8 7.4c1 .3 1.8 1.1 2.1 2.2.6 1.8-.4 3.7-2.1 4.3L280 74.2c-1.7.7-3.4 1.1-5.1 1.1zm13.4-30.2c-1.6 0-3.1.4-4.5 1.1l-26.3 15.1c-.6.2-1.1.6-1.4 1.2-.3.6-.3 1.2-.1 1.8.2.7.8 1.3 1.5 1.5h.1l12.8 7.4c2.8 1.4 6.2 1.4 9.1 0l26.2-15.1c1.2-.4 1.9-1.8 1.5-3-.2-.7-.8-1.3-1.5-1.5h-.1l-12.8-7.4c-1.4-.8-2.9-1.1-4.5-1.1z"
			/>
			<path
				className="st1"
				d="m296.1 31.8-8.8-5.2c-3.2-1.3-6.9-.7-9.5 1.4-7.8 5.7-12.5 14.7-12.5 24.4-.2 3.5 1.5 6.8 4.4 8.6l8.9 5.2c3.2 1.3 6.9.7 9.6-1.4 7.8-5.7 12.5-14.7 12.5-24.4.1-3.5-1.7-6.8-4.6-8.6z"
			/>
			<path
				className="st0"
				d="M282.1 67.4c-1.3 0-2.5-.2-3.7-.7l-8.9-5.2c-3.1-2-4.9-5.4-4.7-9.1 0-9.7 4.8-19 12.7-24.8 2.8-2.2 6.7-2.8 10-1.5l8.9 5.2c3.1 1.9 4.9 5.4 4.7 9.1 0 9.8-4.8 19-12.7 24.8-1.8 1.4-4.1 2.2-6.3 2.2zm1.7-41c-2 0-4.1.7-5.7 2-7.7 5.6-12.3 14.5-12.3 24-.2 3.3 1.4 6.4 4.2 8.2l8.8 5.2c3 1.2 6.5.7 9-1.4 7.7-5.6 12.3-14.5 12.3-24 .2-3.3-1.4-6.4-4.2-8.2l-8.8-5.2a9.6 9.6 0 0 0-3.3-.6z"
			/>
			<path
				className="st0"
				d="m278.3 66-8.7-5.1c-2.9-1.8-4.6-5.1-4.4-8.6 0-4.2.9-8.4 2.6-12.2l8.8 5.1c-1.8 3.8-2.7 8-2.6 12.2-.2 3.4 1.4 6.7 4.3 8.6z"
			/>
			<path
				className="st1"
				d="M288 64.7c7.8-5.7 12.5-14.7 12.5-24.4-.4-8.7-6.7-11.9-14-7.2-7.8 5.7-12.5 14.7-12.5 24.4.4 8.7 6.7 12.1 14 7.2z"
			/>
			<path
				className="st0"
				d="M281.6 67.5c-1.2 0-2.3-.3-3.3-.8-2.8-1.4-4.6-4.8-4.8-9.2 0-9.8 4.8-19.1 12.7-24.8 3.7-2.4 7.3-2.9 10.1-1.5 2.8 1.5 4.5 4.7 4.7 9.1 0 9.8-4.8 19.1-12.7 24.8-2.3 1.6-4.6 2.4-6.7 2.4zm11.3-36.1c-1.9 0-4 .7-6.1 2.1-7.6 5.5-12.2 14.5-12.2 24 .2 4 1.7 7.1 4.2 8.3 2.5 1.2 5.7.7 9-1.5 7.7-5.5 12.2-14.5 12.3-24-.2-4-1.7-6.9-4.2-8.2-1-.5-2-.7-3-.7z"
			/>
			<path
				className="st4"
				d="M281.9 64.1c-4.3.2-5-4.7-5.1-6.7.1-8.6 4.3-16.7 11.2-21.8 1.4-1 3-1.6 4.6-1.7 4.3-.2 5 4.7 5.1 6.7-.1 8.6-4.3 16.7-11.2 21.8-1.4.9-3 1.5-4.6 1.7z"
			/>
			<path
				d="M288,35.5c1.2-0.9,2.6-1.4,4.1-1.7c0.7,1.4,1.2,3,1.2,4.6c-0.1,8.6-4.3,16.7-11.2,21.8c-1.2,0.8-2.6,1.4-4.1,1.7c-0.7-1.4-1.2-3-1.2-4.6C276.9,48.7,281,40.6,288,35.5z"
				fill={state ? theme.palette.warning.main : 'black'}
				style={{ transition: 'all 0.4s' }}
			/>
			<path
				className="st0"
				d="M40.8,392.5l-13.1,7.6c-1.5,0.7-3.3,0.7-4.8,0l-4.1-2.4c-1.4-0.9-2.1-2.5-1.8-4.1L60.4,188c0.5-1.7,1.5-3.1,3-4.1l13.1-7.5c1.5-0.8,3.3-0.8,4.8,0l4.1,2.4c1.3,0.9,2,2.5,1.8,4.1L43.7,388.5C43.3,390.1,42.2,391.6,40.8,392.5z"
			/>
			<path
				className="st1"
				d="m40.8 392.5-13.1 7.6c-1.3.7-2.1 0-1.8-1.3l43.4-205.6c.4-1.7 1.5-3.1 2.9-4.1l13.1-7.6c1.3-.7 2.1 0 1.8 1.3L43.7 388.5c-.4 1.6-1.5 3.1-2.9 4z"
			/>
			<path
				className="st0"
				d="M26.7 400.9c-.3 0-.6-.1-.8-.3-.5-.4-.7-1.1-.5-2L68.8 193c.5-1.8 1.6-3.4 3.1-4.4L85 181c.8-.4 1.5-.5 2-.1s.7 1.1.5 2L44.2 388.6c-.5 1.8-1.6 3.4-3.2 4.4l-13.1 7.6c-.4.2-.8.3-1.2.3zm59.6-219.2c-.2 0-.4.1-.7.2l-13.1 7.6c-1.3.9-2.3 2.2-2.7 3.8L26.4 398.8c-.1.5-.1.8.1 1 .2.1.5.1.9-.2l13.1-7.5c1.3-.9 2.3-2.2 2.7-3.8l43.4-205.6c.1-.5.1-.8-.1-1 0 .1-.1 0-.2 0z"
			/>
			<path className="st1" d="m33.6 379.4 3.8-18.3 9-5.2 82.5 47.7 3.9 22.8-8.9 5.1z" />
			<path
				className="st0"
				d="m123.9 432.1-.2-.1-90.6-52.3 4-18.9 9.4-5.4 83 47.9 4 23.4-9.6 5.4zm-89.8-53 89.7 51.8 8.4-4.8-3.8-22.3-82.1-47.4-8.5 4.9-3.7 17.8z"
			/>
			<path className="st0" d="m123.9 431.5-90.3-52.1 3.8-18.3 82.6 47.6z" />
			<path
				className="st2"
				d="m138.8 449.1-13.1 7.6c-1.5.8-3.3.8-4.8 0l-4.1-2.4c-1.4-.9-2.4-2.4-2.8-4.1L70.3 193.1c-.2-1.6.6-3.2 1.9-4.1l13.1-7.6c1.5-.7 3.3-.7 4.8 0l4.1 2.4c1.4.9 2.5 2.4 2.8 4.1l43.6 257c.3 1.7-.4 3.3-1.8 4.2z"
			/>
			<path
				className="st0"
				d="m70.3 193.1 1.6 9.7 42 247.3c.4 1.7 1.4 3.1 2.8 4.1l4.1 2.4c1.5.8 3.3.8 4.8 0l13-7.6c1.4-.8 2.1-2.4 1.9-4.1L97 188.9v-.9c-.4-1.7-1.4-3.1-2.8-4.1l-4.1-2.4c-1.5-.7-3.3-.7-4.8 0l-12.8 7.8c-1.4.7-2.3 2.2-2.2 3.8z"
			/>
			<path
				className="st1"
				d="m97.1 188 43.6 257c.2 1.6-.5 3.2-1.9 4.1l-13.1 7.6c-.8.6-1.9.4-2.4-.3-.2-.3-.3-.6-.4-1L79.2 198.3c-.2-1.6.5-3.2 2-4.1l13-7.6c.8-.6 1.9-.5 2.5.2.2.4.4.8.4 1.2z"
			/>
			<path
				className="st0"
				d="M124.7 457.5c-.7 0-1.4-.3-1.8-.9-.3-.4-.4-.8-.5-1.3L78.7 198.4c-.3-1.8.6-3.6 2.2-4.6l13-7.6c.9-.8 2.4-.6 3.2.4.3.4.5.9.5 1.4l43.6 257c.3 1.8-.6 3.6-2.1 4.6l-13.1 7.6c-.4.2-.9.3-1.3.3zM95.3 186.8c-.3 0-.6.1-.8.3l-13.1 7.6c-1.2.7-1.9 2.1-1.7 3.6l43.8 257c0 .3.1.6.3.8.4.5 1.2.7 1.7.2l13.1-7.6c1.2-.7 1.9-2.1 1.7-3.6l-43.7-257c0-.3-.1-.6-.3-.8-.3-.4-.6-.5-1-.5z"
			/>
			<path
				className="st3"
				d="m58.3 198 6.9 4.1c2.1 1.1 4.5 1.4 6.8.9.9-.1 1.8-.4 2.7-.9L97.3 189v-.9c-.4-1.7-1.4-3.1-2.8-4.1l-4.1-2.4c-1.5-.7-3.3-.7-4.8 0 .3-1.5 1.3-2 0-2.7l-4.1-2.4a5.1 5.1 0 0 0-4.8 0l-13 7.6c-1.5.9-2.5 2.4-3 4.1l-2.4 9.8z"
			/>
			<path
				className="st1"
				d="M50.4 182c.1-1.2.9-2.3 2-2.8l26.1-15.1c3-1.5 6.5-1.5 9.5 0l12.8 7.4c1.1.5 1.8 1.5 2 2.7v5.9c-.1 1.2-.9 2.3-2 2.8L74.7 198c-3 1.5-6.5 1.5-9.5 0l-12.8-7.4c-1.1-.5-1.8-1.5-2-2.7V182z"
			/>
			<path
				className="st0"
				d="M69.9 199.6c-1.7 0-3.4-.4-5-1.2L52.1 191c-1.2-.6-2.1-1.7-2.2-3.1v-6c.1-1.4 1-2.6 2.3-3.2l26.1-15.1c3.1-1.6 6.9-1.6 10 0l12.8 7.4c1.2.6 2.1 1.7 2.2 3.1v6c-.1 1.4-1 2.7-2.3 3.2l-26.1 15.1c-1.6.8-3.3 1.2-5 1.2zm-19-17.6V187.9c.1 1 .8 1.8 1.7 2.3l12.8 7.4c2.8 1.4 6.2 1.4 9 0l26.1-15.1c1-.4 1.7-1.3 1.7-2.4v-5.9c-.1-1-.8-1.8-1.7-2.3l-12.8-7.4c-2.8-1.4-6.2-1.4-9 0l-26.1 15.1c-1 .5-1.6 1.4-1.7 2.4z"
			/>
			<path
				className="st0"
				d="M50.4 182c.1-1.2.9-2.3 2-2.8L70 169v30.1c-1.7 0-3.3-.4-4.8-1.1l-12.8-7.4c-1.1-.5-1.8-1.5-2-2.7V182z"
			/>
			<path
				className="st1"
				d="m74.7 192.1 26.1-15.1c1.5-.5 2.3-2.1 1.8-3.6-.3-.9-1-1.6-1.8-1.8L88 164.2c-3-1.5-6.5-1.5-9.5 0l-26.2 15.1c-2.6 1.5-2.6 4.1 0 5.5l12.8 7.4c3.1 1.5 6.6 1.4 9.6-.1z"
			/>
			<path
				className="st0"
				d="M69.8 193.8c-1.7 0-3.3-.4-4.8-1.1l-12.8-7.4c-1.4-.8-2.2-1.9-2.2-3.1 0-1.2.8-2.4 2.2-3.2l26.1-15.2c3.1-1.6 6.9-1.6 10 0l12.8 7.4c1 .3 1.8 1.1 2.1 2.2.3.9.2 1.8-.2 2.6s-1.1 1.4-1.9 1.7L75 192.8c-1.7.5-3.5 1-5.2 1zm13.4-30.3c-1.6 0-3.1.4-4.5 1.1l-26.1 15.2c-1.1.6-1.7 1.5-1.7 2.4 0 .8.6 1.7 1.7 2.3l12.8 7.4c2.8 1.4 6.2 1.3 9-.1l26.2-15.2c.6-.2 1.1-.6 1.4-1.2.3-.6.3-1.2.1-1.8-.2-.7-.8-1.3-1.5-1.5h-.1l-12.8-7.6c-1.4-.7-2.9-1-4.5-1z"
			/>
			<path
				className="st1"
				d="m91 150.1-8.9-5.2c-3.2-1.3-6.9-.7-9.6 1.5-7.8 5.7-12.5 14.7-12.5 24.4-.2 3.5 1.5 6.7 4.4 8.6l8.8 5.2c3.2 1.3 6.9.8 9.5-1.4 7.8-5.7 12.5-14.7 12.5-24.4.4-3.5-1.3-6.8-4.2-8.7z"
			/>
			<path
				className="st0"
				d="M76.9 185.8c-1.3 0-2.5-.2-3.8-.7l-8.9-5.2c-3.1-2-4.8-5.4-4.6-9.1 0-9.8 4.8-19 12.7-24.8 2.8-2.3 6.7-2.8 10.1-1.5l8.9 5.2c3.1 2 4.8 5.5 4.6 9.1 0 9.8-4.8 19-12.7 24.8-1.8 1.4-4.1 2.2-6.3 2.2zm1.8-41c-2.1 0-4.1.7-5.8 2-7.7 5.6-12.3 14.5-12.3 24-.2 3.3 1.4 6.4 4.2 8.2l8.8 5.2c3 1.2 6.4.7 9-1.4 7.7-5.6 12.3-14.5 12.3-24 .2-3.3-1.4-6.4-4.1-8.2l-8.8-5.2a9.6 9.6 0 0 0-3.3-.6z"
			/>
			<path
				className="st0"
				d="m73.2 184.4-8.7-5.1c-2.9-1.9-4.6-5.2-4.4-8.6-.1-4.2.8-8.4 2.6-12.2l8.8 5.2c-1.8 3.8-2.7 8-2.6 12.2-.1 3.4 1.5 6.6 4.3 8.5z"
			/>
			<path
				className="st1"
				d="M82.9 183.1c7.8-5.7 12.5-14.7 12.5-24.4-.4-8.7-6.7-11.9-14-7.2-7.8 5.7-12.5 14.7-12.5 24.4.5 8.7 6.7 11.9 14 7.2z"
			/>
			<path
				className="st0"
				d="M76.5 185.7c-1.2 0-2.4-.3-3.4-.8-2.8-1.4-4.5-4.7-4.7-9 0-9.8 4.8-19.1 12.7-24.8 3.7-2.4 7.3-2.9 10.1-1.5s4.5 4.7 4.7 9c0 9.8-4.8 19.1-12.7 24.8-2.3 1.6-4.6 2.3-6.7 2.3zm11.3-35.9c-1.9 0-4 .7-6.1 2.1-7.7 5.5-12.3 14.5-12.3 24 .2 4 1.7 6.9 4.2 8.2s5.7.8 9.1-1.4c7.7-5.5 12.3-14.5 12.3-24-.2-4-1.7-6.9-4.2-8.2-.9-.5-1.9-.7-3-.7z"
			/>
			<path
				className="st4"
				d="M76.8 182.5c-4.3.2-5-4.6-5.1-6.7.1-8.6 4.3-16.7 11.3-21.8 1.4-1 3-1.6 4.6-1.7 4.3-.2 5 4.6 5.1 6.7-.1 8.6-4.3 16.7-11.3 21.8-1.3.9-2.9 1.5-4.6 1.7z"
			/>
			<path
				d="M82.9 153.9c1.2-.8 2.6-1.4 4.1-1.7.7 1.4 1.1 3 1.2 4.6-.1 8.6-4.3 16.7-11.2 21.8-1.2.8-2.6 1.4-4.1 1.7-.7-1.4-1.1-3-1.2-4.6.1-8.6 4.3-16.7 11.2-21.8z"
				fill={state ? 'black' : theme.palette.warning.main}
				style={{ transition: 'all 0.4s' }}
			/>
			<path
				style={{
					fill: '#e88b25',
				}}
				d="M61 226.5 337.3 66.9l-4.5-2.6L56.5 223.9z"
			/>
			<path className="st0" d="M60.9 226.5 337.7 66.1l-4.4-2.6L56.4 223.9z" />
			<path className="st1" d="m61 226.5 13.4 79.8 276.3-159.6-13.4-79.8z" />
			<path
				className="st0"
				d="m74 307.1-13.6-80.9.3-.2 277-159.9 13.6 80.9-.3.2L74 307.1zm-12.4-80.4 13.2 78.8 275.4-159L337 67.7l-275.4 159z"
			/>
			<path
				className="st0"
				d="m56.5 223.9 13.1 80.6 4.5 2.6L61 226.5zM86.4 211.8l-12 94.5L61 226.5zM325.4 161.4l11.9-94.5 13.4 79.8zM120.8 279.5l11.9-94.4-27.4 15.8-12 94.4zM167.2 252.7l11.9-94.5-27.4 15.9-12 94.4zM213.6 225.9l11.9-94.4-27.4 15.8-11.9 94.4zM260 199.1l11.9-94.4-27.4 15.8-11.9 94.4zM306.4 172.3l12-94.4-27.5 15.8-11.9 94.4z"
			/>
		</svg>
	)
}
