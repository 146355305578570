/*
| Developed by Starton
| Filename : TezosLogoType.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface TezosLogoTypeProps extends SvgIconProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const TezosLogoType: React.FC<TezosLogoTypeProps> = (props) => {
	// Render
	//--------------------------------------------------------------------------
	return (
		<SvgIcon {...props}>
			<svg viewBox="0 0 75 27" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g clipPath="url(#clip0_22_145)">
					<path
						d="M37.1893 6.37753H32.5567V18.9188H31.4191V6.37753H26.7983V5.41553H37.1893V6.37753Z"
						fill="white"
					/>
					<path
						d="M41.4699 19.1183C39.8128 19.1429 38.2881 18.2126 37.5528 16.7262C37.1611 15.9437 36.9641 15.079 36.9781 14.2039V13.805C36.9641 12.9051 37.1576 12.0147 37.5411 11.2005C37.8824 10.4614 38.423 9.83142 39.1009 9.38209C39.7424 8.95036 40.4977 8.71807 41.2706 8.71338C42.4903 8.71338 43.4519 9.12399 44.1673 9.95695C44.8828 10.7899 45.2346 11.9279 45.2346 13.3709V13.9927H38.0688V14.2039C38.0688 15.3418 38.3972 16.2921 39.0422 17.043C39.6462 17.7785 40.554 18.1985 41.5051 18.1809C42.0469 18.1938 42.5852 18.0812 43.0766 17.8524C43.5692 17.6096 43.9949 17.2506 44.3198 16.8083L45.0118 17.3362C44.2143 18.52 43.0297 19.1183 41.4699 19.1183ZM41.2706 9.65075C40.4684 9.63784 39.699 9.97103 39.1595 10.5658C38.5849 11.1759 38.2447 11.9971 38.1157 13.0295H44.1322V12.9122C44.097 11.9502 43.8272 11.1641 43.3112 10.5541C42.7952 9.94404 42.115 9.65075 41.2706 9.65075Z"
						fill="white"
					/>
					<path
						d="M48.5184 17.9921H55.0391V18.9306H47.1462V18.1094L53.233 9.85019H47.3339V8.88818H54.6286V9.72114L48.5184 17.9921Z"
						fill="white"
					/>
					<path
						d="M56.6341 13.7568C56.6201 12.857 56.8136 11.9666 57.1971 11.1524C57.5442 10.4121 58.0931 9.7868 58.7803 9.34569C59.4758 8.90926 60.2827 8.68519 61.1025 8.70044C62.4395 8.70044 63.5184 9.16971 64.3511 10.1083C65.1838 11.0468 65.5943 12.2904 65.5943 13.839V14.0736C65.6095 14.9769 65.416 15.8721 65.0313 16.6898C64.6877 17.4289 64.1377 18.053 63.4481 18.4859C62.7502 18.913 61.9445 19.1335 61.1259 19.1194C59.8007 19.1194 58.71 18.6502 57.8773 17.7116C57.0446 16.7731 56.6341 15.5295 56.6341 13.9809V13.7568ZM57.7483 14.0619C57.7483 15.2585 58.0649 16.244 58.6748 17.0183C59.2846 17.7926 60.1056 18.168 61.1142 18.168C62.063 18.1903 62.9672 17.7644 63.5536 17.0183C64.1752 16.244 64.4801 15.235 64.4801 13.968V13.7568C64.4871 13.0318 64.3441 12.3115 64.0579 11.6451C63.8069 11.0491 63.3918 10.5365 62.8617 10.1669C62.3433 9.81378 61.7287 9.62959 61.1025 9.63898C60.1549 9.62138 59.2553 10.0519 58.6748 10.8004C58.0532 11.5747 57.7366 12.5954 57.7366 13.8507L57.7483 14.0619Z"
						fill="white"
					/>
					<path
						d="M73.8976 16.3742C73.9116 15.8487 73.6607 15.3501 73.2291 15.0485C72.7834 14.7201 72.1266 14.4502 71.2236 14.2625C70.5094 14.1288 69.8127 13.9129 69.1477 13.6173C68.6552 13.3709 68.2916 13.0776 68.057 12.7257C67.8096 12.3455 67.6829 11.8997 67.6935 11.4469C67.6935 10.6491 68.0219 10.0039 68.6904 9.48768C69.3589 8.97148 70.2033 8.71338 71.2353 8.71338C72.3495 8.71338 73.2525 8.99494 73.9327 9.54634C74.5977 10.0672 74.9765 10.872 74.9531 11.7167H73.8389C73.8436 11.1372 73.568 10.5916 73.1001 10.2502C72.6075 9.86309 71.9859 9.66365 71.2353 9.66365C70.5082 9.66365 69.9218 9.8279 69.4761 10.1447C69.0539 10.4274 68.8029 10.9037 68.8076 11.4117C68.7854 11.8669 68.9929 12.3021 69.3589 12.5731C69.7224 12.843 70.4026 13.1011 71.376 13.3357C72.3495 13.5703 73.0766 13.805 73.5692 14.0631C74.0019 14.2789 74.3737 14.6027 74.6481 15.0016C75.3049 16.1279 75.0176 17.5685 73.9796 18.3569C73.2994 18.8614 72.4081 19.1195 71.3057 19.1195C70.1329 19.1195 69.1829 18.8379 68.4675 18.2748C67.7521 17.7117 67.3768 16.996 67.3768 16.1279H68.491C68.5144 16.7332 68.817 17.2928 69.3119 17.6413C69.8162 18.005 70.4847 18.1809 71.3174 18.1809C72.0915 18.1809 72.713 18.005 73.1939 17.6765C73.663 17.3233 73.8976 16.8893 73.8976 16.3742Z"
						fill="white"
					/>
					<path
						d="M18.6005 19.6815C18.3307 14.9066 12.326 12.7363 11.7514 12.5134C11.7279 12.5016 11.7279 12.4782 11.7396 12.4547L17.932 6.23685V5.56813C17.9285 5.45903 17.8417 5.37221 17.7326 5.36869H6.32135V1.62625V0.5L2.11102 1.37988V2.0134H2.34558C2.34558 2.0134 3.37764 2.0134 3.37764 3.0458V5.35696H0.117279C0.0539484 5.36166 0.00469116 5.41093 0 5.47428V6.94075H3.38937V14.9066C3.38937 17.4055 4.99609 19.1418 7.81079 18.9072C8.39015 18.8497 8.94722 18.6479 9.42924 18.3206C9.64152 18.1845 9.76935 17.951 9.76935 17.6988V16.9128C8.85457 17.5228 8.08053 17.4876 8.08053 17.4876C6.29789 17.4876 6.33307 15.2234 6.33307 15.2234V6.94075H14.5426L8.63174 12.9005C8.62002 13.6865 8.60829 14.2966 8.60829 14.3083C8.60594 14.3259 8.61884 14.3412 8.63643 14.3435C8.63878 14.3435 8.64113 14.3435 8.64347 14.3435C14.0618 15.2586 15.5278 18.7664 15.5278 19.7871C15.5278 19.7871 16.1142 24.7496 11.1532 25.0898C11.1532 25.0898 7.90461 25.2306 7.32995 23.9284C7.30884 23.8862 7.3276 23.8345 7.36982 23.8146C7.37217 23.8134 7.37451 23.8122 7.37686 23.8111C7.92924 23.58 8.28694 23.0379 8.27991 22.4385C8.27991 21.4764 7.69351 20.6904 6.46208 20.6904C5.46521 20.6904 4.64425 21.4764 4.64425 22.4385C4.64425 22.4385 4.17514 26.615 11.1415 26.4977C19.093 26.3569 18.6005 19.6815 18.6005 19.6815Z"
						fill="white"
					/>
				</g>
				<defs>
					<clipPath id="clip0_22_145">
						<rect width="75" height="26" fill="white" transform="translate(0 0.5)" />
					</clipPath>
				</defs>
			</svg>
		</SvgIcon>
	)
}
