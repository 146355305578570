/*
| Developed by Starton
| Filename : SupportDialogBody.tsx
| Author : Calixte De Tourris (calixte@starton.com)
*/

import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { StartonSelect, StartonMenuItem, StartonTextField } from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import { MailOutlined } from '@mui/icons-material'
import * as Yup from 'yup'
import { useRouter } from 'next/router'
import { SupportDialogBodySuccess } from './SupportDialogBodySuccess'
import { StartonUploadFooter } from '@/components/common/StartonUploadFooter'
import { useWhoami } from '@/services/auth-api/endpoints/users'
import { ApiCommonErrorsHandler } from '@/services/api/starton-extended/errors/errors.service'
import { supportTicketCreate } from '@/services/fapi/hooks/useTicket'
import { supportOptionsNames } from '@/config/support/support.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SupportDialogBodyProps {
	handleClose: VoidFunction
}

export interface SupportDialogFormikInitialValues {
	name: string
	email: string
	subject: string
	description: string
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const SupportDialogBodyStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	margin: '0 auto',
	maxWidth: 700,
	paddingTop: theme.spacing(5),
	gap: theme.spacing(5),
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SupportDialogBody: React.FC<SupportDialogBodyProps> = (props) => {
	const { handleClose } = props
	const { t } = useTranslation()
	const [messageSent, setMessageSent] = React.useState(false)
	const { data: user } = useWhoami()
	const router = useRouter()
	const { projectId } = router.query

	// Memoization
	//--------------------------------------------------------------------------
	const { name, email } = React.useMemo(() => {
		return {
			name: user?.name ?? '',
			email: user?.email ?? '',
		}
	}, [user?.email, user?.name])

	// Formik & Yup
	//--------------------------------------------------------------------------
	const initialValues: SupportDialogFormikInitialValues = { name, email, subject: 'placeholder', description: '' }
	const validationSchema = Yup.object().shape({
		name: Yup.string().required(),
		email: Yup.string().email().required(),
		subject: Yup.string().notOneOf(['placeholder']).required(),
		description: Yup.string().min(3).max(2000).required(),
	})

	// Methods
	//--------------------------------------------------------------------------
	const handleSubmit = async (values: SupportDialogFormikInitialValues) => {
		try {
			await supportTicketCreate({
				content: values.description.replace(/"/g, '\\"'),
				email: values.email,
				from: 'app',
				name: values.name,
				projectId: projectId ? String(projectId) : '',
				subject: values.subject,
			})
			setMessageSent(true)
		} catch (error) {
			console.log(error)
			/** Api Common Error handling **/
			ApiCommonErrorsHandler(error, t, t('common:support.error.title'))
		}
	}

	// Render
	//--------------------------------------------------------------------------
	if (messageSent) {
		return <SupportDialogBodySuccess handleClose={handleClose} />
	}
	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
			{({ values }) => (
				<Form>
					<SupportDialogBodyStyled>
						<Box display="flex" flexDirection="column" gap={1}>
							<Typography variant="h1" textTransform="uppercase">
								{t('common:support.title')}
							</Typography>
							<Typography variant="body1" color="text.secondary">
								{t('common:support.description')}
							</Typography>
						</Box>
						<Box display="flex" flexDirection="column" gap={4}>
							<Field
								name="name"
								disabled={true}
								component={StartonTextField}
								label={t('common:common.name')}
								data-testid="name"
							/>
							<Field
								name="email"
								disabled={true}
								component={StartonTextField}
								label={t('common:common.email')}
								data-testid="email"
							/>
							<Field
								name="subject"
								component={StartonSelect}
								label={t('common:support.inputs.subject.label')}
								renderValue={(value: string) =>
									value !== 'placeholder' ? (
										<Typography>{t(`common:support.inputs.subject.options.${value}`)}</Typography>
									) : (
										'-'
									)
								}
								data-testid="subject"
							>
								{supportOptionsNames.map((item, index) => (
									<StartonMenuItem value={item} key={index} data-testid={`subject-option-${item}`}>
										<Typography>{t(`common:support.inputs.subject.options.${item}`)}</Typography>
									</StartonMenuItem>
								))}
							</Field>
							<Field
								name="description"
								multiline
								minRows={5}
								component={StartonTextField}
								label={t('common:common.description')}
								placeholder={t('common:support.inputs.description.placeholder')}
								helperText={t('common:common.maxCharacterWithCurrent', {
									current: values['description']?.length ?? 0,
									max: 2000,
								})}
								FormHelperTextProps={{
									sx: {
										textAlign: 'right',
									},
								}}
								data-testid="description"
							/>
						</Box>
						<StartonUploadFooter
							margin={0}
							submitText={t('common:support.inputs.submit')}
							submitButtonProps={{ startIcon: <MailOutlined /> }}
							previousText={t('common:support.inputs.cancel')}
							onPrevious={handleClose}
						/>
					</SupportDialogBodyStyled>
				</Form>
			)}
		</Formik>
	)
}
