/*
| Developed by Starton
| Filename : StartonColorTextField.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Field } from 'formik'
import { Box, InputAdornment } from '@mui/material'
import { StartonTextField } from '@starton/react-ui'
import StartonUtils from '@/utils/starton.utils'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonColorTextFieldProps {
	name: string
	label: string
	color: string
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonColorTextField: React.FC<StartonColorTextFieldProps> = React.forwardRef(
	({ name, label, color }, ref) => {
		const backgroundColor = React.useMemo(() => {
			return StartonUtils.ifValidHexOrWhite(color)
		}, [color])

		return (
			<Box display={'flex'} flexDirection={'row'} alignItems={'flex-end'}>
				<Field
					name={name}
					label={label}
					component={StartonTextField}
					ref={ref}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Box sx={{ width: 40, height: 40, backgroundColor }} />
							</InputAdornment>
						),
					}}
					sx={{ paddingRight: 0 }}
				/>
			</Box>
		)
	},
)

StartonColorTextField.displayName = 'StartonColorTextField'
