/*
| Developed by Starton
| Filename : DashboardDrawerMenuItem.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { ListItemButton, ListItemButtonProps, ListItemIcon, ListItemText, styled, useTheme } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { StartonTooltip } from '@starton/react-ui'
import { NavDefaultConfig, NavItem } from '@/config/common/nav.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DashboardDrawerMenuItemProps {
	open: boolean
	data: NavItem
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const DashboardDrawerMenuItemStyled = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== 'selected' && prop !== 'component',
})<ListItemButtonProps>(({ theme, selected }) => ({
	borderLeftWidth: 4,
	borderLeftStyle: 'solid',
	borderLeftColor: selected ? theme.palette.primary.main : 'transparent',
	boxSizing: 'border-box',
	paddingLeft: `calc(${theme.spacing(2)} - 4px)`,
	paddingTop: theme.spacing(1.5),
	paddingBottom: theme.spacing(1.5),
	display: 'flex',
	justifyContent: 'flex-start',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	'& .MuiListItemIcon-root': {
		minWidth: `${NavDefaultConfig.sizes.appbar.height - 13}px`,
	},
	'& .MuiTypography-root': {
		fontWeight: 600,
	},
	'&:hover': {
		backgroundColor: theme.palette.background.default,
		'& .MuiListItemButton-root': {
			backgroundColor: theme.palette.background.default,
		},
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardDrawerMenuItem: React.FC<DashboardDrawerMenuItemProps> = ({ data, open }) => {
	const router = useRouter()
	const { projectId } = router.query
	const theme = useTheme()

	const href = React.useMemo(() => {
		return data.href(projectId as string)
	}, [projectId, data])

	const selected = React.useMemo(() => {
		return data.selectedRegex(projectId as string).test(router.asPath)
		// return router.asPath.includes(data.href(projectId as string))
	}, [projectId, data, router.asPath])

	return (
		<Link href={href} data-testid={`dashboard-drawer-menu-item-${data.name.toLowerCase()}`} passHref>
			<StartonTooltip arrow title={!open ? data.name : ''} placement="right" color="default">
				<DashboardDrawerMenuItemStyled selected={selected}>
					<ListItemIcon color="primary" sx={{ color: 'text.primary' }}>
						{data.icon}
					</ListItemIcon>
					<ListItemText
						primary={data.name}
						primaryTypographyProps={{
							fontWeight: theme.typography.nav.body.md.fontWeight,
							fontSize: theme.typography.nav.body.md.fontSize,
							lineHeight: theme.typography.nav.body.md.lineHeight,
						}}
					/>
				</DashboardDrawerMenuItemStyled>
			</StartonTooltip>
		</Link>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
DashboardDrawerMenuItem.displayName = 'DashboardDrawerMenuItem'
