/*
| Developed by Starton
| Filename : StartonListCard
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { Box, BoxProps, styled, Typography, TypographyProps } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonListCardProps extends BoxProps {
	title?: string
	titleProps?: TypographyProps
	children?: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const CardContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: theme.spacing(2),
	padding: theme.spacing(3),
	cursor: 'pointer',
	border: '2px solid transparent',
	outline: `1px solid ${theme.palette.divider}`,
	'&:hover': {
		backgroundColor: theme.palette.background.paper,
		border: `2px solid ${theme.palette.secondary.main}`,
		outline: '1px solid transparent',
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonListCard: React.FC<StartonListCardProps> = (props) => {
	const { title, children, ...boxProps } = props
	return (
		<CardContainer {...boxProps}>
			<Box display={'flex'} flexDirection={'column'} gap={1}>
				{title ? <Typography variant={'h3'}>{title}</Typography> : null}
				{children}
			</Box>
			<ArrowForward color={'secondary'} />
		</CardContainer>
	)
}
