/*
| Developed by Starton
| Filename : SettingsDrawer.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { Drawer, DrawerProps, List, ListItemIcon, ListItemText, styled, Theme, Toolbar } from '@mui/material'
import { StartonIconButton, StartonTooltip } from '@starton/react-ui'
import { Starton, StartonType } from '@starton/react-ui-iconography'
import {
	KeyboardDoubleArrowRightOutlined,
	KeyboardDoubleArrowLeftOutlined,
	LogoutOutlined,
	DashboardOutlined,
} from '@mui/icons-material'
import Link from 'next/link'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'
import { ListItemButtonStyled } from './SettingsDrawerItem'
import { UrlsConfig } from '@/config/urls/urls.config'
import { useWhoami } from '@/services/auth-api/endpoints/users'
import { authLogout } from '@/services/auth-api/endpoints/auth'
import { GtagService } from '@/services/gtag/gtag.service'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SettingsDrawerProps {}

export interface SettingsDrawerNavItem {
	name: string
	href: string
	icon: React.ReactNode
}
interface LogoLinkProps {
	open: boolean
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
export const APP_BAR_WEIGHT = 56
const DRAWER_WIDTH_OPENED = 220
const DRAWER_WIDTH_CLOSED = 60

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DashboardDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })<DrawerProps>(
	({ theme, open }) => ({
		position: 'relative',
		'& .MuiDrawer-paper': {
			position: 'relative',
			whiteSpace: 'nowrap',
			width: `${DRAWER_WIDTH_OPENED}px`,
			backgroundColor: theme.palette.background.paper,
			borderRight: `1px solid ${theme.palette.divider}`,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
			boxSizing: 'border-box',
			overflow: 'initial',
			...(!open && {
				// overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen,
				}),
				width: `${DRAWER_WIDTH_CLOSED}px`,
			}),
		},
	}),
)

const LogoLink = styled('span', { shouldForwardProp: (prop) => prop !== 'open' })<LogoLinkProps>(({ theme, open }) => ({
	width: '100%',
	cursor: 'pointer',
	...(open
		? {
				height: '30px',
				'& svg': {
					height: '30px',
				},
			}
		: {
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				maxHeight: `calc(${APP_BAR_WEIGHT}px - ${theme.spacing(2)})`,
				'& svg': {
					maxHeight: `calc(${APP_BAR_WEIGHT}px - ${theme.spacing(2)})`,
				},
			}),
}))

const CollapseButton = styled(StartonIconButton)(() => ({
	borderRadius: '30px',
	width: '24px',
	height: '24px',
	position: 'absolute',
	right: '-12px',
	top: '64px',
}))

export const DashboardToolbar = styled(Toolbar)(({ theme }) => ({
	minHeight: `${APP_BAR_WEIGHT}px`,
	justifyContent: 'space-between',
	[theme.breakpoints.up('sm')]: {
		minHeight: `${APP_BAR_WEIGHT}px`,
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SettingsDrawer: React.FC<SettingsDrawerProps> = () => {
	const router = useRouter()
	const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
	const [open, setOpen] = React.useState(true)
	const { data: user } = useWhoami()

	// Listen media query for close on mobile
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		setOpen(!isTablet)
	}, [isTablet])

	// Methods
	// ----------------------------------------------------------------------------
	const toggleDrawer = () => {
		setOpen(!open)
	}

	const handleGoToApp = () => {
		void router.push(UrlsConfig.home)
	}

	const handleLogout = async () => {
		try {
			await authLogout()
		} catch {
			// Empty catch
		} finally {
			await router.push(UrlsConfig.auth.login)
			GtagService.createUserEvent({
				name: 'user-logout',
				userId: user?.id,
			})
		}
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<DashboardDrawerStyled variant="permanent" open={open}>
			<CollapseButton variant="contained" color="secondary" onClick={toggleDrawer}>
				{open ? <KeyboardDoubleArrowLeftOutlined /> : <KeyboardDoubleArrowRightOutlined />}
			</CollapseButton>
			<DashboardToolbar
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					px: [1],
					cursor: 'pointer',
					mb: (theme) => theme.spacing(5),
				}}
			>
				<Link href={UrlsConfig.auth.settings}>
					<LogoLink open={open}>
						{open ? <StartonType fill={'#fff'} sx={{ width: '100%' }} /> : <Starton fill={'primary'} />}
					</LogoLink>
				</Link>
			</DashboardToolbar>
			<List component="nav">
				{/*<SettingsDrawerItem key={node} open={open} data={NAV_ITEMS[node as StartonFlowMethods]} />*/}
				<StartonTooltip arrow title={!open ? 'Dashboard' : ''} placement="right" color={'default'}>
					{/* @ts-ignore */}
					<ListItemButtonStyled onClick={handleGoToApp}>
						<ListItemIcon color={'primary'}>
							<DashboardOutlined sx={{ color: 'text.primary' }} />
						</ListItemIcon>
						<ListItemText primary={'Go to dashboard'} />
					</ListItemButtonStyled>
				</StartonTooltip>
				<StartonTooltip arrow title={!open ? 'Logout' : ''} placement="right" color={'default'}>
					{/* @ts-ignore */}
					<ListItemButtonStyled onClick={handleLogout}>
						<ListItemIcon color={'primary'}>
							<LogoutOutlined sx={{ color: 'text.primary' }} />
						</ListItemIcon>
						<ListItemText primary={'Logout'} />
					</ListItemButtonStyled>
				</StartonTooltip>
			</List>
		</DashboardDrawerStyled>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
SettingsDrawer.displayName = 'SettingsDrawer'
