/*
| Developed by Starton
| Filename : StartonLink.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React, { PropsWithChildren } from 'react'
import { Tooltip, Typography } from '@mui/material'
import Link, { LinkProps } from 'next/link'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonLinkProps extends LinkProps, PropsWithChildren {
	aProps?: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
	disabled?: {
		active: boolean
		message?: string
	}
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonLink: React.FC<StartonLinkProps> = (props) => {
	const { children, aProps, disabled, ...linkProps } = props

	// Disabled version
	// ----------------------------------------------------------------------------
	if (disabled?.active) {
		return (
			<Tooltip title={disabled?.message ?? undefined}>
				<Typography color={'text.disabled'} sx={{ cursor: 'not-allowed' }}>
					{children}
				</Typography>
			</Tooltip>
		)
	}

	// Classic version
	// ----------------------------------------------------------------------------
	return (
		<Link {...linkProps} href={linkProps.href}>
			{/* @ts-ignore */}
			<Typography color={'primary.main'} {...aProps} sx={{ textDecoration: 'underline' }}>
				{children}
			</Typography>
		</Link>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
StartonLink.displayName = 'StartonLink'
