/*
| Developed by Starton
| Filename : ImageUploadField.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Accept, DropzoneProps, useDropzone } from 'react-dropzone'
import {
	Box,
	BoxProps,
	Card,
	CardProps,
	Fab,
	FormControl,
	FormControlProps,
	FormHelperText,
	FormLabel,
	styled,
	Typography,
} from '@mui/material'
import { useField } from 'formik'
import _ from 'lodash'
import useTranslation from 'next-translate/useTranslation'
import { Delete } from '@mui/icons-material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface ImageUploadFieldProps {
	name: string
	label?: string
	accept?: Accept
	maxSize?: number
	helperText?: string
	showChildrenWhenEmpty?: boolean
	dropzoneProps?: Omit<DropzoneProps, 'maxFiles' | 'maxSize'>
	formControlProps?: FormControlProps
	imageDropzoneProps?: BoxProps
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ImageDropzoneContainer = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'isDragActive' && prop !== 'isEmpty',
})<BoxProps & { isDragActive?: boolean; isEmpty: boolean }>(({ theme, isDragActive, isEmpty }) => ({
	borderWidth: 2,
	borderStyle: 'dashed',
	borderRadius: 12,
	boxSizing: 'border-box',
	margin: theme.spacing(1, 0, 0),
	width: '100%',

	...(isDragActive
		? {
				borderColor: theme.palette.primary.main,
			}
		: {
				borderColor: isEmpty ? theme.palette.divider : 'transparent',
			}),
}))

const ImageUploadCard = styled(Card, {
	shouldForwardProp: (propName) => propName !== 'avatarUrl',
})<CardProps & { avatarUrl?: string }>(({ theme, avatarUrl }) => ({
	position: 'relative',
	width: '100%',
	height: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	cursor: 'pointer',
	...(avatarUrl
		? {
				backgroundImage: `url(${avatarUrl})`,
				backgroundPosition: 'center center',
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
			}
		: {
				backgroundColor: 'transparent',
			}),
	borderRadius: 13,
	padding: theme.spacing(2),
	paddingBottom: theme.spacing(2),
	minHeight: 96,
	boxSizing: 'border-box',
	// color: theme.palette.mode === 'light' ? theme.palette.secondary.dark : theme.palette.text.primary,
	//
	// ...(!avatarUrl
	// 	? {
	// 			'&::before': {
	// 				content: '""',
	// 				position: 'absolute',
	// 				top: 0,
	// 				left: 0,
	// 				right: 0,
	// 				bottom: 0,
	// 				backgroundColor:
	// 					theme.palette.mode === 'light'
	// 						? alpha(theme.palette.secondary.light, 0.9)
	// 						: alpha(theme.palette.secondary.main, 0.9),
	// 			},
	// 		}
	// 	: null),
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
	name,
	label,
	accept = {
		'image/*': ['.jpg', '.jpeg', '.png', '.svg', '.webp'],
	},
	maxSize = 10 * 1024 * 1024, // 10MB
	helperText,
	showChildrenWhenEmpty = false,
	dropzoneProps,
	formControlProps,
	imageDropzoneProps,
}) => {
	const { t } = useTranslation()
	const [field, meta, helpers] = useField<File>(name)
	const [filePreview, setFilePreview] = React.useState<string>('')

	const { error, showError } = React.useMemo(() => {
		const showError = meta.touched
		const error = typeof meta.error === 'string' ? meta.error : _.get(meta.error, name)
		return { error, showError }
	}, [meta.error, meta.touched, name])
	const isEmpty = React.useMemo(() => {
		if (showChildrenWhenEmpty) return false

		return !(field.value instanceof File)
	}, [field.value, showChildrenWhenEmpty])

	// If the field value is a File, set the file preview
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (field.value instanceof File) {
			setFilePreview(URL.createObjectURL(field.value))
		}
	}, [field.value])

	// Methods
	// ----------------------------------------------------------------------------
	const onDelete = React.useCallback(async () => {
		// Set file preview
		setFilePreview('')

		// Revoke object URL
		URL.revokeObjectURL(filePreview)

		// @ts-ignore
		await helpers.setValue('')
	}, [field.value, filePreview, helpers])

	// Dropzone configuration
	// ----------------------------------------------------------------------------
	const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
		onDrop: async (acceptedFiles: Array<File>) => {
			await helpers.setValue(acceptedFiles[0])
			setFilePreview(URL.createObjectURL(acceptedFiles[0]))
		},
		accept,
		maxFiles: 1,
		multiple: false,
		maxSize,
		...dropzoneProps,
	})

	// Render
	// ----------------------------------------------------------------------------
	return (
		<FormControl {...formControlProps} error={showError || fileRejections.length > 0 ? Boolean(error) : undefined}>
			{label ? (
				<FormLabel htmlFor={name}>
					<Typography>{label}</Typography>
				</FormLabel>
			) : null}

			<ImageDropzoneContainer
				{...getRootProps()}
				isDragActive={isDragActive}
				isEmpty={isEmpty}
				{...imageDropzoneProps}
			>
				<input {...getInputProps()} />
				<ImageUploadCard avatarUrl={filePreview === '' ? undefined : filePreview} elevation={0}>
					{/* Show a message when user is dragging a file */}
					{isEmpty ? (
						isDragActive ? (
							<Typography variant={'button'} textAlign={'center'} zIndex={10}>
								{t('common:form.upload.dragHere.one')}
							</Typography>
						) : (
							<Typography variant={'button'} textAlign={'center'} zIndex={10}>
								{t('common:form.upload.dragAndDrop.one')}
							</Typography>
						)
					) : null}
					{!isEmpty ? (
						<Fab
							sx={{
								position: 'absolute',
								bottom: 8,
								right: 8,
								cursor: 'pointer',
							}}
							onClick={(event) => {
								event.preventDefault()
								event.stopPropagation()

								void onDelete()
							}}
							size={'small'}
						>
							<Delete sx={{ fontSize: 24 }} />
						</Fab>
					) : null}
				</ImageUploadCard>
			</ImageDropzoneContainer>

			{/* Show all file rejections error, group by message and show only message without translation */}
			{fileRejections.length > 0 ? (
				<FormHelperText error sx={{ marginBottom: 1 }}>
					{fileRejections
						.map((fileRejection) =>
							fileRejection.errors.map((error) =>
								t(`common:form.upload.errors.${error.code}`, {
									maxFiles: 1,
									maxSize: maxSize / 1024 / 1024,
								}),
							),
						)
						.flat()
						.filter((value, index, self) => self.indexOf(value) === index)
						.join('\n')}
				</FormHelperText>
			) : null}

			{showError && error ? <FormHelperText>{error}</FormHelperText> : null}
			{helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
		</FormControl>
	)
}
