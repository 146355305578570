/*
| Developed by Starton
| Filename : DashboardBreadcrumbs.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, styled, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import Link from 'next/link'
import { selectAppBarBreadcrumbs } from '@/stores/common/commonSlice'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DashboardBreadcrumbsProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DashboardStartonBreadcrumbs = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spacing(0.5),
	padding: theme.autoSpacing('lg', 'xl'),
}))
const ParentBreadcrumbStyled = styled(Typography)(({ theme }) => ({
	overflow: 'hidden',
	display: 'inline-block',
	verticalAlign: 'middle',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	maxWidth: '200px',
	flexGrow: 1,
	fontWeight: 500,
	color: theme.palette.text.secondary,
	...theme.typography.nav.body.xs,
	textTransform: 'capitalize',
}))

const ChildBreadcrumbStyled = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.primary,
	...theme.typography.nav.title.md,
	fontWeight: 600,
	maxWidth: 300,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardBreadcrumbs: React.FC<DashboardBreadcrumbsProps> = () => {
	const appBarBreadcrumbs = useSelector(selectAppBarBreadcrumbs)

	const { child, parent } = React.useMemo(() => {
		const length = appBarBreadcrumbs?.length

		return {
			parent: length >= 2 ? appBarBreadcrumbs[length - 2] : null,
			child: length >= 1 ? appBarBreadcrumbs[length - 1] : null,
		}
	}, [appBarBreadcrumbs])

	return appBarBreadcrumbs?.length > 0 ? (
		<DashboardStartonBreadcrumbs>
			{parent ? (
				<React.Fragment>
					{parent.href ? (
						<Link
							href={parent.href}
							aria-label={parent.alt ?? 'Go to parent page'}
							style={{ lineHeight: 'initial', fontSize: 12 }}
							data-testid="parent-breadcrumb"
						>
							<ParentBreadcrumbStyled data-dd-privacy={'mask'}>{parent.name}</ParentBreadcrumbStyled>
						</Link>
					) : (
						<ParentBreadcrumbStyled data-dd-privacy={'mask'}>{parent.name}</ParentBreadcrumbStyled>
					)}
				</React.Fragment>
			) : null}
			{child ? <ChildBreadcrumbStyled data-dd-privacy={'mask'}>{child.name}</ChildBreadcrumbStyled> : null}
		</DashboardStartonBreadcrumbs>
	) : null
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
DashboardBreadcrumbs.displayName = 'DashboardBreadcrumbs'
