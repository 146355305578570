/*
| Developed by Starton
| Filename : starton.utils.ts
*/

import { StartonUIUtils } from '@starton/react-ui'
import moment from 'moment'
import getConfig from 'next/config'
import mergeWith from 'lodash/mergeWith'
import isArray from 'lodash/isArray'
import { Dictionary } from '@/utils/types'

/*
|--------------------------------------------------------------------------
| Get public runtime env
|--------------------------------------------------------------------------
*/
const {
	publicRuntimeConfig: { processEnv },
} = getConfig()

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface IFilters {
	// eslint-disable-next-line no-unused-vars
	[key: string]: (value: any) => boolean
}

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/
class StartonUtils {
	/*
	|--------------------------------------------------------------------------
	| URL Helpers
	|--------------------------------------------------------------------------
	*/
	static getURL(): string {
		const url =
			processEnv?.NEXT_PUBLIC_API_HOST && processEnv.NEXT_PUBLIC_API_HOST !== ''
				? processEnv.NEXT_PUBLIC_API_HOST
				: process?.env?.VERCEL_URL && process.env.VERCEL_URL !== ''
					? process.env.VERCEL_URL
					: 'http://localhost:3000'

		return url.includes('http') ? url : `https://${url as string}`
	}

	/*
	|--------------------------------------------------------------------------
	| MATHS
	|--------------------------------------------------------------------------
	*/
	// tpmt is two power minus ten times t scaled to [0,1]
	static tpmt(x: number) {
		return (Math.pow(2, -10 * x) - 0.0009765625) * 1.0009775171065494
	}

	/*
	|--------------------------------------------------------------------------
	| EASE
	|--------------------------------------------------------------------------
	*/

	// Bounce
	// ----------------------------------------------------------------------------
	private static bounce1 = 4 / 11
	private static bounce2 = 6 / 11
	private static bounce3 = 8 / 11
	private static bounce4 = 3 / 4
	private static bounce5 = 9 / 11
	private static bounce6 = 10 / 11
	private static bounce7 = 15 / 16
	private static bounce8 = 21 / 22
	private static bounce9 = 63 / 64
	private static bounce0 = 1 / (4 / 11) / (4 / 11)

	static bounceOut(t: number) {
		return (t = +t) < this.bounce1
			? this.bounce0 * t * t
			: t < this.bounce3
				? this.bounce0 * (t -= this.bounce2) * t + this.bounce4
				: t < this.bounce6
					? this.bounce0 * (t -= this.bounce5) * t + this.bounce7
					: this.bounce0 * (t -= this.bounce8) * t + this.bounce9
	}

	static bounceInOut(t: number) {
		return ((t *= 2) <= 1 ? 1 - this.bounceOut(1 - t) : this.bounceOut(t - 1) + 1) / 2
	}

	static bounceIn(t: number) {
		return 1 - this.bounceOut(1 - t)
	}

	// Circle
	// ----------------------------------------------------------------------------
	static circleIn(t: number) {
		return 1 - Math.sqrt(1 - t * t)
	}

	static circleOut(t: number) {
		return Math.sqrt(1 - --t * t)
	}

	static circleInOut(t: number) {
		return ((t *= 2) <= 1 ? 1 - Math.sqrt(1 - t * t) : Math.sqrt(1 - (t -= 2) * t) + 1) / 2
	}

	// Cubic
	// ----------------------------------------------------------------------------
	static cubicIn(t: number) {
		return t * t * t
	}

	static cubicOut(t: number) {
		return --t * t * t + 1
	}

	static cubicInOut(t: number) {
		return ((t *= 2) <= 1 ? t * t * t : (t -= 2) * t * t + 2) / 2
	}

	// Exp
	// ----------------------------------------------------------------------------
	static expIn(t: number) {
		return this.tpmt(1 - +t)
	}

	static expOut(t: number) {
		return 1 - this.tpmt(t)
	}

	static expInOut(t: number) {
		return ((t *= 2) <= 1 ? this.tpmt(1 - t) : 2 - this.tpmt(t - 1)) / 2
	}

	// Linear
	// ----------------------------------------------------------------------------
	static linear = (t: number) => +t

	// Quad
	// ----------------------------------------------------------------------------
	static quadIn(t: number) {
		return t * t
	}

	static quadOut(t: number) {
		return t * (2 - t)
	}

	static quadInOut(t: number) {
		return ((t *= 2) <= 1 ? t * t : --t * (2 - t) + 1) / 2
	}

	// Sin
	// ----------------------------------------------------------------------------
	private static pi = Math.PI
	private static halfPi = StartonUtils.pi / 2

	static sinIn(t: number) {
		return +t === 1 ? 1 : 1 - Math.cos(t * this.halfPi)
	}

	static sinOut(t: number) {
		return Math.sin(t * this.halfPi)
	}

	static sinInOut(t: number) {
		return (1 - Math.cos(this.pi * t)) / 2
	}

	// String
	// ----------------------------------------------------------------------------
	static capitalize(text: string) {
		return text.charAt(0).toUpperCase() + text.slice(1)
	}

	static camelCaseToText(text: string) {
		return text.replace(/([A-Z])/g, ' $1')
	}

	static sanitizeString(text: string) {
		return text.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
	}

	// Colors
	// ----------------------------------------------------------------------------
	static validHexColor(color: string) {
		return /^#[0-9A-F]{6}$/i.test(color)
	}

	static ifValidHexOrWhite(color: string) {
		return this.validHexColor(color) ? color : '#fff'
	}

	// Object
	// ----------------------------------------------------------------------------
	static isValidJsonFromString(jsonString: string) {
		try {
			JSON.parse(jsonString)
			return true
		} catch (e) {
			return false
		}
	}

	// Array
	// ----------------------------------------------------------------------------
	private static getValue(value: any) {
		return typeof value === 'string' ? value.toUpperCase() : value
	}

	static filterArray<TInput = any>(array: Array<TInput>, filters: IFilters): Array<TInput> {
		const filterKeys = Object.keys(filters)
		return array.filter((item) => {
			// validates all filter criteria
			return filterKeys.every((key) => {
				// ignores non-function predicates
				if (typeof filters[key] !== 'function') return true
				// @ts-ignore
				return filters[key](item[key])
			})
		})
	}

	static filterPlainArray<TInput = any, TArray = any>(
		array: Array<TInput>,
		filters: Dictionary<Array<TArray>>,
	): Array<TInput> {
		const filterKeys = Object.keys(filters)
		return array.filter((item) => {
			// validates all filter criteria
			return filterKeys.every((key) => {
				// ignores an empty filter
				if (!filters[key].length) return true
				// @ts-ignore
				return filters[key].find((filter) => this.getValue(filter) === this.getValue(item[key]))
			})
		})
	}

	static getDotColor = (state: string) => {
		switch (state.toLowerCase()) {
			case 'running':
				return 'secondary'
			case 'pending':
				return 'info'
			case 'paused':
				return 'warning'
			case 'error':
				return 'error'
			default:
				return 'primary'
		}
	}

	static renderBytesAsString = (bytes: number) => {
		const { value, unit }: { value: number; unit: string } = StartonUIUtils.valueLabelFormatBytes(bytes)
		return `${Math.round(value).toString()}${unit}`
	}

	/*
	|--------------------------------------------------------------------------
	| Time utils
	|--------------------------------------------------------------------------
	*/
	static _durationNames = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'] as const

	static formatDurationHelper = (value: number, symbol: string) => {
		if (value < 1) return ''
		const flooredValue = Math.floor(value)
		const prefix = flooredValue < 10 ? '0' : ''
		return prefix + flooredValue.toString() + symbol
	}

	static formatDuration = (period: number) => {
		const parts: Array<string> = []
		const duration = moment.duration(period)

		if (!duration || duration.toISOString() === 'P0D') return ''

		this._durationNames.map((durationName) => {
			if (parts.length >= 3) return
			const value = this.formatDurationHelper(duration[durationName](), durationName[0])
			if (!value) return
			parts.push(value)
		})

		if (parts.length === 0) return '0s ago'
		return parts.join(' ') + ' ago'
	}

	/*
	|--------------------------------------------------------------------------
	| Merging
	|--------------------------------------------------------------------------
	*/
	static deepMerge<T extends object | Array<any>>(target: T, source: T): T {
		const customizer = (objValue: T, srcValue: T) => {
			if (isArray(objValue)) {
				return objValue.concat(srcValue)
			}
		}

		return mergeWith(source, target, customizer)
	}

	static numberToLiteral(value: number) {
		if (value < 1000) return value.toString()
		if (value < 1000000) return (value / 1000).toFixed(1).toString() + 'K'
		if (value < 1000000000) return (value / 1000000).toFixed(1).toString() + 'M'
		if (value < 1000000000000) return (value / 1000000000).toFixed(1).toString() + 'B'
		if (value >= 1000000000000) return '1T+'
	}

	/*
	|--------------------------------------------------------------------------
	| Generators
	|--------------------------------------------------------------------------
	*/
	static generateRandomUUID() {
		return crypto.randomUUID()
	}

	static async createChecksumFromObject<T extends Record<string, unknown>>(object: T) {
		const jsonString = JSON.stringify(Object.assign({}, object))

		// Create encoding payload
		const encoder = new TextEncoder()
		const data = encoder.encode(jsonString)

		// Calculate SHA-256 hash
		const hashBuffer = await crypto.subtle.digest('SHA-256', data)
		const hashArray = Array.from(new Uint8Array(hashBuffer))

		return hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('')
	}

	/*
	|--------------------------------------------------------------------------
	| String
	|--------------------------------------------------------------------------
	*/

	/**
	 * Truncate a string
	 * @param text
	 * @param length
	 */
	static truncateString(text: string, length = 30) {
		return text.length > length ? text.substring(0, length) + '...' : text
	}

	/**
	 * Truncate a string (all chars) with ellipsis in the middle.
	 * @param text
	 * @param startCount
	 * @param endCount
	 */
	static truncateStringWithEllipsis(text: string, startCount = 4, endCount = 4) {
		const regex = new RegExp(`^(.{${startCount}})[^]{${text.length - startCount - endCount}}(.{${endCount}})$`)
		const match = text.match(regex)

		if (!match) return text
		return `${match[1]}…${match[2]}`
	}

	/**
	 * Check if a string is a valid JSON.
	 * @param text
	 */
	static isValidJSON(text: string) {
		try {
			JSON.parse(text)
			return true
		} catch (e) {
			return false
		}
	}
}

export default StartonUtils
