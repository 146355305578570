/*
| Developed by Starton
| Filename : AppBarInfos
| Author : Calixte DE TOURRIS (calixte@starton.io)
*/

import React from 'react'
import { useSelector } from 'react-redux'
import { StartonAddressField } from '@/components/common/StartonAddressField'
import { selectAppBarInfos } from '@/stores/common/commonSlice'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface AppBarInfosProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const AppBarInfos: React.FC<AppBarInfosProps> = () => {
	const addressFieldProps = useSelector(selectAppBarInfos)

	// Render
	//--------------------------------------------------------------------------
	if (!addressFieldProps) return null
	return (
		<React.Fragment>
			<StartonAddressField
				address={addressFieldProps.address}
				href={addressFieldProps.href}
				copyButtonProps={{ size: 'small' }}
			/>
		</React.Fragment>
	)
}
