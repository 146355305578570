/*
| Developed by Starton
| Filename: PGListItem.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { ListItem, ListItemProps, styled } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PGListItemProps extends ListItemProps {}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const ListItemStyled = styled(ListItem)(({ theme }) => ({
	display: 'list-item',
	color: theme.palette.text.secondary,
	'&::marker': {
		color: theme.palette.primary.main,
	},
	...theme.typography.body2,
	paddingTop: 0,
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PGListItem: React.FC<PGListItemProps> = ({ children, ...props }) => {
	// Render
	//--------------------------------------------------------------------------
	return <ListItemStyled {...props}>{children}</ListItemStyled>
}
