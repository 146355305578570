/*
| Developed by Starton
| Filename : DashboardProjectMenu.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, ButtonBase, ButtonBaseProps, Skeleton, styled, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { StartonMenu, StartonMenuItem, StartonMenuItemTitle } from '@starton/react-ui'
import { useRouter } from 'next/router'
import { ArrowBackOutlined } from '@mui/icons-material'
import useTranslation from 'next-translate/useTranslation'
import { DashboardProjectMenuItem } from './DashboardProjectMenuItem'
import { SessionStorageService } from '@/services/api/sessionStorage/sessionStorage.service'
import { axiosInstance } from '@/services/api/mutators/axios-custom-instance'
import { BillingPlanNames, useBillingPlans } from '@/config/billing/billing.config'
import { useProjectSpecificSWRKey } from '@/config/swr'
import { useProjectGetAll, useProjectGetOne } from '@/services/api/starton/endpoints/internal/internal'
import { Project } from '@/services/api/starton/model'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DashboardProjectMenuProps {}
interface DashboardProjectButtonProps extends ButtonBaseProps {
	projectColor: string
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const DashboardProjectButton = styled(ButtonBase, {
	shouldForwardProp: (prop) => prop !== 'projectColor',
})<DashboardProjectButtonProps>(({ theme, projectColor }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	position: 'relative',
	padding: theme.spacing(0, 1, 0, 2),
	height: '100% ',
	gap: 0,
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: theme.palette.divider,
	backgroundColor: theme.palette.background.paper2,
	transition: theme.transitions.create(['background-color']),

	'&:before': {
		position: 'absolute',
		left: 0,
		top: 0,
		height: '100%',
		display: 'block',
		backgroundColor: projectColor,
		content: '""',
		width: 4,
	},

	'&:hover': {
		backgroundColor: theme.palette.background.paper,
	},

	[theme.breakpoints.up('md')]: {
		minWidth: 270,

		'& svg': {
			marginLeft: theme.spacing(1),
		},
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardProjectMenu: React.FC<DashboardProjectMenuProps> = () => {
	const router = useRouter()
	const { projectId } = router.query
	const [projectMenuEl, setProjectMenuEl] = React.useState<null | HTMLElement>(null)
	const projectMenuIsOpened = Boolean(projectMenuEl)
	const { t } = useTranslation()
	const billingPlans = useBillingPlans()

	// SWR
	//--------------------------------------------------------------------------
	const [projectAllSwrKey, projectOneSwrKey]: Array<string> = useProjectSpecificSWRKey([
		'PROJECTS_GET_ALL',
		'PROJECTS_GET_ONE',
	])
	const { data: allProjectData } = useProjectGetAll({}, { swr: { swrKey: projectAllSwrKey } })
	const { data: currentProject } = useProjectGetOne((projectId as string) ?? '', {
		swr: { swrKey: projectOneSwrKey },
	})

	// Memoization
	//--------------------------------------------------------------------------
	const { billingPlanName, isBillingPlanPayable } = React.useMemo(() => {
		return {
			billingPlanName: billingPlans[currentProject?.subscriptionPlan as BillingPlanNames]?.name ?? '',
			isBillingPlanPayable: currentProject?.subscriptionPlan && currentProject?.subscriptionPlan !== 'FREE',
		}
	}, [billingPlans, currentProject?.subscriptionPlan])

	// Methods
	// ----------------------------------------------------------------------------
	const handleOpenProjectMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setProjectMenuEl(event.currentTarget)
	}

	const handleCloseProjectMenu = () => {
		setProjectMenuEl(null)
	}

	const handleBackToProjects = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		handleCloseProjectMenu()
		delete axiosInstance.defaults.headers.common['x-project-id']
		SessionStorageService.removeItem('starton.current-project-id')
		void router.push('/projects')
	}

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<DashboardProjectButton
				onClick={handleOpenProjectMenu}
				projectColor={currentProject?.color ?? '#fff'}
				data-testid="layout-project-menu-button"
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="flex-start"
					gap={0.5}
					color="text.primary"
					overflow={'hidden'}
					marginRight={{
						xs: 1,
						sm: 0,
					}}
				>
					{/* Desktop */}
					<Typography
						variant="caption"
						color={isBillingPlanPayable ? 'warning.main' : 'primary.main'}
						display={{ xs: 'none', sm: 'block' }}
					>
						{billingPlanName}
					</Typography>
					<Typography
						variant="button"
						fontWeight={500}
						fontSize={14}
						maxWidth={270}
						display={{ xs: 'none', sm: 'block' }}
						textOverflow="ellipsis"
						overflow="hidden"
						data-dd-privacy="mask"
						noWrap
					>
						{currentProject?.name ? (
							currentProject.name
						) : (
							<Skeleton variant="text" width={150} sx={{ fontSize: '1rem' }} />
						)}{' '}
					</Typography>

					{/*	Mobile */}
					<Typography
						variant="button"
						fontWeight={500}
						fontSize={14}
						maxWidth={270}
						display={{ xs: 'block', sm: 'none' }}
						textOverflow="ellipsis"
						overflow="hidden"
						data-dd-privacy="mask"
						noWrap
					>
						My projects
					</Typography>
				</Box>
				{projectMenuIsOpened ? (
					<ExpandLessIcon sx={{ width: 16, height: 16, color: 'text.primary' }} />
				) : (
					<ExpandMoreIcon sx={{ width: 16, height: 16, color: 'text.primary' }} />
				)}
			</DashboardProjectButton>
			<StartonMenu
				id="basic-menu"
				anchorEl={projectMenuEl}
				open={projectMenuIsOpened}
				onClose={handleCloseProjectMenu}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
					disablePadding: true,
				}}
				data-testid="layout-project-menu"
			>
				{allProjectData?.items?.map((project, index) => (
					<DashboardProjectMenuItem
						project={project as unknown as Project}
						handleCloseProjectMenu={handleCloseProjectMenu}
						key={index}
					/>
				)) ?? null}
				<StartonMenuItem
					onClick={handleBackToProjects}
					sx={{
						borderTop: '1px solid',
						borderColor: 'divider',
					}}
					data-testid="layout-project-menu-back"
				>
					<StartonMenuItemTitle
						compact
						title={t('common:topBar.backToProjectList')}
						icon={<ArrowBackOutlined />}
						data-testid="layout-project-menu-back-title"
					/>
				</StartonMenuItem>
			</StartonMenu>
		</React.Fragment>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
DashboardProjectMenu.displayName = 'DashboardProjectMenu'
