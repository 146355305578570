/*
| Developed by Starton
| Filename : GoogleTagManager.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import Script from 'next/script'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface GoogleTagManagerProps {
	children: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const GTM_IS_ACTIVATED = ['prod', 'staging'].includes(process.env.NEXT_PUBLIC_APP_ENV as string)
const GTM_KEY: string = process.env.NEXT_PUBLIC_GTM_KEY as string

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const GoogleTagManager: React.FC<GoogleTagManagerProps> = (props) => {
	if (!GTM_IS_ACTIVATED || !GTM_KEY) {
		return <React.Fragment>{props.children}</React.Fragment>
	}

	return (
		<React.Fragment>
			<Script id="google-tag-manager" strategy="afterInteractive">
				{`
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','${GTM_KEY}');
				`}
			</Script>
			<noscript>
				<iframe
					src={`https://www.googletagmanager.com/ns.html?id=${GTM_KEY}`}
					height="0"
					width="0"
					className={'gtm-hide'}
				/>
			</noscript>
			{props.children}
		</React.Fragment>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
GoogleTagManager.displayName = 'GoogleTagManager'
