/*
| Developed by Starton
| Filename : useDebounce.ts
| Author : Calixte De Tourris (calixte@starton.com)
*/

import { useEffect, useState } from 'react'

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const useDebounce = <ValueType,>(value: ValueType, delay?: number): ValueType => {
	const [debouncedValue, setDebouncedValue] = useState<ValueType>(value)

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500)

		return () => {
			clearTimeout(timer)
		}
	}, [value, delay])

	return debouncedValue
}

export const useDebounceLoading = <ValueType,>(value: ValueType, delay?: number) => {
	const [debouncedValue, setDebouncedValue] = useState<ValueType>(value)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		const timer = setTimeout(() => {
			setDebouncedValue(value)
			setLoading(false)
		}, delay || 500)

		return () => {
			setLoading(false)
			clearTimeout(timer)
		}
	}, [value, delay])

	return { debouncedValue, loading }
}
