/*
| Developed by Starton
| Filename : DefaultWhiteLabelSeo.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import merge from 'lodash/merge'
import { Router } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { DEFAULT_SEO_PROPS, DefaultSeoPropsExtra } from '@/config/common/seo.config'
import { useGetCanonialUrl } from '@/hooks/useGetCanonialUrl'
import { useWhiteLabel } from '@/components/white-labelling/context/whitelabel.context'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DefaultWhiteLabelSeoProps {
	router: Router
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DefaultWhiteLabelSeo: React.FC<DefaultWhiteLabelSeoProps> = ({ router }) => {
	const whiteLabel = useWhiteLabel()

	// Prepare canonical url
	// ----------------------------------------------------------------------------
	const { url } = useGetCanonialUrl(router)

	// Create default SEO data props
	// ----------------------------------------------------------------------------
	const defaultDataProps = React.useMemo<DefaultSeoPropsExtra>(() => {
		// Prepare data with component data
		const seoProps: DefaultSeoPropsExtra = {
			titleTemplate: whiteLabel.isStarton ? 'Starton | %s' : `${whiteLabel.name} | %s`,
			canonical: url,
			openGraph: {
				url,
				siteName: whiteLabel.isStarton ? 'Starton' : whiteLabel.name,
			},
			additionalLinkTags: [
				{
					rel: 'shortcut icon',
					href: whiteLabel.isStarton ? '/favicon.ico' : whiteLabel.logo,
				},
			],
		}

		// Deep merging
		return merge(
			{
				...DEFAULT_SEO_PROPS,
			},
			seoProps,
		)
	}, [url, whiteLabel.isStarton, whiteLabel?.logo, whiteLabel.name])

	// Render
	// ----------------------------------------------------------------------------
	return <DefaultSeo {...defaultDataProps} />
}
