/*
| Developed by Starton
| Filename : StartonCopy
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { StartonIconButton, StartonIconButtonProps, StartonTooltip } from '@starton/react-ui'
import { ContentCopy } from '@mui/icons-material'
import { useTheme } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonCopyProps extends StartonIconButtonProps {
	value: string
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonCopy: React.FC<StartonCopyProps> = (props) => {
	const { t } = useTranslation()
	const { value, children: _children, ...buttonProps } = props
	const [clicked, setClicked] = React.useState(false)
	const theme = useTheme()
	const [clickedNonce, setClickedNonce] = React.useState(0)

	// Methods
	//--------------------------------------------------------------------------
	const handleCopy = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation()
		setClickedNonce((state) => state + 1)
		try {
			if (typeof navigator !== 'undefined') {
				await navigator.clipboard.writeText(value ?? '')
				setClicked(true)
			} else {
				console.error('Your navigator is not compatible with navigator copy.')
			}
		} catch (error) {
			console.error('ERROR', error)
		}
	}

	const handleOnClose = () => {
		setTimeout(() => {
			setClicked(false)
			setClickedNonce(0)
		}, 100)
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<StartonTooltip
			color="default"
			title={
				clickedNonce > 21
					? `Okay I think it's copied now :)`
					: t(clicked ? 'common:common.copied' : 'common:common.copy')
			}
			onClose={handleOnClose}
		>
			<StartonIconButton
				color="secondary"
				type="button"
				variant="contained"
				onClick={handleCopy}
				{...buttonProps}
			>
				<ContentCopy sx={{ fill: theme.palette.text.primary }} />
			</StartonIconButton>
		</StartonTooltip>
	)
}

StartonCopy.defaultProps = {}
