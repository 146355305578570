/*
| Developed by Starton
| Filename : playgroundSlice.ts
*/

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostHog } from 'posthog-js'
import { GtagService } from '@/services/gtag/gtag.service'
import { RootState } from '@/stores/store'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export interface PlaygroundState {
	currentStep: number
	devMode: {
		show: boolean
		code: string
	}
	params: {
		cid: string
		imageUrl: string
		nftName: string
		signerWallet: string
		networkName: string
		smartContractAddress: string
	}
}

export interface PlaygroundValidateCurrentStepPayload {
	exerciseId: string
	posthog: PostHog
	userId: string
	projectId: string
	step: number
}

/*
|--------------------------------------------------------------------------
| INIT STATE
|--------------------------------------------------------------------------
*/
const initialState: PlaygroundState = {
	currentStep: 0,
	devMode: {
		show: false,
		code: '',
	},
	params: {
		cid: '',
		imageUrl: '',
		nftName: '',
		signerWallet: '',
		networkName: 'avalanche-fuji',
		smartContractAddress: '',
	},
}

/*
|--------------------------------------------------------------------------
| SLICE
|--------------------------------------------------------------------------
*/
export const playgroundSlice = createSlice({
	name: 'playground',
	initialState,
	reducers: {
		playgroundReset: () => initialState,
		playgroundValidateCurrentStep: (state, action: PayloadAction<PlaygroundValidateCurrentStepPayload | null>) => {
			state.currentStep += 1
			if (action?.payload) {
				const { exerciseId, step, userId, posthog, projectId } = action.payload
				GtagService.createUserEvent(
					{
						...(userId && {
							userId,
						}),
						...(projectId && {
							projectId: String(projectId),
						}),
						name: 'playground:step',
						metadata: {
							exerciseId,
							step,
						},
					},
					posthog,
				)
			}
		},
		playgroundInvalidateCurrentStep: (state) => {
			state.currentStep -= 1
		},
		playgroundDevModeSetOpen: (state, action: PayloadAction<boolean>) => {
			state.devMode.show = action.payload
		},
		playgroundSetSignerWallet: (state, action: PayloadAction<string>) => {
			state.params.signerWallet = action.payload
		},
		playgroundSetCid: (state, action: PayloadAction<string>) => {
			state.params.cid = action.payload
		},
		playgroundSetImageUrl: (state, action: PayloadAction<string>) => {
			state.params.imageUrl = action.payload
		},
		playgroundSetNetwork: (state, action: PayloadAction<string>) => {
			state.params.networkName = action.payload
		},
		playgroundSetDevModeCode: (state, action: PayloadAction<string>) => {
			state.devMode.code = action.payload
		},
		playgroundSetSmartContractAddress: (state, action: PayloadAction<string>) => {
			state.params.smartContractAddress = action.payload
		},
		playgroundSetNftName: (state, action: PayloadAction<string>) => {
			state.params.nftName = action.payload
		},
		playgroundSetFakeSmartContract: (state) => {
			state.params.smartContractAddress = '0x9ddab05641d3956bb97f3accf329431e93d08c0e'
		},
		playgroundRestartExercise: (state) => {
			state.params = initialState.params
			state.currentStep = initialState.currentStep
		},
	},
})

/*
|--------------------------------------------------------------------------
| EXPORT SELECTORS
|--------------------------------------------------------------------------
*/
export const selectPlaygroundState = (state: RootState) => state.playground
export const selectPlaygroundCurrentStep = (state: RootState) => state.playground.currentStep
export const selectPlaygroundShowDevMode = (state: RootState) => state.playground.devMode.show
export const selectPlaygroundDevMode = (state: RootState) => state.playground.devMode
export const selectPlaygroundSignerWallet = (state: RootState) => state.playground.params.signerWallet
export const selectPlaygroundCid = (state: RootState) => state.playground.params.cid
export const selectPlaygroundImageUrl = (state: RootState) => state.playground.params.imageUrl
export const selectPlaygroundNetworkName = (state: RootState) => state.playground.params.networkName
export const selectPlaygroundParams = (state: RootState) => state.playground.params

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export const {
	playgroundReset,
	playgroundValidateCurrentStep,
	playgroundInvalidateCurrentStep,
	playgroundDevModeSetOpen,
	playgroundSetSignerWallet,
	playgroundSetCid,
	playgroundSetImageUrl,
	playgroundSetNetwork,
	playgroundSetDevModeCode,
	playgroundSetSmartContractAddress,
	playgroundSetNftName,
	playgroundSetFakeSmartContract,
	playgroundRestartExercise,
} = playgroundSlice.actions
export default playgroundSlice.reducer
