/*
| Developed by Starton
| Filename : cookies.service.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { OptionsType } from 'cookies-next/lib/types'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import getConfig from 'next/config'

/*
|--------------------------------------------------------------------------
| Get public runtime env
|--------------------------------------------------------------------------
*/
const {
	publicRuntimeConfig: { processEnv },
} = getConfig()

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type CookieName = 'NEXT_LOCALE' | 'starton-last-project-id' | 'starton-ssr-project-id'

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export class CookiesService {
	// Common
	// ----------------------------------------------------------------------------
	static getCookie(key: CookieName, options?: OptionsType) {
		return getCookie(key, options)
	}

	static deleteCookie(key: CookieName, options?: OptionsType) {
		return deleteCookie(key, options)
	}

	static setCookie<T>(key: CookieName, data: T, options?: OptionsType) {
		return setCookie(key, data, {
			domain: processEnv.NEXT_PUBLIC_COOKIE_DOMAIN,
			sameSite: 'lax',
			...options,
		})
	}

	// Auth
	// ----------------------------------------------------------------------------
	static getAuthCookie(options?: OptionsType) {
		return getCookie(processEnv.NEXT_PUBLIC_AUTH_SESSION_NAME as string, options)
	}

	static deleteAuthCookie(options?: OptionsType) {
		return deleteCookie(processEnv.NEXT_PUBLIC_AUTH_SESSION_NAME as string, options)
	}

	// Project ID
	// ----------------------------------------------------------------------------
	static getLastProjectId(options?: OptionsType) {
		return getCookie('starton-last-project-id', options)
	}
	static deleteLastProjectId(options?: OptionsType) {
		return deleteCookie('starton-last-project-id', options)
	}
	static setLastProjectId(data: string, options?: OptionsType) {
		if (data === 'default') return
		return this.setCookie<string>('starton-last-project-id', data, options)
	}

	static getSSRProjectId(options?: OptionsType) {
		return getCookie('starton-ssr-project-id', options)
	}
	static deleteSSRProjectId(options?: OptionsType) {
		return deleteCookie('starton-ssr-project-id', options)
	}
	static setSSRProjectId(data: string, options?: OptionsType) {
		if (data === 'default') return
		return this.setCookie<string>('starton-ssr-project-id', data, options)
	}
}
