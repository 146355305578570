/*
| Developed by Starton
| Filename : Logo.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Starton } from '@starton/react-ui-iconography'
import { useTheme } from '@mui/material'
import { useWhiteLabel } from '@/hooks/white-labelling/useWhiteLabel'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface LogoProps {
	onClick?: React.MouseEventHandler
	width?: string | number
	height?: string | number
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const Logo: React.FC<LogoProps> = ({ width, height, ...props }) => {
	const { logo, name } = useWhiteLabel()
	const theme = useTheme()

	if (logo === 'default') {
		return (
			<Starton
				fill={theme.palette.text.primary}
				{...props}
				sx={{
					width: width || '100%',
					height: height || 'auto',
					...(props.onClick
						? {
								cursor: 'pointer',
							}
						: {}),
				}}
			/>
		)
	}

	return (
		<img
			src={logo}
			alt={name}
			style={{
				width: width || '100%',
				height: height || 'auto',
				...(props.onClick
					? {
							cursor: 'pointer',
						}
					: {}),
			}}
			{...props}
		/>
	)
}
