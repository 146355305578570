/*
| Developed by Starton
| Filename : WhiteLabelPresetListCard.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { Box, BoxProps, styled, Typography } from '@mui/material'
import { StartonChip, StartonIconButton, StartonIconButtonProps } from '@starton/react-ui'
import { ArrowForwardOutlined } from '@mui/icons-material'
import useTranslate from 'next-translate/useTranslation'
import { Starton } from '@starton/react-ui-iconography'
import { WhiteLabelConfiguration } from '@/components/white-labelling/context/whitelabel.context'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface WhiteLabelPresetListCardProps {
	whiteLabelConfig: WhiteLabelConfiguration
	selected: boolean
	endIcon?: React.ReactNode
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const WhiteLabelPresetListCardContainer = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'selected',
})<BoxProps & { selected: boolean }>(({ theme, onClick, selected }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.autoSpacing('md'),
	backgroundColor: theme.palette.background.paper,
	borderWidth: 1,
	borderStyle: 'solid',
	borderColor: theme.palette.divider,
	outlineWidth: 1,
	outlineStyle: 'solid',
	outlineColor: 'transparent',
	padding: theme.autoSpacing('xl'),
	position: 'relative',
	transition: theme.transitions.create(['background-color', 'border-color', 'outline']),
	'&:hover': {
		borderColor: theme.palette.primary.hover,
		backgroundColor: theme.palette.background.paper2,
	},
	'&:active': {
		borderColor: theme.palette.primary.main,
		outlineColor: theme.palette.primary.main,
	},
	...(Boolean(onClick && !selected) && {
		cursor: 'pointer',
	}),
	...(Boolean(onClick && selected) && {
		cursor: 'not-allowed',
	}),
	...(selected && {
		borderColor: theme.palette.primary.main,
		outlineColor: theme.palette.primary.main,
	}),
}))

const LogoContainer = styled(Box, {
	shouldForwardProp: (prop) => prop !== 'platformColor',
})<BoxProps & { platformColor: string }>(({ platformColor, theme }) => ({
	width: '100%',
	height: '24px',
	color: platformColor ? platformColor : theme.palette.text.primary,

	'& svg': {
		height: '24px',
	},

	'& img': {
		height: '24px',
	},
}))

const IconButtonStyled = styled(StartonIconButton, {
	shouldForwardProp: (prop) => prop !== 'color',
})<Omit<StartonIconButtonProps, 'color'> & { platformColor: string }>(({ platformColor, theme }) => ({
	marginLeft: 'auto',
	color: platformColor ? platformColor : theme.palette.text.primary,

	'$ svg': {
		fill: platformColor ? platformColor : theme.palette.text.primary,
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const WhiteLabelPresetListCard: React.FC<WhiteLabelPresetListCardProps> = ({
	whiteLabelConfig: { featuredFlags, name, logo, theme },
	selected,
	endIcon,
	onClick,
}) => {
	const { t } = useTranslate()
	const featuredFlagsList = React.useMemo(() => {
		return Object.entries(featuredFlags)
			.filter(([, value]) => value)
			.map(([key]) => key)
	}, [featuredFlags])

	return (
		<WhiteLabelPresetListCardContainer onClick={onClick} selected={selected}>
			<Box display="flex" flexDirection="row" justifyContent={'space-between'} alignItems={'center'}>
				<Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
					{/* @ts-ignore */}
					<LogoContainer platformColor={theme?.palette?.primary.main}>
						{logo === 'default' ? <Starton fill={'#fff'} /> : <img src={logo} alt={name} />}
					</LogoContainer>
					<Typography variant="subtitle2" textTransform="uppercase" sx={{ marginBottom: '-3px' }}>
						{name}
					</Typography>
				</Box>

				{/* @ts-ignore */}
				<IconButtonStyled size="large" platformColor={theme?.palette?.primary.main}>
					{endIcon ? endIcon : <ArrowForwardOutlined />}
				</IconButtonStyled>
			</Box>

			<Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gap={1}>
				{featuredFlagsList.length > 0 ? (
					featuredFlagsList.map((flag) => (
						<StartonChip
							key={flag}
							label={t(`common:whitelabel.featuredFlags.${flag}`)}
							color="secondary"
							size={'small'}
						/>
					))
				) : (
					<StartonChip label="No featured flags activated" color="secondary" />
				)}
			</Box>
		</WhiteLabelPresetListCardContainer>
	)
}
