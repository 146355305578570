/*
| Developed by Starton
| Filename: PGHome.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Box, Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { PGCard, PGCardProps } from '../../lib/PGCard'
import { PGListItem } from '../../lib/PGListItem'
import { projectUrl } from '@/config/urls/project-builder'
import { useWhiteLabel } from '@/components/white-labelling/context/whitelabel.context'
import { useProjectGetOne } from '@/services/api/starton/endpoints/internal/internal'
import { useGlobalTranslation } from '@/hooks/i18n/useGlobalTranslation'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PGHomeProps {}

export const exerciseIds = {
	mintNft: 'mint-your-first-nft',
	trackSmartContractEvents: 'track-smart-contract-events',
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PGHome: React.FC<PGHomeProps> = () => {
	const { t } = useGlobalTranslation()
	const router = useRouter()
	const { projectId } = router.query
	const { data: project } = useProjectGetOne(projectId as string)
	const whiteLabel = useWhiteLabel()

	// Mint NFT exercice variant
	// ----------------------------------------------------------------------------
	const mintNFTVariant = React.useMemo<PGCardProps['variant']>(() => {
		if (!whiteLabel.featuredFlags.enableRelayer) return 'disabled'

		return project?.playground?.pg_mint_nft === 1 ? 'done' : 'ready'
	}, [project?.playground?.pg_mint_nft, whiteLabel.featuredFlags.enableRelayer])

	// Render
	//--------------------------------------------------------------------------
	return (
		<Box marginTop={4} marginBottom={10} paddingX={3} display="flex" flexDirection="column" gap={5} width="100%">
			<Box display="flex" flexDirection="column" gap={1}>
				<Typography variant="h1">{t('playground:home.title')}</Typography>
				<Typography variant="body2" maxWidth="75ch" color="text.secondary">
					{t('playground:home.description')}
				</Typography>
			</Box>
			<Box display="flex" flexDirection="column" gap={2}>
				<PGCard
					title={t('playground:mint-nft-card.title')}
					description={t('playground:mint-nft-card.description')}
					variant={mintNFTVariant}
					href={projectUrl.create((urls) => urls.project.playground.mintNft)}
					id={exerciseIds.mintNft}
				>
					{Array(4)
						.fill(0)
						.map((_, index) => (
							<PGListItem key={index}>
								{t(`playground:mint-nft-card.steps.${index}`, { returnObjects: true })}
							</PGListItem>
						))}
				</PGCard>
				<PGCard
					title={t('playground:track-smart-contract-events-card.title')}
					description={t('playground:track-smart-contract-events-card.description')}
					variant="soon"
					href={projectUrl.create((urls) => urls.project.playground.mintNft)}
				>
					{Array(4)
						.fill(0)
						.map((_, index) => (
							<PGListItem key={index}>
								{t(`playground:mint-nft-card.steps.${index}`, { returnObjects: true })}
							</PGListItem>
						))}
				</PGCard>
				{/*<PGCard*/}
				{/*	title={t('playground:track-smart-contract-event.title')}*/}
				{/*	description={t('playground:track-smart-contract-event.description')}*/}
				{/*	buttonProps={{ children: 'Start' }}*/}
				{/*	variant="ready"*/}
				{/*	href={projectUrl.create((urls) => urls.project.playground.trackSmartContractEvents)}*/}
				{/*>*/}
				{/*	<PGListItem>Use a wallet</PGListItem>*/}
				{/*	<PGListItem>Pay deployment GAS</PGListItem>*/}
				{/*	<PGListItem>Create your NFT</PGListItem>*/}
				{/*	<PGListItem>Mint the NFT</PGListItem>*/}
				{/*</PGCard>*/}
			</Box>
		</Box>
	)
}
