/*
| Developed by Starton
| Filename : usePlatform.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import useSwr from 'swr'
import { FAPIGetParams, SecondAxiosParameter, Platforms } from '../contracts'
import { fapiAxiosInstance } from '@/services/fapi'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UsePlatformsParams extends FAPIGetParams {}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const FAPI_PLATFORMS = '/api/platforms?populate=deep'
export const FAPI_PLATFORM = (slug: string) =>
	`/api/platforms?sort=createdAt:desc&populate=deep&filters[slug][$eq]=${slug}`

/*
|--------------------------------------------------------------------------
| Hook - Get all
|--------------------------------------------------------------------------
*/
export const platformsGetAll = (
	params?: UsePlatformsParams,
	options?: SecondAxiosParameter<typeof fapiAxiosInstance>,
) => {
	return fapiAxiosInstance<Platforms>(
		{
			url: FAPI_PLATFORMS,
			method: 'get',
			params: {
				sort: 'createdAt:desc',
				...params,
			},
		},
		options,
	)
}
export const usePlatforms = (params?: UsePlatformsParams) => {
	return useSwr<Awaited<Platforms>>(FAPI_PLATFORMS, () => platformsGetAll(params), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		refreshInterval: 3600000, // 1 hour
		revalidateOnMount: true,
	})
}

/*
|--------------------------------------------------------------------------
| Hook - Get one
|--------------------------------------------------------------------------
*/
export const platformGetOne = (slug: string, options?: SecondAxiosParameter<typeof fapiAxiosInstance>) => {
	return fapiAxiosInstance<Platforms>(
		{
			url: FAPI_PLATFORM(slug),
			method: 'get',
		},
		options,
	)
}

export const usePlatform = (slug: string) => {
	return useSwr<Awaited<Platforms>>(FAPI_PLATFORM(slug), () => platformGetOne(slug), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		refreshInterval: 3600000, // 1 hour
		revalidateOnMount: true,
	})
}
