/*
| Developed by Starton
| Filename : nav.config.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import {
	BookOutlined as BookOutlinedIcon,
	RemoveRedEyeOutlined as RemoveRedEyeOutlinedIcon,
	CodeOutlined as CodeOutlinedIcon,
	SettingsOutlined as SettingsOutlinedIcon,
	SyncAltOutlined,
	HandymanOutlined,
	DashboardOutlined,
	CreditCardOutlined,
} from '@mui/icons-material'
import { Ipfs } from '@starton/react-ui-iconography'
import { projectUrl } from '@/config/urls/project-builder'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
// | 'home'
export type NavItemNames =
	| 'dashboard'
	| 'transactionManager'
	// | 'transactions'
	// | 'wallet'
	| 'smartContract'
	| 'ipfs'
	| 'watcher'
	| 'tools'
	| 'developer'
	| 'settings'
export type BottomNavItemNames = 'billing'
export interface NavItem {
	name: string
	alt: string
	href: (...args: Array<string>) => string
	selectedRegex: (...args: Array<string>) => RegExp
	icon: React.ReactNode
}
export interface NavConfigSizesDrawer {
	widthOpened: number
	widthClosed: number
}
export interface NavConfigSizesAppBar {
	height: number
}
export interface NavConfigSizes {
	drawer: NavConfigSizesDrawer
	whiteLabelDrawer: NavConfigSizesDrawer
	appbar: NavConfigSizesAppBar
}
export interface NavConfig {
	sizes: NavConfigSizes
	navItems: Record<NavItemNames, NavItem>
	bottomNavItems: Record<BottomNavItemNames, NavItem>
}

/*
|--------------------------------------------------------------------------
| Configurations
|--------------------------------------------------------------------------
*/
export const NavDefaultConfig: NavConfig = {
	sizes: {
		drawer: {
			widthOpened: 256,
			widthClosed: 60,
		},
		whiteLabelDrawer: {
			widthOpened: 400,
			widthClosed: 0,
		},
		appbar: {
			height: 56,
		},
	},
	navItems: {
		dashboard: {
			name: 'Dashboard',
			icon: <DashboardOutlined />,
			// href: (projectId: string) => `/projects/${projectId}/dashboard`,
			href: () => projectUrl.create((urls) => urls.project.dashboard),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/dashboard.*`),
			alt: 'Dashboard',
		},
		transactionManager: {
			name: 'Transaction Manager',
			icon: <SyncAltOutlined />,
			// href: (projectId: string) => `/projects/${projectId}/transactions`,
			href: () => projectUrl.create((urls) => urls.project.transactionManager),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/transaction-manager.*`),
			alt: 'Transaction Manager',
		},
		// transactions: {
		// 	name: 'Transactions',
		// 	icon: <SyncAltOutlined />,
		// 	// href: (projectId: string) => `/projects/${projectId}/transactions`,
		// 	href: () => projectUrl.create((urls) => urls.project.transactions),
		// 	selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/transactions.*`),
		// 	alt: 'Transactions',
		// },
		// wallet: {
		// 	name: 'Wallet',
		// 	icon: <AccountBalanceWalletOutlinedIcon />,
		// 	// href: (projectId: string) => `/projects/${projectId}/wallet`,
		// 	href: () => projectUrl.create((urls) => urls.project.wallet),
		// 	selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/wallet.*`),
		// 	alt: 'Wallet',
		// },
		smartContract: {
			name: 'Smart Contract',
			icon: <BookOutlinedIcon />,
			// href: (projectId: string) => `/projects/${projectId}/smart-contract`,
			href: () => projectUrl.create((urls) => urls.project.smartContracts),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/smart-contract.*`),
			alt: 'Smart Contract',
		},
		ipfs: {
			name: 'IPFS Storage',
			icon: <Ipfs fill={'currentColor'} />,
			// href: (projectId: string) => `/projects/${projectId}/ipfs`,
			href: () => projectUrl.create((urls) => urls.project.ipfs),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/ipfs.*`),
			alt: 'IPFS Storage',
		},
		watcher: {
			name: 'Monitor',
			icon: <RemoveRedEyeOutlinedIcon />,
			// href: (projectId: string) => `/projects/${projectId}/monitor`,
			href: () => projectUrl.create((urls) => urls.project.monitor),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/monitor.*`),
			alt: 'Monitor',
		},
		tools: {
			name: 'Tools',
			icon: <HandymanOutlined />,
			// href: (projectId: string) => `/projects/${projectId}/tools`,
			href: () => projectUrl.create((urls) => urls.project.tools),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/tools.*`),
			alt: 'Tools',
		},
		developer: {
			name: 'Developer',
			icon: <CodeOutlinedIcon />,
			// href: (projectId: string) => `/projects/${projectId}/developer`,
			href: () => projectUrl.create((urls) => urls.project.developer),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/developer.*`),
			alt: 'Developer',
		},
		settings: {
			name: 'Settings',
			icon: <SettingsOutlinedIcon />,
			// href: (projectId: string) => `/projects/${projectId}/settings?activeTab=information`,
			href: () => projectUrl.create((urls) => urls.project.settings.information),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/settings.*`),
			alt: 'Settings',
		},
	},
	bottomNavItems: {
		billing: {
			name: 'Billing',
			icon: <CreditCardOutlined />,
			// href: (projectId: string) => `/projects/${projectId}/billing`,
			href: () => projectUrl.create((urls) => urls.billing),
			selectedRegex: (projectId: string) => new RegExp(`/projects/${projectId}/billing.*`),
			alt: 'Billing',
		},
	},
}
