/*
| Developed by Starton
| Filename : DashboardLayout.tsx
*/

import React from 'react'
import { useRouter } from 'next/router'
import { GlobalStyles } from '@mui/material'
import { usePostHog } from 'posthog-js/react'
import { toast, Toaster } from 'sonner'
import { StartonSnackbar, StartonSnackbarProps } from '@starton/react-ui'
import { GtagService } from '@/services/gtag/gtag.service'
import { useWhoami } from '@/services/auth-api/endpoints/users'
import { StartonLayouts } from '@/contracts/next-layout'
import { commonResponseInterceptorError, commonResponseInterceptorSuccess } from '@/services/api/api.interceptors'
import { axiosInstance } from '@/services/api/mutators/axios-custom-instance'
import {
	DefaultTemplate,
	EmptyTemplate,
	NotAuthTemplate,
	PlaygroundTemplate,
} from '@/components/layouts/pages/DashboardLayout/templates'
import { WagmiProvider } from '@/services/wagmi/wagmi.service'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface DashboardLayoutProps {
	children: React.ReactNode
	layout: StartonLayouts
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const LAYOUT_MAPPING: Record<StartonLayouts, React.ElementType> = {
	default: DefaultTemplate,
	empty: EmptyTemplate,
	error: EmptyTemplate,
	playground: PlaygroundTemplate,
	auth: EmptyTemplate,
	public: NotAuthTemplate,
}

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/
export const triggerNotification = (variant: StartonSnackbarProps['color'], title: string, description?: string) => {
	toast.custom(() => (
		<StartonSnackbar
			color={variant}
			title={title}
			description={description}
			sx={{ width: '100%', minWidth: 370, maxWidth: 370, textWarp: 'balanced' }}
		/>
	))
}

/*
|--------------------------------------------------------------------------
| Global styles
|--------------------------------------------------------------------------
*/
const inputGlobalStyles = (
	<GlobalStyles
		styles={(theme) => ({
			/* width */
			'&::-webkit-scrollbar': {
				width: '8px',
				height: '8px',
			},

			/* Track */
			'&::-webkit-scrollbar-track': {
				background: theme.palette.divider,
			},

			/* Handle */
			'&::-webkit-scrollbar-thumb': {
				background: theme.palette.background.paper,
				borderRadius: 5,
				borderStyle: 'solid',
				borderWidth: '1px',
				borderColor: theme.palette.divider,
			},

			'&::-webkit-scrollbar-corner': {
				background: 'transparent',
			},

			'::-moz-selection': {
				/* Code for Firefox */
				color: `${theme.palette.primary.contrastText} !important`,
				background: `${theme.palette.primary.main} !important`,
			},

			'::selection': {
				color: `${theme.palette.primary.contrastText} !important`,
				background: `${theme.palette.primary.main} !important`,
			},
		})}
	/>
)

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const DashboardLayout: React.FC<DashboardLayoutProps> = (props) => {
	const router = useRouter()
	const { projectId } = router.query
	// const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'), { noSsr: true })
	const { data: user } = useWhoami()
	const posthog = usePostHog()

	// Whitelabel - Change preset from URL
	// ----------------------------------------------------------------------------
	// React.useEffect(() => {
	// 	if (router.query?.preset === 'starton') {
	// 		whiteLabel.onReset()
	// 		return
	// 	}
	//
	// 	if (!whiteLabel.isStarton || router.query?.preset) {
	// 		platformGetOne((router.query?.preset as string) ?? whiteLabel.slug)
	// 			.then((results) => {
	// 				whiteLabel.onUpdate({
	// 					name: results.data[0].attributes.name,
	// 					slug: results.data[0].attributes.slug,
	// 					colorMode: results.data[0].attributes.colorMode,
	// 					featuredFlags: {
	// 						enableListener: results.data[0].attributes.featuredFlags.enableListener,
	// 						enableRelayer: results.data[0].attributes.featuredFlags.enableRelayer,
	// 						poweredBy: results.data[0].attributes.featuredFlags.poweredBy,
	// 					},
	// 					logo: results.data[0].attributes.logo.data.attributes.url,
	// 					logoType: results.data[0].attributes.logoType.data.attributes.url,
	// 					theme: {
	// 						palette: {
	// 							primary: {
	// 								main: results.data[0].attributes.theme.primary.main,
	// 								dark: results.data[0].attributes.theme.primary.dark,
	// 								light: results.data[0].attributes.theme.primary.light,
	// 								hover: results.data[0].attributes.theme.primary.hover,
	// 								contrastText: results.data[0].attributes.theme.primary.contrastText,
	// 							},
	// 						},
	// 					},
	// 					chainIds: results.data[0].attributes.chainIds,
	// 					isStarton: false,
	// 				})
	// 			})
	// 			.catch((error) => {
	// 				console.error(error)
	// 			})
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])

	// Axios interceptors
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		/**
		 * Interceptor for Starton's axios instance
		 */
		axiosInstance.interceptors.response.use(
			commonResponseInterceptorSuccess,
			commonResponseInterceptorError(router),
		)
		axiosInstance.interceptors.request.use(
			(config) => {
				return config
			},
			(error) => {
				// Do something with request error
				return Promise.reject(error)
			},
		)
	}, [router])

	// Identify user on sentry
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (user?.id) {
			const id = user?.id // TODO, is this the right way to get the user id?

			if (id) {
				// No need for posthog here, we will use Post Hog's identify right below
				GtagService.createUserEvent({
					name: 'identify',
					userId: id,
				})
			}

			// PostHog Identify
			//--------------------------------------------------------------------------
			if (id) {
				// TODO optimise number of call (posthog without hook?)
				posthog?.identify(`usr_${id.replace(/-/g, '')}`, {
					email: user?.email,
					name: user?.name,
				})
			}
		}
	}, [posthog, user])

	// Modify `x-project-id` when we change project
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (user?.id) {
			const id = user?.id ?? undefined // TODO is this the right way to get the user id?

			// Save projectID in cookie
			if (projectId) {
				GtagService.createUserEvent(
					{
						name: 'switch-project',
						...(projectId && {
							projectId: String(projectId),
						}),
						userId: id,
					},
					posthog,
				)
			}
		}
	}, [posthog, projectId, user?.id])

	// If mobile size then show under construction
	// ----------------------------------------------------------------------------
	// if (!['empty', 'default', 'playground'].includes(props.layout)) {
	// 	return (
	// 		<Box height={'100vh'}>
	// 			<StartonUnderConstructionPage />
	// 		</Box>
	// 	)
	// }

	// Get current layout
	// ----------------------------------------------------------------------------
	const CurrentLayout =
		LAYOUT_MAPPING?.[props?.layout ?? 'default'] ??
		((children: React.ReactNode) => <React.Fragment>{children}</React.Fragment>)

	// Render layout
	// ----------------------------------------------------------------------------
	if (['public', 'auth'].includes(props.layout)) {
		return (
			<CurrentLayout>
				{/*<DevTools />*/}
				<Toaster />
				{inputGlobalStyles}
				{props.children}
			</CurrentLayout>
		)
	}

	return (
		<WagmiProvider>
			<CurrentLayout>
				{/*<DevTools />*/}
				<Toaster />
				{inputGlobalStyles}
				{props.children}
			</CurrentLayout>
		</WagmiProvider>
	)
}
