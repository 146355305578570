/*
| Developed by Dirupt
| Filename : useLocalStorage.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { LocalStorageName } from '@/services/localStorage/localStorage.service'

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/
export const getStorage = (key: string) => {
	let value = null

	try {
		const result = window.localStorage.getItem(key)

		if (result) {
			value = JSON.parse(result)
		}
	} catch (error) {
		console.error(error)
	}

	return value
}

export const setStorage = (key: string, value: any) => {
	try {
		window.localStorage.setItem(key, JSON.stringify(value))
	} catch (error) {
		console.error(error)
	}
}

export const removeStorage = (key: string) => {
	try {
		window.localStorage.removeItem(key)
	} catch (error) {
		console.error(error)
	}
}

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export function useLocalStorage(key: LocalStorageName, initialState: any) {
	const [state, setState] = React.useState(initialState)

	// Restore state from local storage
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		const restored = getStorage(key)

		if (restored) {
			setState((prevValue: any) => ({
				...prevValue,
				...restored,
			}))
		}
	}, [key])

	// Update value
	// ----------------------------------------------------------------------------
	const update = React.useCallback(
		(updateValue: any, doNotSave: boolean = false) => {
			setState((prevValue: any) => {
				if (!doNotSave) {
					console.log('[useLocalStorage] update', key)
					setStorage(key, {
						...prevValue,
						...updateValue,
					})
				}

				return {
					...prevValue,
					...updateValue,
				}
			})
		},
		[key],
	)

	// Reset state
	// ----------------------------------------------------------------------------
	const reset = React.useCallback(() => {
		removeStorage(key)
		setState(initialState)
	}, [initialState, key])

	// Return
	// ----------------------------------------------------------------------------
	return {
		state,
		update,
		reset,
	}
}
