/*
| Developed by Starton
| Filename: SettingsSocialConnections.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Box, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { AxiosError } from 'axios'
import { GitHub, Google } from '@mui/icons-material'
import { useWhoami } from '@/services/auth-api/endpoints/users'
import { passwordUpdate } from '@/services/auth-api/endpoints/password'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SettingsSocialConnectionsProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SettingsSocialConnections: React.FC<SettingsSocialConnectionsProps> = () => {
	const { t } = useTranslation()
	// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
	const [emailSent, setEmailSent] = React.useState(false)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
	const [isSubmitting, setIsSubmitting] = React.useState(false)
	// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
	const [error, setError] = React.useState('')
	const { data: user } = useWhoami()

	// Methods
	//--------------------------------------------------------------------------
	// eslint-disable-next-line @typescript-eslint/no-unused-vars,unused-imports/no-unused-vars
	const handleResetPassword = async () => {
		setEmailSent(false)
		setIsSubmitting(true)
		try {
			await passwordUpdate()
			setEmailSent(true)
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.response?.status === 429) {
					setError(t('auth/settings:password.emailAlreadySent'))
					setIsSubmitting(false)
					return
				}
			}
			setError(t('auth/settings:password.unknownError'))
		}
		setIsSubmitting(false)
	}

	// Render
	//--------------------------------------------------------------------------
	if (!user?.connections.includes('google') && !user?.connections.includes('github')) return null

	return (
		<Box display="flex" flexDirection="column" alignItems="flex-start" gap={2}>
			<Box display="flex" flexDirection="column" alignItems="flex-start">
				<Typography variant={'h2'}>{t('auth/settings:socialLogins.title')}</Typography>
				<Typography variant="body2" color="text.secondary" whiteSpace="pre-line">
					{t('auth/settings:socialLogins.description')}
				</Typography>
			</Box>
			{user?.connections.includes('google') ? (
				<Box display="flex" alignItems="center" gap={1}>
					<Google />
					<Typography variant="body2">{t('auth/settings:socialLogins.googleLinked')}</Typography>
				</Box>
			) : null}
			{user?.connections.includes('github') ? (
				<Box display="flex" alignItems="center" gap={1}>
					<GitHub />
					<Typography variant="body2">{t('auth/settings:socialLogins.githubLinked')}</Typography>
				</Box>
			) : null}

			{/*{!user?.connections.includes('google') ? (*/}
			{/*	<Link href={UrlsConfig.googleRedirect}>*/}
			{/*		<StartonButton*/}
			{/*			startIcon={<Google />}*/}
			{/*			onClick={handleResetPassword}*/}
			{/*			variant="contained"*/}
			{/*			disabled={isSubmitting}*/}
			{/*		>*/}
			{/*			{t('auth/settings:socialLogins.linkGoogle')}*/}
			{/*		</StartonButton>*/}
			{/*	</Link>*/}
			{/*) : null}*/}
			{/*{!user?.connections.includes('github') ? (*/}
			{/*	<Link href={UrlsConfig.githubRedirect}>*/}
			{/*		<StartonButton*/}
			{/*			startIcon={<GitHub />}*/}
			{/*			onClick={handleResetPassword}*/}
			{/*			variant="contained"*/}
			{/*			disabled={isSubmitting}*/}
			{/*		>*/}
			{/*			{t('auth/settings:socialLogins.linkGithub')}*/}
			{/*		</StartonButton>*/}
			{/*	</Link>*/}
			{/*) : null}*/}
		</Box>
	)
}
