/*
| Developed by Starton
| Filename: PGDevMode.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { CodeBlock } from 'react-code-blocks'
import { Box, styled, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useAppSelector } from '../../../../../stores/hooks'
import { selectPlaygroundDevMode } from '../../../../../stores/playground/playgroundSlice'
import { PGDevModeTheme } from './PGDevModeTheme'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface PGDevModeProps {
	code?: string
}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const pgDevModeExampleCode = `
import axios from "axios";

export const createWallet = async (it: number = 1, ms: number = 500) => {
 try {
  for (let i = 0; i < it; i++) {
   const wallet = axios.post(
    "https://api.starton-staging.com/v3/kms/wallet",
    {
     name: "the first production vault.",
     description: "Created vault test",
     kmsId: "kms_33333333333333333333333333333333",
    },
    {
    headers: {
     "x-api-key": "YOUR_KEY"
    },
   },
  ).then(wallet => {console.log(wallet.data)}).catch(e=>console.log(e.response.data))
   await new Promise(f=>setTimeout(f, ms))
  }
 } catch (error) {
  console.log(error)
 }
}

createWallet(1, 200).then().catch(e=>console.log(e))
`

export const pgDevModeExampleCodes = [
	`import axios from "axios";
const startonApi = axios.create({
	baseUrl: "https://api.starton.com/v3"
})`,
	`export const createWallet = async (it: number = 1, ms: number = 500) => {
 try {
  for (let i = 0; i < it; i++) {
   const wallet = axios.post(
    "https://api.starton-staging.com/v3/kms/wallet",
    {
     name: "the first production vault.",
     description: "Created vault test",
     kmsId: "kms_33333333333333333333333333333333",
    },
    {
    headers: {
     "x-api-key": "YOUR_KEY"
    },
   },
  ).then(wallet => {console.log(wallet.data)}).catch(e=>console.log(e.response.data))
   await new Promise(f=>setTimeout(f, ms))
  }
 } catch (error) {
  console.log(error)
 }
}`,
	`createWallet(1, 200).then().catch(e=>console.log(e))`,
]

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const CodeContainerStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	maxWidth: 640,
	'& span': {
		fontWeight: `400 !important`,
		...theme.typography.caption,
		lineHeight: 0.1,
	},

	'& span.token': {
		fontWeight: `400 !important`,
	},
	'& code': {
		padding: theme.spacing(2) + '!important',
		width: '100%',
		// padding: '0px !important',
		height: 'fit-content !important',
		// overflow: 'hidden !important',
		backgroundColor: 'transparent',
		transition: theme.transitions.create(['backgroundColor']) + '!important',
		span: {
			transition: theme.transitions.create(['backgroundColor']),
		},
		'&:hover': {
			// backgroundColor: theme.palette.primary.hover,
			// span: {
			// 	background: 'transparent !important',
			// },
		},
	},
	// padding: theme.spacing(2),
	backgroundColor: theme.palette.background.paper2,
}))

const CodeHeaderStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	borderBottomWidth: 1,
	borderBottomStyle: 'solid',
	borderBottomColor: theme.palette.divider,
	overflow: 'auto',
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PGDevMode: React.FC<PGDevModeProps> = ({ code }) => {
	const devModeData = useAppSelector(selectPlaygroundDevMode)

	// Render
	//--------------------------------------------------------------------------
	return (
		<CodeContainerStyled>
			<CodeHeaderStyled>
				<Box />
				<Typography color="text.secondary" variant="caption" paddingX={1} paddingY={2}>
					Node.js
				</Typography>
			</CodeHeaderStyled>
			<Box display="flex" overflow="auto" maxHeight="calc(100vh - 57px - 56px - 35px)" width="100%">
				<motion.div initial={{ height: 0 }} animate={{ height: 'auto' }} style={{ width: '100%' }}>
					<CodeBlock
						text={code ?? devModeData.code}
						language="ts"
						// wrapLongLines
						showLineNumbers={false}
						theme={PGDevModeTheme}
					/>
				</motion.div>
			</Box>
		</CodeContainerStyled>
	)
}
