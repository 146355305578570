// GET /whoami
//--------------------------------------------------------------------------
import { AxiosRequestConfig } from 'axios'
import useSWR from 'swr'
import { authApi } from '../auth-api-instance'
import { User } from '../contracts/users'

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const AUTH_API_WHOAMI = '/whoami'

/*
|--------------------------------------------------------------------------
| Endpoints & hooks
|--------------------------------------------------------------------------
*/
export const whoami = (options?: AxiosRequestConfig) => {
	return authApi<User>({ url: AUTH_API_WHOAMI, method: 'GET' }, options)
}

export const useWhoami = () => {
	return useSWR<Awaited<ReturnType<typeof whoami>>>(AUTH_API_WHOAMI, () => whoami())
}

// PATCH /users/me
//--------------------------------------------------------------------------
export const usersUpdateMe = (data: Partial<User>) => {
	return authApi<User>({ url: '/users/me', method: 'PATCH', data })
}

// POST /users/verify
//--------------------------------------------------------------------------
export const usersVerify = (data: { token: string }) => {
	return authApi({ url: '/users/verify', method: 'POST', data })
}

// POST /users/verify/code
// ----------------------------------------------------------------------------
export const usersVerifyCode = (data: { email: string; code: number }) => {
	return authApi({ url: '/users/verify/code', method: 'POST', data })
}

// POST /users/verify/resend
//--------------------------------------------------------------------------
export const usersVerifyResend = (data: { email: string }) => {
	return authApi({ url: '/users/verify/resend', method: 'POST', data })
}

// POST /users/update-email
//--------------------------------------------------------------------------
export const usersUpdateEmail = (data: { newEmail: string }) => {
	return authApi({ url: '/users/update-email', method: 'POST', data })
}
