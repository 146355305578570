import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  ApiKeyCreate201
} from '../../model/apiKeyCreate201'
import type {
  ApiKeyGetAll200
} from '../../model/apiKeyGetAll200'
import type {
  ApiKeyGetAllParams
} from '../../model/apiKeyGetAllParams'
import type {
  BadRequestException
} from '../../model/badRequestException'
import type {
  BillingAccount
} from '../../model/billingAccount'
import type {
  BillingAccountGetAll200
} from '../../model/billingAccountGetAll200'
import type {
  BillingAccountGetAllParams
} from '../../model/billingAccountGetAllParams'
import type {
  ChangePlanDto
} from '../../model/changePlanDto'
import type {
  ClaimInvitationDto
} from '../../model/claimInvitationDto'
import type {
  ComputeUnitPerDay200
} from '../../model/computeUnitPerDay200'
import type {
  ComputeUnitPerDayParams
} from '../../model/computeUnitPerDayParams'
import type {
  ComputeUnitPerMonthParams
} from '../../model/computeUnitPerMonthParams'
import type {
  ComputeUnitPerServiceThisMonth200
} from '../../model/computeUnitPerServiceThisMonth200'
import type {
  CouldNotFindResource
} from '../../model/couldNotFindResource'
import type {
  CreateApiKeyDto
} from '../../model/createApiKeyDto'
import type {
  CreateBillingAccountDto
} from '../../model/createBillingAccountDto'
import type {
  CreateProjectDto
} from '../../model/createProjectDto'
import type {
  MonthlyUsage
} from '../../model/monthlyUsage'
import type {
  NotAuthenticated
} from '../../model/notAuthenticated'
import type {
  Project
} from '../../model/project'
import type {
  ProjectGetAll200
} from '../../model/projectGetAll200'
import type {
  ProjectGetAllParams
} from '../../model/projectGetAllParams'
import type {
  StripeConsumerDto
} from '../../model/stripeConsumerDto'
import type {
  StripeCreatePortal200
} from '../../model/stripeCreatePortal200'
import type {
  StripeCustomerGet200
} from '../../model/stripeCustomerGet200'
import type {
  StripeGetPaymentMethod200
} from '../../model/stripeGetPaymentMethod200'
import type {
  StripePreviewSubscriptionChange201
} from '../../model/stripePreviewSubscriptionChange201'
import type {
  StripeSubscriptionCancel201
} from '../../model/stripeSubscriptionCancel201'
import type {
  StripeSubscriptionReactivate201
} from '../../model/stripeSubscriptionReactivate201'
import type {
  StripeSubscriptionUpgrade201
} from '../../model/stripeSubscriptionUpgrade201'
import type {
  StripeTaxDelete200
} from '../../model/stripeTaxDelete200'
import type {
  StripeTaxGet200
} from '../../model/stripeTaxGet200'
import type {
  SubscriptionUpgradeDto
} from '../../model/subscriptionUpgradeDto'
import type {
  UpdateBillingAccountDto
} from '../../model/updateBillingAccountDto'
import type {
  UpdateProjectDto
} from '../../model/updateProjectDto'
import { axiosCustomInstance } from '../../../mutators/axios-custom-instance';
import type { ErrorType } from '../../../mutators/axios-custom-instance';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const stripeCustomerUpdate = (
    stripeConsumerDto: StripeConsumerDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<void>(
      {url: `/v3/stripe/customer`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: stripeConsumerDto
    },
      options);
    }
  

export const stripeCustomerGet = (
    
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripeCustomerGet200>(
      {url: `/v3/stripe/customer`, method: 'GET'
    },
      options);
    }
  

export const getStripeCustomerGetKey = () => [`/v3/stripe/customer`] as const;

    
export type StripeCustomerGetQueryResult = NonNullable<Awaited<ReturnType<typeof stripeCustomerGet>>>
export type StripeCustomerGetQueryError = ErrorType<NotAuthenticated>

export const useStripeCustomerGet = <TError = ErrorType<NotAuthenticated>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof stripeCustomerGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getStripeCustomerGetKey() : null);
  const swrFn = () => stripeCustomerGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const stripeTaxGet = (
    
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripeTaxGet200>(
      {url: `/v3/stripe/tax`, method: 'GET'
    },
      options);
    }
  

export const getStripeTaxGetKey = () => [`/v3/stripe/tax`] as const;

    
export type StripeTaxGetQueryResult = NonNullable<Awaited<ReturnType<typeof stripeTaxGet>>>
export type StripeTaxGetQueryError = ErrorType<NotAuthenticated>

export const useStripeTaxGet = <TError = ErrorType<NotAuthenticated>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof stripeTaxGet>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getStripeTaxGetKey() : null);
  const swrFn = () => stripeTaxGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const stripeTaxDelete = (
    id: string,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripeTaxDelete200>(
      {url: `/v3/stripe/tax/${id}`, method: 'DELETE'
    },
      options);
    }
  

export const stripeSubscriptionUpgrade = (
    subscriptionUpgradeDto: SubscriptionUpgradeDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripeSubscriptionUpgrade201>(
      {url: `/v3/stripe/subscription/upgrade`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionUpgradeDto
    },
      options);
    }
  

export const stripeSubscriptionReactivate = (
    
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripeSubscriptionReactivate201>(
      {url: `/v3/stripe/subscription/reactivate`, method: 'POST'
    },
      options);
    }
  

export const stripeSubscriptionCancel = (
    
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripeSubscriptionCancel201>(
      {url: `/v3/stripe/subscription/cancel`, method: 'POST'
    },
      options);
    }
  

export const stripePreviewSubscriptionChange = (
    subscriptionUpgradeDto: SubscriptionUpgradeDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripePreviewSubscriptionChange201>(
      {url: `/v3/stripe/subscription/preview`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: subscriptionUpgradeDto
    },
      options);
    }
  

export const stripeCreatePortal = (
    
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripeCreatePortal200>(
      {url: `/v3/stripe/portal`, method: 'GET'
    },
      options);
    }
  

export const getStripeCreatePortalKey = () => [`/v3/stripe/portal`] as const;

    
export type StripeCreatePortalQueryResult = NonNullable<Awaited<ReturnType<typeof stripeCreatePortal>>>
export type StripeCreatePortalQueryError = ErrorType<NotAuthenticated>

export const useStripeCreatePortal = <TError = ErrorType<NotAuthenticated>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof stripeCreatePortal>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getStripeCreatePortalKey() : null);
  const swrFn = () => stripeCreatePortal(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const stripeGetPaymentMethod = (
    
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<StripeGetPaymentMethod200>(
      {url: `/v3/stripe/payment-method`, method: 'GET'
    },
      options);
    }
  

export const getStripeGetPaymentMethodKey = () => [`/v3/stripe/payment-method`] as const;

    
export type StripeGetPaymentMethodQueryResult = NonNullable<Awaited<ReturnType<typeof stripeGetPaymentMethod>>>
export type StripeGetPaymentMethodQueryError = ErrorType<NotAuthenticated>

export const useStripeGetPaymentMethod = <TError = ErrorType<NotAuthenticated>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof stripeGetPaymentMethod>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getStripeGetPaymentMethodKey() : null);
  const swrFn = () => stripeGetPaymentMethod(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary projectGetAll
 */
export const projectGetAll = (
    params?: ProjectGetAllParams,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<ProjectGetAll200>(
      {url: `/v3/project`, method: 'GET',
        params
    },
      options);
    }
  

export const getProjectGetAllKey = (params?: ProjectGetAllParams,) => [`/v3/project`, ...(params ? [params]: [])] as const;

    
export type ProjectGetAllQueryResult = NonNullable<Awaited<ReturnType<typeof projectGetAll>>>
export type ProjectGetAllQueryError = ErrorType<BadRequestException | NotAuthenticated>

/**
 * @summary projectGetAll
 */
export const useProjectGetAll = <TError = ErrorType<BadRequestException | NotAuthenticated>>(
 params?: ProjectGetAllParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof projectGetAll>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getProjectGetAllKey(params) : null);
  const swrFn = () => projectGetAll(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const projectCreate = (
    createProjectDto: CreateProjectDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<Project>(
      {url: `/v3/project`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createProjectDto
    },
      options);
    }
  

export const projectGetOne = (
    id: string,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<Project>(
      {url: `/v3/project/${id}`, method: 'GET'
    },
      options);
    }
  

export const getProjectGetOneKey = (id: string,) => [`/v3/project/${id}`] as const;

    
export type ProjectGetOneQueryResult = NonNullable<Awaited<ReturnType<typeof projectGetOne>>>
export type ProjectGetOneQueryError = ErrorType<BadRequestException | NotAuthenticated | CouldNotFindResource>

export const useProjectGetOne = <TError = ErrorType<BadRequestException | NotAuthenticated | CouldNotFindResource>>(
 id: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof projectGetOne>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getProjectGetOneKey(id) : null);
  const swrFn = () => projectGetOne(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const projectUpdate = (
    id: string,
    updateProjectDto: UpdateProjectDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<Project>(
      {url: `/v3/project/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateProjectDto
    },
      options);
    }
  

export const projectChangePlan = (
    changePlanDto: ChangePlanDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<Project>(
      {url: `/v3/project/change-plan`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: changePlanDto
    },
      options);
    }
  

export const billingAccountGetAll = (
    params?: BillingAccountGetAllParams,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<BillingAccountGetAll200>(
      {url: `/v3/billing-account`, method: 'GET',
        params
    },
      options);
    }
  

export const getBillingAccountGetAllKey = (params?: BillingAccountGetAllParams,) => [`/v3/billing-account`, ...(params ? [params]: [])] as const;

    
export type BillingAccountGetAllQueryResult = NonNullable<Awaited<ReturnType<typeof billingAccountGetAll>>>
export type BillingAccountGetAllQueryError = ErrorType<BadRequestException | NotAuthenticated>

export const useBillingAccountGetAll = <TError = ErrorType<BadRequestException | NotAuthenticated>>(
 params?: BillingAccountGetAllParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof billingAccountGetAll>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBillingAccountGetAllKey(params) : null);
  const swrFn = () => billingAccountGetAll(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const billingAccountCreate = (
    createBillingAccountDto: CreateBillingAccountDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<BillingAccount>(
      {url: `/v3/billing-account`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createBillingAccountDto
    },
      options);
    }
  

export const billingAccountGetOne = (
    id: string,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<BillingAccount>(
      {url: `/v3/billing-account/${id}`, method: 'GET'
    },
      options);
    }
  

export const getBillingAccountGetOneKey = (id: string,) => [`/v3/billing-account/${id}`] as const;

    
export type BillingAccountGetOneQueryResult = NonNullable<Awaited<ReturnType<typeof billingAccountGetOne>>>
export type BillingAccountGetOneQueryError = ErrorType<BadRequestException | NotAuthenticated | CouldNotFindResource>

export const useBillingAccountGetOne = <TError = ErrorType<BadRequestException | NotAuthenticated | CouldNotFindResource>>(
 id: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof billingAccountGetOne>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(id)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getBillingAccountGetOneKey(id) : null);
  const swrFn = () => billingAccountGetOne(id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const billingAccountUpdate = (
    id: string,
    updateBillingAccountDto: UpdateBillingAccountDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<BillingAccount>(
      {url: `/v3/billing-account/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateBillingAccountDto
    },
      options);
    }
  

export const billingAccountDelete = (
    id: string,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<number>(
      {url: `/v3/billing-account/${id}`, method: 'DELETE'
    },
      options);
    }
  

export const computeUnitPerDay = (
    params?: ComputeUnitPerDayParams,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<ComputeUnitPerDay200>(
      {url: `/v3/compute-unit/per-day`, method: 'GET',
        params
    },
      options);
    }
  

export const getComputeUnitPerDayKey = (params?: ComputeUnitPerDayParams,) => [`/v3/compute-unit/per-day`, ...(params ? [params]: [])] as const;

    
export type ComputeUnitPerDayQueryResult = NonNullable<Awaited<ReturnType<typeof computeUnitPerDay>>>
export type ComputeUnitPerDayQueryError = ErrorType<BadRequestException | NotAuthenticated>

export const useComputeUnitPerDay = <TError = ErrorType<BadRequestException | NotAuthenticated>>(
 params?: ComputeUnitPerDayParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof computeUnitPerDay>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getComputeUnitPerDayKey(params) : null);
  const swrFn = () => computeUnitPerDay(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const computeUnitUsedThisMonth = (
    
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<number>(
      {url: `/v3/compute-unit/used-this-month`, method: 'GET'
    },
      options);
    }
  

export const getComputeUnitUsedThisMonthKey = () => [`/v3/compute-unit/used-this-month`] as const;

    
export type ComputeUnitUsedThisMonthQueryResult = NonNullable<Awaited<ReturnType<typeof computeUnitUsedThisMonth>>>
export type ComputeUnitUsedThisMonthQueryError = ErrorType<NotAuthenticated | CouldNotFindResource>

export const useComputeUnitUsedThisMonth = <TError = ErrorType<NotAuthenticated | CouldNotFindResource>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof computeUnitUsedThisMonth>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getComputeUnitUsedThisMonthKey() : null);
  const swrFn = () => computeUnitUsedThisMonth(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const computeUnitPerMonth = (
    params?: ComputeUnitPerMonthParams,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<MonthlyUsage[]>(
      {url: `/v3/compute-unit/per-month`, method: 'GET',
        params
    },
      options);
    }
  

export const getComputeUnitPerMonthKey = (params?: ComputeUnitPerMonthParams,) => [`/v3/compute-unit/per-month`, ...(params ? [params]: [])] as const;

    
export type ComputeUnitPerMonthQueryResult = NonNullable<Awaited<ReturnType<typeof computeUnitPerMonth>>>
export type ComputeUnitPerMonthQueryError = ErrorType<NotAuthenticated | CouldNotFindResource>

export const useComputeUnitPerMonth = <TError = ErrorType<NotAuthenticated | CouldNotFindResource>>(
 params?: ComputeUnitPerMonthParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof computeUnitPerMonth>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getComputeUnitPerMonthKey(params) : null);
  const swrFn = () => computeUnitPerMonth(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

export const computeUnitPerServiceThisMonth = (
    
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<ComputeUnitPerServiceThisMonth200>(
      {url: `/v3/compute-unit/per-service-this-month`, method: 'GET'
    },
      options);
    }
  

export const getComputeUnitPerServiceThisMonthKey = () => [`/v3/compute-unit/per-service-this-month`] as const;

    
export type ComputeUnitPerServiceThisMonthQueryResult = NonNullable<Awaited<ReturnType<typeof computeUnitPerServiceThisMonth>>>
export type ComputeUnitPerServiceThisMonthQueryError = ErrorType<NotAuthenticated>

export const useComputeUnitPerServiceThisMonth = <TError = ErrorType<NotAuthenticated>>(
  options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof computeUnitPerServiceThisMonth>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getComputeUnitPerServiceThisMonthKey() : null);
  const swrFn = () => computeUnitPerServiceThisMonth(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Claim an invitation (internal).
 * @summary Claim an invitation (internal)
 */
export const claimInvitation = (
    claimInvitationDto: ClaimInvitationDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<boolean>(
      {url: `/v3/project-member/invitation/claim`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: claimInvitationDto
    },
      options);
    }
  

export const apiKeyGetAll = (
    params?: ApiKeyGetAllParams,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<ApiKeyGetAll200>(
      {url: `/v3/api-key`, method: 'GET',
        params
    },
      options);
    }
  

export const getApiKeyGetAllKey = (params?: ApiKeyGetAllParams,) => [`/v3/api-key`, ...(params ? [params]: [])] as const;

    
export type ApiKeyGetAllQueryResult = NonNullable<Awaited<ReturnType<typeof apiKeyGetAll>>>
export type ApiKeyGetAllQueryError = ErrorType<BadRequestException>

export const useApiKeyGetAll = <TError = ErrorType<BadRequestException>>(
 params?: ApiKeyGetAllParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof apiKeyGetAll>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getApiKeyGetAllKey(params) : null);
  const swrFn = () => apiKeyGetAll(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * @summary apiKeyCreate
 */
export const apiKeyCreate = (
    createApiKeyDto: CreateApiKeyDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<ApiKeyCreate201>(
      {url: `/v3/api-key`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createApiKeyDto
    },
      options);
    }
  

/**
 * @summary apiKeyDelete
 */
export const apiKeyDelete = (
    id: string,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<number>(
      {url: `/v3/api-key/${id}`, method: 'DELETE'
    },
      options);
    }
  

