/*
| Developed by Starton
| Filename : methods.yup.ts
| Author : Calixte De Tourris (calixte@starton.com)
*/

import * as Yup from 'yup'

/*
|--------------------------------------------------------------------------
| ETH Address
|--------------------------------------------------------------------------
*/
Yup.addMethod(Yup.string, 'ethAddress', function (props) {
	const message = props?.message ?? 'Invalid ETH address format'

	return this.test('is-eth-address', message, function (value) {
		return value ? /^0x[a-fA-F0-9]{40}$/.test(value) : false
	})
})

/*
|--------------------------------------------------------------------------
| String number for (uint for example)
|--------------------------------------------------------------------------
*/
Yup.addMethod(Yup.string, 'stringInteger', function (props) {
	const message = props?.message ?? 'Invalid format, you can only use alphanumeric char (0-9).'

	return this.test('is-string-integer', message, function (value) {
		return value ? /^\d*$/.test(value) : false
	})
})

Yup.addMethod(Yup.string, 'stringFloat', function (props) {
	const message = props?.message ?? 'Invalid format, you can only use alphanumeric char with virgule (0-9).'

	return this.test('is-string-float', message, function (value) {
		return value ? /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value) : false
	})
})
