/*
| Developed by Starton
| Filename : store.ts
*/

import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'
import commonReducer from './common/commonSlice'
import watcherReducer from './watcher/watcherSlice'
import networksReducer from './networks/networksSlice'
import playgroundReducer from './playground/playgroundSlice'
import tipsReducer from './tips/tipsSlice'

export const store = configureStore({
	reducer: {
		common: commonReducer,
		watcher: watcherReducer,
		networks: networksReducer,
		tips: tipsReducer,
		playground: playgroundReducer,
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
