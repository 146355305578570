/*
| Developed by Starton
| Filename : typographies.ts
| Author : Calixte De Tourris (calixte@starton.io)
*/

import { styled, Typography, TypographyProps } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
export const TypographyTableText = styled(Typography)(({ theme }) => ({
	...theme.typography.table.text,
}))

export const TypographyInputText = styled(({ ...props }: TypographyProps) => {
	return <Typography component="span" {...props} />
})(({ theme }) => ({
	...theme.typography.input.label,
}))

export const TypographyInputLabel = styled(Typography)(({ theme }) => ({
	...theme.typography.input.label,
}))
