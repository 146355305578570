/*
| Developed by Starton
| Filename : EmptyTemplate.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { useRouter } from 'next/router'
import { Box, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { LayoutTemplateProps } from '../layout-template.contract'
import { StartonLoadingScreen } from '../../../../../common'
import { projectUrl } from '@/config/urls/project-builder'
import { useNetworksLoad } from '@/hooks/networks/networksHooks'
import { useTipsLoad } from '@/hooks/tips/tips'
import { useAppDispatch, useAppSelector } from '@/stores/hooks'
import { playgroundReset, selectPlaygroundShowDevMode } from '@/stores/playground/playgroundSlice'
import { SupportDialog } from '@/components/dialogs/SupportDialog'
import { PGNavbar } from '@/components/pages/playground/lib/PGNavbar'
import { PGDevMode } from '@/components/pages/playground/lib/PGDevMode'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const PlaygroundTemplate: React.FC<LayoutTemplateProps> = (props) => {
	const { t } = useTranslation()
	const router = useRouter()
	const { projectId } = router.query
	const showDevMode = useAppSelector(selectPlaygroundShowDevMode)
	const dispatch = useAppDispatch()

	// Effects
	//--------------------------------------------------------------------------
	// Set project ID for project urls
	React.useEffect(() => {
		if (projectId) {
			projectUrl.setProjectId(String(projectId))
		}
	}, [projectId])

	// Automatic playground reset on home page
	React.useEffect(() => {
		if (!router.pathname.includes('exercises')) {
			dispatch(playgroundReset())
		}
	}, [dispatch, router.pathname])

	// Loading data to be cached
	//--------------------------------------------------------------------------
	useTipsLoad()
	const { isLoaded } = useNetworksLoad(projectId)

	// Memoization
	//--------------------------------------------------------------------------
	const { navbarVariant } = React.useMemo(() => {
		// Automatically set navbar variant for home or exercise
		return {
			navbarVariant: router.pathname.includes('exercises') ? 'navigation' : 'logo',
		}
	}, [router.pathname])

	// Guardian
	// ----------------------------------------------------------------------------
	if (!router.isReady || !projectId || !isLoaded) {
		return (
			<Box height={'100vh'}>
				<StartonLoadingScreen loadingIcon={'icon'} text={false} />
			</Box>
		)
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<main id={'starton-template-empty'}>
			<SupportDialog />
			<PGNavbar variant={navbarVariant as 'navigation' | 'logo'} />
			<Box display="flex" width="100%">
				{props.children}
				{showDevMode ? (
					<Box
						sx={{ backgroundColor: 'background.paper' }}
						width="100%"
						minHeight="calc(100vh - 57px)"
						maxWidth={600}
						paddingX={2}
						paddingBottom={3}
					>
						<Typography variant="h4" paddingY={2}>
							{t('playground:devMode.title')}
						</Typography>
						<PGDevMode />
					</Box>
				) : null}
			</Box>
		</main>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
PlaygroundTemplate.displayName = 'PlaygroundTemplate'
