/*
| Developed by Starton
| Filename : useTips.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import useSwr from 'swr'
import { FAPIGetParams, SecondAxiosParameter, TipDTO } from '../contracts'
import { fapiAxiosInstance } from '../fapi-instance'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface UseTipsParams extends FAPIGetParams {}

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const FAPI_MAINTENANCES = '/api/tips?populate=*'

/*
|--------------------------------------------------------------------------
| Hook
|--------------------------------------------------------------------------
*/
export const maintenancesGetAll = (
	params?: UseTipsParams,
	options?: SecondAxiosParameter<typeof fapiAxiosInstance>,
) => {
	return fapiAxiosInstance<TipDTO>(
		{
			url: FAPI_MAINTENANCES,
			method: 'GET',
			params: params,
		},
		options,
	)
}
export const useTips = (params?: UseTipsParams) => {
	return useSwr<Awaited<TipDTO>>(FAPI_MAINTENANCES, () => maintenancesGetAll(params), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		refreshInterval: 3600000, // 1 hour
		revalidateOnMount: true,
		revalidateOnReconnect: false,
		refreshWhenOffline: false,
		refreshWhenHidden: false,
		errorRetryCount: 0,
		shouldRetryOnError: false,
	})
}
