/*
| Developed by Starton
| Filename : StartonNumericFormat.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import { NumericFormat, NumericFormatProps } from 'react-number-format'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonNumericFormatProps {
	onChange: (event: { target: { name: string; value: string } }) => void
	name: string
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonNumericFormat = React.forwardRef<NumericFormatProps, StartonNumericFormatProps>(
	function NumericFormatCustom(props, ref) {
		const { onChange, ...other } = props

		return (
			<NumericFormat
				{...other}
				getInputRef={ref}
				onValueChange={(values) => {
					onChange({
						target: {
							name: props.name,
							value: values.value,
						},
					})
				}}
				thousandSeparator={' '}
				valueIsNumericString
			/>
		)
	},
)
