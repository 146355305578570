/*
| Developed by Starton
| Filename : SettingsContent.tsx
| Author : Calixte DE TOURRIS (calixte@starton.com)
*/

import React from 'react'
import { Box, BoxProps, styled, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import Trans from 'next-translate/Trans'
import { SettingsProfile } from '../SettingsProfile'
import { SettingsPassword } from '../SettingsPassword'
import { SettingsSocialConnections } from '../SettingsSocialConnections'
import { User } from '@/services/auth-api/contracts/users'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SettingsContentProps {
	user: User
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/

const SettingsContainer = styled(Box)<BoxProps>(({ theme }) => ({
	padding: theme.spacing(6),
	backgroundColor: theme.palette.background.default,
	flexGrow: 1,
	height: `100vh`,
	overflow: 'auto',
}))

export const HighlightText = styled('span')(({ theme }) => ({
	color: theme.palette.primary.main,
}))

export const SettingCard = styled(Box)<BoxProps>(({ theme }) => ({
	marginBottom: theme.spacing(8),
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SettingsContent: React.FC<SettingsContentProps> = ({ user }) => {
	const { t } = useTranslation()

	// Render
	// ----------------------------------------------------------------------------
	return (
		<SettingsContainer>
			<SettingCard>
				<Typography variant={'h2'} marginBottom={1}>
					{t('auth/settings:page.title')}
				</Typography>
				<Typography variant={'body2'} sx={{ whiteSpace: 'pre-line' }}>
					<Trans
						i18nKey={'auth/settings:page.description'}
						components={[<HighlightText key={0} />, <br key={1} />]}
						values={{ email: user.email }}
					/>
				</Typography>
			</SettingCard>

			<Box display="flex" flexDirection="column" gap={4}>
				<SettingsProfile />
				{/*<SettingsEmail />*/}
				<SettingsPassword />
				<SettingsSocialConnections />
			</Box>
		</SettingsContainer>
	)
}

/*
|--------------------------------------------------------------------------
| Component configurations
|--------------------------------------------------------------------------
*/
SettingsContent.displayName = 'SettingsContent'
