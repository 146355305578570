/*
| Developed by Dirupt
| Filename : whitelabel.provider.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { isEqual } from 'lodash'
import { useRouter } from 'next/router'
import {
	WhiteLabelConfiguration,
	WhiteLabelContext,
	WhiteLabelContextProps,
} from '@/components/white-labelling/context/whitelabel.context'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { platformGetOne } from '@/services/fapi/hooks/usePlatform'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface WhiteLabelProviderProps {
	children: React.ReactNode
	defaultConfiguration: WhiteLabelConfiguration
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const WhiteLabelProvider: React.FC<WhiteLabelProviderProps> = ({ children, defaultConfiguration }) => {
	const router = useRouter()
	const { state, update, reset } = useLocalStorage('starton.white-label.settings', defaultConfiguration)
	const [openDrawer, setOpenDrawer] = React.useState(false)

	// Fetch platform data if preset is passed and not starton
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (router.query?.preset === 'starton') {
			reset()
			return
		}

		if (!state.isStarton || router.query?.preset) {
			platformGetOne((router.query?.preset as string) ?? state.slug)
				.then((results) => {
					update({
						name: results.data[0].attributes.name,
						slug: results.data[0].attributes.slug,
						colorMode: results.data[0].attributes.colorMode,
						featuredFlags: {
							enablePlayground: results.data[0].attributes.featuredFlags.enablePlayground,
							enableListener: results.data[0].attributes.featuredFlags.enableListener,
							enableRelayer: results.data[0].attributes.featuredFlags.enableRelayer,
							poweredBy: results.data[0].attributes.featuredFlags.poweredBy,
						},
						logo: results.data[0].attributes.logo.data.attributes.url,
						logoType: results.data[0].attributes.logoType.data.attributes.url,
						theme: {
							palette: {
								primary: {
									main: results.data[0].attributes.theme.primary.main,
									dark: results.data[0].attributes.theme.primary.dark,
									light: results.data[0].attributes.theme.primary.light,
									hover: results.data[0].attributes.theme.primary.hover,
									contrastText: results.data[0].attributes.theme.primary.contrastText,
								},
							},
						},
						isStarton: false,
					})
				})
				.catch((error) => {
					console.error(error)
				})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Fetch platform data if subdomain is not starton
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		// Check if window is defined
		if (typeof window !== 'undefined') {
			// Get base path from the window location
			const spaceTenant = window.location.hostname.split('.')[0]

			// If spaceTenant is contain subdomain on app.starton*.com then we need retrieve the slug from the subdomain
			if (spaceTenant && spaceTenant !== 'app') {
				platformGetOne(spaceTenant)
					.then((results) => {
						update({
							name: results.data[0].attributes.name,
							slug: results.data[0].attributes.slug,
							colorMode: results.data[0].attributes.colorMode,
							featuredFlags: {
								enablePlayground: results.data[0].attributes.featuredFlags.enablePlayground,
								enableListener: results.data[0].attributes.featuredFlags.enableListener,
								enableRelayer: results.data[0].attributes.featuredFlags.enableRelayer,
								poweredBy: results.data[0].attributes.featuredFlags.poweredBy,
							},
							logo: results.data[0].attributes.logo.data.attributes.url,
							logoType: results.data[0].attributes.logoType.data.attributes.url,
							theme: {
								palette: {
									primary: {
										main: results.data[0].attributes.theme.primary.main,
										dark: results.data[0].attributes.theme.primary.dark,
										light: results.data[0].attributes.theme.primary.light,
										hover: results.data[0].attributes.theme.primary.hover,
										contrastText: results.data[0].attributes.theme.primary.contrastText,
									},
								},
							},
							isStarton: false,
						})
					})
					.catch((error) => {
						console.error(error)
					})
			} else {
				// If spaceTenant is starton then we need to reset the configuration
				reset()
			}
		}
	}, [])

	// Drawer methods
	// ----------------------------------------------------------------------------
	const onToggleDrawer = React.useCallback(() => {
		setOpenDrawer((prev) => !prev)
	}, [])

	const onCloseDrawer = React.useCallback(() => {
		setOpenDrawer(false)
	}, [])

	// Reset method
	// ----------------------------------------------------------------------------
	const canReset = React.useMemo(() => {
		return !isEqual(state, defaultConfiguration)
	}, [state, defaultConfiguration])

	// Memoized value
	// ----------------------------------------------------------------------------
	const memoizedValue = React.useMemo(
		() =>
			({
				...state,
				defaultConfiguration,
				onUpdate: update,
				canReset,
				onReset: reset,
				open: openDrawer,
				onToggle: onToggleDrawer,
				onClose: onCloseDrawer,
			}) as WhiteLabelContextProps,
		[canReset, defaultConfiguration, onCloseDrawer, onToggleDrawer, openDrawer, reset, state, update],
	)

	// Return provider
	// ----------------------------------------------------------------------------
	return <WhiteLabelContext.Provider value={memoizedValue}>{children}</WhiteLabelContext.Provider>
}
