import theme from '../../../../../styles/mui.theme'

export const PGDevModeTheme = {
	lineNumberColor: theme.palette.text.primary,
	lineNumberBgColor: theme.palette.background.paper2,
	backgroundColor: theme.palette.background.paper2,
	textColor: theme.palette.text.primary,
	substringColor: `blue`,
	keywordColor: theme.palette.dataviz.set1.value6,
	attributeColor: theme.palette.warning.main,
	selectorAttributeColor: `blue`,
	docTagColor: `blue`,
	nameColor: `blue`,
	builtInColor: theme.palette.dataviz.set1.value6,
	literalColor: `red`,
	bulletColor: `blue`,
	codeColor: `blue`,
	additionColor: `blue`,
	regexpColor: `blue`,
	symbolColor: `blue`,
	variableColor: `blue`,
	templateVariableColor: `blue`,
	linkColor: `blue`,
	selectorClassColor: `blue`,
	typeColor: `blue`,
	stringColor: theme.palette.success.main,
	selectorIdColor: `blue`,
	quoteColor: `blue`,
	templateTagColor: theme.palette.error.main,
	deletionColor: `blue`,
	titleColor: `blue`,
	sectionColor: theme.palette.error.main,
	commentColor: theme.palette.divider,
	metaKeywordColor: `blue`,
	metaColor: `blue`,
	functionColor: theme.palette.info.main,
	numberColor: theme.palette.warning.main,
}
