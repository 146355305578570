/*
| Developed by Starton
| Filename : swrKeys.config.ts
| Author : Calixte De Tourris (calixte@starton.com)
*/

import { useRouter } from 'next/router'

/*
|--------------------------------------------------------------------------
| Config
|--------------------------------------------------------------------------
*/
// TODO: Remove SWR Keys from this file and move them to the components that use them
export const SwrKeysConfig = {
	/** Watcher **/
	WATCHER_GET_ALL: 'watcher-get-all',
	WATCHER_GET_ONE: 'watcher-get-one',
	WATCHER_EVENTS_GET_ALL: 'watcher-event-get-all',

	/** Ipfs **/
	IPFS_PINS_GET_ALL: 'ipfs-pins-get-all',
	IPFS_PINS_GET_ONE: 'ipfs-pins-get-one',

	/** Api Key **/
	API_KEY_GET_ALL: 'api-key-get-all',

	/** Projects **/
	PROJECTS_GET_ALL: 'projects-get-all',
	PROJECTS_GET_ONE: 'projects-get-one',
	PROJECTS_GET_ALL_MEMBERS: 'projects-get-all-members',

	/** Smart contract **/
	SMART_CONTRACT_GET_ALL: 'smart-contract-get-all',
	SMART_CONTRACT_GET_ONE: 'smart-contract-get-one',

	/** Transaction **/
	TRANSACTION_GET_ALL: 'transaction-get-all',
	TRANSACTION_GET_ALL_THIS_MONTH: 'transaction-get-all-this-month',

	/** Wallet **/
	WALLET_GET_ALL: 'wallet-get-all',
	WALLET_GET_ONE: 'wallet-get-one',
	WALLET_KMS_GET_ALL: 'wallet-kms-get-all',

	/** Settings **/
	SETTINGS_GET_ALL_KMS: 'settings-invitations-get-all',
	SETTINGS_GET_ALL_MEMBERS: 'settings-members-get-all',
	SETTINGS_GET_ALL_INVITATIONS: 'settings-invitations-get-all',
	SETTINGS_GET_ONE_PROJECT: 'settings-get-one-project',

	/** Usage **/
	IPFS_USED_STORAGE: 'ipfs-used-storage',

	/** Compute units **/
	CU_PER_SERVICE_THIS_MONTH: 'cu-per-service-this-month',
	CU_USAGE: 'cu-usage',
	CU_PER_DAY: 'cu-per-day',
	CU_USED_THIS_MONTH: 'cu-used-this-month',
}

export const projectSpecificSWRKey = (projectId: string, key: keyof typeof SwrKeysConfig) => {
	return `${projectId}-${SwrKeysConfig[key]}`
}

export type StartonSwrKey = keyof typeof SwrKeysConfig

type Value<T extends StartonSwrKey | Array<StartonSwrKey>> = T extends StartonSwrKey ? string : Array<string>

export const useProjectSpecificSWRKey = <KeyType extends StartonSwrKey | Array<StartonSwrKey>>(
	key: KeyType,
): Value<KeyType> => {
	const router = useRouter()
	const { projectId } = router.query
	if (typeof key === 'string') {
		return projectSpecificSWRKey(projectId as string, key) as Value<KeyType>
	}
	return key.map((item) => projectSpecificSWRKey(projectId as string, item)) as Value<KeyType>
}
