/*
| Developed by Starton
| Filename : fapi-instance.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

// eslint-disable-next-line import/no-named-as-default
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import getConfig from 'next/config'
import { FAPI_HOST } from '@/config/common/common.config'

/*
|--------------------------------------------------------------------------
| Get public runtime env
|--------------------------------------------------------------------------
*/
const {
	publicRuntimeConfig: { processEnv },
} = getConfig()

/*
|--------------------------------------------------------------------------
| Create axios instance
|--------------------------------------------------------------------------
*/
export const axiosFAPIInstance = Axios.create({
	baseURL: FAPI_HOST,
	headers: {
		'Content-type': 'application/json',
		Authorization: `Bearer ${processEnv.NEXT_PUBLIC_FAPI_KEY as string}`,
	},
})

/*
|--------------------------------------------------------------------------
| Interceptors
|--------------------------------------------------------------------------
*/
const commonResponseInterceptorSuccess = (response: AxiosResponse): AxiosResponse | Promise<AxiosResponse> => {
	return response
}
const commonResponseInterceptorError = (error: AxiosError): any => {
	if (error?.response) {
		// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION, {
		// 	extra: {
		// 		data: error.response.data,
		// 	},
		// })
		return Promise.reject(error)
	} else if (error?.request) {
		console.error('==============================')
		console.error('[Starton Front API] Request problem.')
		console.error('> Code: ', error.code)
		console.error('> Message: ', error.message)
		console.error('> Status: ', error.status)
		console.error('==============================\n\n')
	} else {
		// SentryService.captureExceptionWithTags(error, SENTRY_TAG_SECTION)
	}

	return Promise.reject(error)
}
axiosFAPIInstance.interceptors.response.use(commonResponseInterceptorSuccess, commonResponseInterceptorError)

/*
|--------------------------------------------------------------------------
| Create custom instance for override and improve axios
|--------------------------------------------------------------------------
*/
export const fapiAxiosInstance = <T>(config: AxiosRequestConfig, options?: AxiosRequestConfig): Promise<T> => {
	// Add cancel token to axios
	// ----------------------------------------------------------------------------
	// eslint-disable-next-line import/no-named-as-default-member, import/no-named-as-default
	const source = Axios.CancelToken.source()
	const promise = axiosFAPIInstance({
		...config,
		...options,
		cancelToken: source.token,
		headers: {
			...options?.headers,
			'Content-type': 'application/json',
			Authorization: `Bearer ${processEnv.NEXT_PUBLIC_FAPI_KEY as string}`,
		},
	}).then(({ data }) => data)

	// @ts-ignore
	promise.cancel = () => {
		source.cancel('Query was cancelled')
	}

	return promise
}
