/*
| Developed by Starton
| Filename : watcherSlice.ts
*/

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@/stores/store'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
export type WatcherDialogStatus = 'closed' | 'information' | 'type' | 'notification-details'

export interface WatcherDialog {
	status: WatcherDialogStatus
	watcherName?: string
	watcherDescription?: string
	type?: string
}

export interface WatcherState {
	dialog: WatcherDialog
}

/*
|--------------------------------------------------------------------------
| INIT STATE
|--------------------------------------------------------------------------
*/
const initialState: WatcherState = {
	dialog: {
		status: 'closed',
		watcherName: '',
		watcherDescription: '',
	},
}

/*
|--------------------------------------------------------------------------
| SLICE
|--------------------------------------------------------------------------
*/
export const watcherSlice = createSlice({
	name: 'watcher',
	initialState,
	reducers: {
		setWatcherDialog: (state, action: PayloadAction<WatcherDialog>) => {
			state.dialog = action.payload
		},
	},
})

/*
|--------------------------------------------------------------------------
| EXPORT SELECTORS
|--------------------------------------------------------------------------
*/
export const selectWatcherDialog = (state: RootState) => state.watcher.dialog

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export const { setWatcherDialog } = watcherSlice.actions
export default watcherSlice.reducer
