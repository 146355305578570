/*
| Developed by Dirupt
| Filename : WhiteLabelConfigDrawer.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { Box, Drawer, styled } from '@mui/material'
import { KeyboardDoubleArrowRightOutlined } from '@mui/icons-material'
import { StartonIconButton } from '@starton/react-ui'
import { NavDefaultConfig } from '@/config/common/nav.config'
import { WhiteLabelNewPresetForm, WhiteLabelPresetList } from '@/components/white-labelling/drawer/views'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface WhiteLabelConfigDrawerProps {
	open: boolean
	handleClose: VoidFunction
}
type WhiteLabelConfigDrawerView = 'list' | 'new'

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const WhiteLabelDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	position: 'relative',
	flexShrink: 0,
	overflow: 'hidden',
	'& .MuiDrawer-paper': {
		gap: theme.spacing(1),
		position: 'relative',
		width: `${NavDefaultConfig.sizes.whiteLabelDrawer.widthOpened}px`,
		maxHeight: '100vh',
		backgroundColor: theme.palette.background.paper2,
		borderRight: `1px solid ${theme.palette.divider}`,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		overflowX: 'hidden',
		overflowY: 'scroll',
		paddingBottom: theme.spacing(3),
		...(!open && {
			// overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: `${NavDefaultConfig.sizes.whiteLabelDrawer.widthClosed}px`,
		}),
	},
}))

const WhiteLabelDrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end',
}))

const CollapseButton = styled(StartonIconButton)(({ theme }) => ({
	'&.MuiIconButton-root': {
		margin: theme.spacing('auto', 1, 2),
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const WhiteLabelConfigDrawer: React.FC<WhiteLabelConfigDrawerProps> = (props) => {
	const [currentView, setCurrentView] = React.useState<WhiteLabelConfigDrawerView>('list')

	// Render
	// ----------------------------------------------------------------------------
	return (
		<WhiteLabelDrawerStyled variant="permanent" anchor={'right'} open={props.open}>
			<WhiteLabelDrawerHeader />
			<Box paddingX={3} flex={1} sx={{ width: `${NavDefaultConfig.sizes.whiteLabelDrawer.widthOpened}px` }}>
				{currentView === 'list' ? <WhiteLabelPresetList handleNew={() => setCurrentView('new')} /> : null}
				{currentView === 'new' ? <WhiteLabelNewPresetForm handleBack={() => setCurrentView('list')} /> : null}
			</Box>
			{currentView === 'list' ? (
				<CollapseButton
					color="secondary"
					variant="contained"
					size="large"
					onClick={props.handleClose}
					data-testid="white-label-drawer-collapse"
				>
					<KeyboardDoubleArrowRightOutlined sx={{ color: 'text.primary' }} />
				</CollapseButton>
			) : null}
		</WhiteLabelDrawerStyled>
	)
}
