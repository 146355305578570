/*
| Developed by Starton
| Filename : common.config.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import getConfig from 'next/config'

/*
|--------------------------------------------------------------------------
| Get public runtime env
|--------------------------------------------------------------------------
*/
const {
	publicRuntimeConfig: { processEnv },
} = getConfig()

/*
|--------------------------------------------------------------------------
| White Labelling
|--------------------------------------------------------------------------
*/
export const FAPI_HOST: string = processEnv?.NEXT_PUBLIC_FAPI_HOST as string
