/*
| Developed by Starton
| Filename : StartonConfirmationDialog.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import {
	StartonButton,
	StartonButtonProps,
	StartonDialog,
	StartonDialogFooter,
	StartonDialogBody,
	StartonDialogHeader,
	StartonDialogProps,
} from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import { Box, Typography } from '@mui/material'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonConfirmationDialogOptions {
	title: string
	description: string
	icon?: React.ReactNode
	confirmationText?: string
	cancellationText?: string
}
export interface StartonConfirmationDialogProps {
	dialogProps?: StartonDialogProps
	confirmationButtonProps?: StartonButtonProps
	cancellationButtonProps?: StartonButtonProps
	onConfirm?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, ...args: Array<unknown>) => void
	onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	children: React.FC<{ setOpenDialog: React.Dispatch<React.SetStateAction<boolean>> }>
	options: StartonConfirmationDialogOptions
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonConfirmationDialog: React.FC<StartonConfirmationDialogProps> = (props) => {
	const { t } = useTranslation()
	const [openDialog, setOpenDialog] = React.useState(false)

	// Methods
	// ----------------------------------------------------------------------------
	/**
	 * Method for close dialog
	 */
	const handleClose = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.preventDefault?.()
			event.stopPropagation?.()
			if (props?.onClose) props.onClose(event)
			setOpenDialog(false)
		},
		[props],
	)

	/**
	 * Method for close dialog if user cancel request
	 */
	const handleCancel = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event?.preventDefault?.()
			event?.stopPropagation?.()
			if (props?.onCancel) props.onCancel(event)
			handleClose(event)
		},
		[handleClose, props],
	)

	/**
	 * Method for close dialog if user accept request
	 */
	const handleConfirm = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, ...args: Array<unknown>) => {
			event?.preventDefault()
			event?.stopPropagation()
			if (props?.onConfirm) props.onConfirm(event, ...args)
			handleClose(event)
		},
		[handleClose, props],
	)

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			{props.children({ setOpenDialog })}
			<StartonDialog fullWidth maxWidth={'md'} open={openDialog} onClose={handleCancel} {...props.dialogProps}>
				<StartonDialogHeader icon={props.options.icon} onClose={() => setOpenDialog(false)}>
					{props.options.title}
				</StartonDialogHeader>
				<StartonDialogBody sx={{ width: '100%' }}>
					<Box display={'flex'} padding={'19px 32px'}>
						<Typography variant={'body2'} whiteSpace={'pre-line'}>
							{props.options.description}
						</Typography>
					</Box>
				</StartonDialogBody>
				<StartonDialogFooter>
					<StartonButton
						{...props.cancellationButtonProps}
						onClick={handleCancel}
						color="secondary"
						data-testid="cancel-button"
					>
						{props?.options?.cancellationText ?? t('common:common.cancel')}
					</StartonButton>
					<StartonButton
						variant={'contained'}
						{...props.confirmationButtonProps}
						onClick={handleConfirm}
						data-testid="confirm-button"
					>
						{props?.options?.confirmationText ?? t('common:common.confirm')}
					</StartonButton>
				</StartonDialogFooter>
			</StartonDialog>
		</React.Fragment>
	)
}
