/*
| Developed by Starton
| Filename : startonScrollbar.ts
| Author : Calixte De Tourris (calixte@starton.com)
*/

import { Theme } from '@mui/material'

export const startonScrollbar = (theme: Theme) => {
	return {
		/* width */
		'&::-webkit-scrollbar': {
			width: '8px',
			height: '8px',
		},

		/* Track */
		'&::-webkit-scrollbar-track': {
			background: theme.palette.divider,
		},

		/* Handle */
		'&::-webkit-scrollbar-thumb': {
			background: theme.palette.background.paper,
			borderRadius: 5,
			borderLeft: `1px solid ${theme.palette.divider}`,
		},

		'&::-webkit-scrollbar-corner': {
			background: 'transparent',
		},
	}
}
