/*
| Developed by Starton
| Filename : StartonAddressField.tsx.tsx
| Author : Maxime Aiguier (maxime@starton.com)
*/

import { Typography, Box, TypographyProps, BoxProps } from '@mui/material'
import { StartonUIUtils } from '@starton/react-ui'
import React from 'react'
import Link from 'next/link'
import { StartonCopy, StartonCopyProps } from '../StartonCopy'
import { TypographyInputText } from '../typographies/typographies'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface StartonAddressFieldProps {
	address: string
	concat?: boolean
	typographyProps?: TypographyProps
	href?: string
	target?: string
	boxProps?: BoxProps & {
		'data-dd-privacy'?: string
	}
	copyButtonProps?: Omit<StartonCopyProps, 'value'>
	isTableText?: boolean
	onClick?: VoidFunction
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const StartonAddressField: React.FC<StartonAddressFieldProps> = (props) => {
	const { address, concat, typographyProps, href, target, boxProps, copyButtonProps, isTableText } = props
	const Text = isTableText ? TypographyInputText : Typography

	// Memoization
	//--------------------------------------------------------------------------
	const trunkedAddress = React.useMemo(() => {
		if (!address) return ''
		if (!concat) return address
		return StartonUIUtils.truncateCID(address)
	}, [address, concat])

	const url = React.useMemo(() => {
		if (!href) return `https://etherscan.io/address/${address ?? ''}`
		return href
	}, [href, address])

	// Render
	//--------------------------------------------------------------------------
	return (
		<Box display={'flex'} alignItems={'center'} gap={1} data-dd-privacy="mask" {...boxProps}>
			{address ? (
				<Link
					href={url}
					title={'Check this address'}
					passHref
					legacyBehavior
					data-testid="starton-address-field-link"
				>
					<a
						target={target ? target : '_blank'}
						onClick={(e) => {
							props.onClick?.()
							if (target && target !== '_blank') return
							e.stopPropagation()
						}}
					>
						<Text
							color="primary.main"
							data-testid="starton-address-field-address"
							sx={{ whiteSpace: 'nowrap', textDecoration: 'underline', ...typographyProps?.sx }}
							{...typographyProps}
							maxWidth="45ch"
							textOverflow="ellipsis"
							overflow="hidden"
						>
							{trunkedAddress}
						</Text>
					</a>
				</Link>
			) : (
				<Typography color="text.secondary" sx={{ whiteSpace: 'nowrap' }} {...typographyProps}>
					NA
				</Typography>
			)}
			{address ? (
				<StartonCopy value={address ?? ''} data-testid="starton-address-field-copy" {...copyButtonProps} />
			) : null}
		</Box>
	)
}
StartonAddressField.defaultProps = {
	concat: true,
}
