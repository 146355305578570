import useSwr from 'swr'
import type {
  Key,
  SWRConfiguration
} from 'swr'
import type {
  BadRequestException
} from '../../model/badRequestException'
import type {
  CouldNotFindResource
} from '../../model/couldNotFindResource'
import type {
  CreateNetworkDto
} from '../../model/createNetworkDto'
import type {
  GetAllNetwork200
} from '../../model/getAllNetwork200'
import type {
  GetAllNetworkParams
} from '../../model/getAllNetworkParams'
import type {
  Network
} from '../../model/network'
import type {
  UpdateNetworkDto
} from '../../model/updateNetworkDto'
import { axiosCustomInstance } from '../../../mutators/axios-custom-instance';
import type { ErrorType } from '../../../mutators/axios-custom-instance';


  
  // eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * Fetches a paginated list of networks available in the current project.
 * @summary Retrieve the list of supported networks
 */
export const getAllNetwork = (
    params: GetAllNetworkParams,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<GetAllNetwork200>(
      {url: `/v3/network`, method: 'GET',
        params
    },
      options);
    }
  

export const getGetAllNetworkKey = (params: GetAllNetworkParams,) => [`/v3/network`, ...(params ? [params]: [])] as const;

    
export type GetAllNetworkQueryResult = NonNullable<Awaited<ReturnType<typeof getAllNetwork>>>
export type GetAllNetworkQueryError = ErrorType<BadRequestException>

/**
 * @summary Retrieve the list of supported networks
 */
export const useGetAllNetwork = <TError = ErrorType<BadRequestException>>(
 params: GetAllNetworkParams, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getAllNetwork>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetAllNetworkKey(params) : null);
  const swrFn = () => getAllNetwork(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Adds a new network to the project, available only for Enterprise plans.
 * @summary Create a new network (Enterprise)
 */
export const createNetwork = (
    createNetworkDto: CreateNetworkDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<Network>(
      {url: `/v3/network`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createNetworkDto
    },
      options);
    }
  

/**
 * Retrieves detailed information about a specific network based on its name.
 * @summary Fetch a specific network
 */
export const getOneNetwork = (
    name: string,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<Network>(
      {url: `/v3/network/${name}`, method: 'GET'
    },
      options);
    }
  

export const getGetOneNetworkKey = (name: string,) => [`/v3/network/${name}`] as const;

    
export type GetOneNetworkQueryResult = NonNullable<Awaited<ReturnType<typeof getOneNetwork>>>
export type GetOneNetworkQueryError = ErrorType<BadRequestException | CouldNotFindResource>

/**
 * @summary Fetch a specific network
 */
export const useGetOneNetwork = <TError = ErrorType<BadRequestException | CouldNotFindResource>>(
 name: string, options?: { swr?:SWRConfiguration<Awaited<ReturnType<typeof getOneNetwork>>, TError> & { swrKey?: Key, enabled?: boolean }, request?: SecondParameter<typeof axiosCustomInstance> }

  ) => {

  const {swr: swrOptions, request: requestOptions} = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!(name)
    const swrKey = swrOptions?.swrKey ?? (() => isEnabled ? getGetOneNetworkKey(name) : null);
  const swrFn = () => getOneNetwork(name, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query
  }
}

/**
 * Modifies the details of a specific network based on its unique name. This feature is only available to Entreprise plans. 
 * @summary Update specific network details (Enterprise)
 */
export const updateNetwork = (
    name: string,
    updateNetworkDto: UpdateNetworkDto,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<Network>(
      {url: `/v3/network/${name}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: updateNetworkDto
    },
      options);
    }
  

/**
 * Removes a network from the project, available only for Enterprise plans.
 * @summary Delete a network (Enterprise)
 */
export const deleteNetwork = (
    name: string,
 options?: SecondParameter<typeof axiosCustomInstance>) => {
      return axiosCustomInstance<number>(
      {url: `/v3/network/${name}`, method: 'DELETE'
    },
      options);
    }
  

