/*
| Developed by Starton
| Filename : SupportDialog.tsx
| Author : Calixte De Tourris (calixte@starton.com)
*/

import React from 'react'
import { StartonDialog } from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import { SupportDialogBody } from './SupportDialogBody'
import { StartonDialogHeaderFullscreen } from '@/components/common/StartonDialogHeaderFullscreen'
import { useAppDispatch, useAppSelector } from '@/stores/hooks'
import { selectOpenSupportDialog, setOpenSupportDialog } from '@/stores/common/commonSlice'
import { Logo } from '@/components/white-labelling/components/Logo'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SupportDialogProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SupportDialog: React.FC<SupportDialogProps> = () => {
	const { t } = useTranslation()
	const openSupport = useAppSelector(selectOpenSupportDialog)
	const dispatch = useAppDispatch()

	// Methods
	//--------------------------------------------------------------------------
	const handleClose = () => {
		dispatch(setOpenSupportDialog(false))
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<StartonDialog open={openSupport} onClose={handleClose} fullScreen>
			<StartonDialogHeaderFullscreen title={t('common:support.title')} icon={<Logo />} onClose={handleClose} />
			<SupportDialogBody handleClose={handleClose} />
		</StartonDialog>
	)
}
