/*
| Developed by Starton
| Filename : wagmi.config.ts
| Author : Calixte De Tourris (calixte@starton.com)
*/

import React from 'react'
import { createConfig, http, WagmiProvider as OriginalWagmiProvider } from 'wagmi'
import { injected } from 'wagmi/connectors'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Chain, mainnet } from 'viem/chains'
import { Network } from '@/services/api/starton/model'
import { useNetworkGetAllCached } from '@/hooks/networks/networksHooks'

/*
|--------------------------------------------------------------------------
| TEMPORARY FIX
|--------------------------------------------------------------------------
*/
// @ts-ignore
// const rpcUrls: Record<string, Chain['rpcUrls']> = {
// 	'arbitrum-goerli': arbitrumGoerli.rpcUrls,
// 	'arbitrum-mainnet': arbitrum.rpcUrls,
// 	'astar-mainnet': {
// 		default: {
// 			http: ['https://1rpc.io/astr'],
// 		},
// 		public: {
// 			http: ['https://1rpc.io/astr'],
// 		},
// 	},
// 	'avalanche-fuji': avalancheFuji.rpcUrls,
// 	'avalanche-mainnet': avalanche.rpcUrls,
// 	'binance-mainnet': bsc.rpcUrls,
// 	'binance-testnet': bscTestnet.rpcUrls,
// 	'ethereum-goerli': goerli.rpcUrls,
// 	'ethereum-mainnet': mainnet.rpcUrls,
// 	'fantom-mainnet': fantom.rpcUrls,
// 	'fantom-testnet': fantomTestnet.rpcUrls,
// 	'polygon-mainnet': polygon.rpcUrls,
// 	'polygon-mumbai': polygonMumbai.rpcUrls,
// 	'polygon-zkevm-mainnet': polygonZkEvm.rpcUrls,
// 	'polygon-zkevm-testnet': polygonZkEvmTestnet.rpcUrls,
// 	'tezos-evm-ghostnet': {
// 		default: {
// 			http: ['https://evm.ghostnet-evm.tzalpha.net'],
// 		},
// 		public: {
// 			http: ['https://evm.ghostnet-evm.tzalpha.net'],
// 		},
// 	},
// }

/*
|--------------------------------------------------------------------------
| Wagmi configuration
|--------------------------------------------------------------------------
*/
// export const config = createConfig({
// 	chains: [mainnet, polygonMumbai],
// 	connectors: [injected({ target: 'metaMask' })],
// 	ssr: true,
// 	transports: {
// 		[mainnet.id]: http(),
// 		[polygonMumbai.id]: http(),
// 	},
// })

/*
|--------------------------------------------------------------------------
| Query Client
|--------------------------------------------------------------------------
*/
const queryClient = new QueryClient()

/*
|--------------------------------------------------------------------------
| Wagmi Chains
|--------------------------------------------------------------------------
*/
export const WagmiProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const networks = useNetworkGetAllCached()
	// const { networks } = useWhiteLabelNetwork()

	// Create wagmi config
	// ----------------------------------------------------------------------------
	const wagmiConfig = React.useMemo(() => {
		// if (!networks?.items || !networks?.items.length) return null
		if (!networks || !networks.length) return null

		const _chains: Array<Chain> = networks
			.filter((network: Network) => network.enableExternalWallet)
			.map((network: Network) => ({
				id: network.chainId,
				name: network.displayName,
				testnet: network.testnet,
				blockExplorers: {
					default: {
						name: 'Default',
						url: network.explorerUrl,
					},
				},
				nativeCurrency: { decimals: network.decimal, name: network.symbol, symbol: network.symbol },
				rpcUrls: {
					default: {
						http: [network?.loadbalancerUrl ?? `${process.env.NEXT_PUBLIC_RPC_NODE_URL}/${network.name}`],
						webSocket: [
							network?.loadbalancerUrl ?? `${process.env.NEXT_PUBLIC_RPC_NODE_URL}/${network.name}`,
						],
					},
					public: {
						http: [network?.loadbalancerUrl ?? `${process.env.NEXT_PUBLIC_RPC_NODE_URL}/${network.name}`],
						webSocket: [
							network?.loadbalancerUrl ?? `${process.env.NEXT_PUBLIC_RPC_NODE_URL}/${network.name}`,
						],
					},
				},
				// rpcUrls: rpcUrls[network.name],
				network: network.name,
			}))

		return createConfig({
			chains: [mainnet, ..._chains],
			connectors: [
				injected({
					target: 'metaMask',
				}),
			],
			ssr: true,
			transports: {
				[mainnet.id]: http(),
				..._chains.reduce((acc, chain) => {
					// @ts-ignore
					acc[chain.id] = http()
					return acc
				}, {}),
			},
		})
	}, [networks])

	// Render
	// ----------------------------------------------------------------------------
	if (!wagmiConfig) return <React.Fragment>{children}</React.Fragment>

	return (
		<OriginalWagmiProvider config={wagmiConfig}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</OriginalWagmiProvider>
	)
}
