/*
| Developed by Starton
| Filename : SupportDialogBodySuccess.tsx
| Author : Calixte De Tourris (calixte@starton.com)
*/

import React from 'react'
import { Avatar, Box, styled, Typography } from '@mui/material'
import { StartonButton } from '@starton/react-ui'
import useTranslation from 'next-translate/useTranslation'
import { ArrowBackOutlined, CheckCircleOutlineOutlined } from '@mui/icons-material'
import { motion } from 'framer-motion'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SupportDialogBodySuccessProps {
	handleClose: VoidFunction
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const SupportDialogBodySuccessStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	paddingTop: theme.spacing(5),
	gap: theme.spacing(5),
}))

const IconStyled = styled(Avatar)(({ theme }) => ({
	backgroundColor: 'transparent',
	'&, & svg': {
		width: theme.spacing(8),
		height: theme.spacing(8),
	},
}))

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SupportDialogBodySuccess: React.FC<SupportDialogBodySuccessProps> = (props) => {
	const { handleClose } = props
	const { t } = useTranslation()

	// Render
	//--------------------------------------------------------------------------
	return (
		<motion.div>
			<SupportDialogBodySuccessStyled>
				<IconStyled variant="square">
					<CheckCircleOutlineOutlined sx={{ color: 'primary.main' }} />
				</IconStyled>
				<Box display="flex" flexDirection="column" gap={3} textAlign="center">
					<Typography variant="h1" textTransform="uppercase">
						{t('common:support.success.title')}
					</Typography>
					<Typography variant="body1" color="text.secondary">
						{t('common:support.success.description')}
					</Typography>
				</Box>
				<StartonButton
					variant="contained"
					color="primary"
					startIcon={<ArrowBackOutlined />}
					onClick={handleClose}
					size="large"
				>
					{t('common:support.success.back')}
				</StartonButton>
			</SupportDialogBodySuccessStyled>
		</motion.div>
	)
}
