/*
| Developed by Starton
| Filename : sessionStorage.service.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
import { CookiesService } from '../../cookies/cookies.service'

export type SessionStorageName = 'starton.current-project-id'

/*
|--------------------------------------------------------------------------
| Service
|--------------------------------------------------------------------------
*/
export class SessionStorageService {
	// Utils
	// ----------------------------------------------------------------------------
	private static isClientSide(): boolean {
		return typeof window !== 'undefined'
	}

	// Common
	// ----------------------------------------------------------------------------
	static getItem(key: SessionStorageName) {
		if (this.isClientSide()) {
			return window.sessionStorage.getItem(key)
		}
	}

	static setItem<T extends string>(key: SessionStorageName, data: T) {
		if (this.isClientSide()) {
			window.sessionStorage.setItem(key, data)
			CookiesService.setLastProjectId(data)
		}
	}

	static removeItem(key: SessionStorageName) {
		if (this.isClientSide()) {
			window.sessionStorage.removeItem(key)
			CookiesService.deleteLastProjectId()
		}
	}

	static clear() {
		if (this.isClientSide()) {
			window.sessionStorage.clear()
		}
	}
}
