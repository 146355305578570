/*
| Developed by Starton
| Filename : project-builder.ts
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import { API_URL, UrlsConfig } from './urls.config'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export type ProjectUrlBuilderCreateUrlParams = string | ((urls: typeof UrlsConfig) => string)

/*
|--------------------------------------------------------------------------
| Helpers
|--------------------------------------------------------------------------
*/
export const createNetworkUrl = (scanUrl: string, scanType: 'tx' | 'address', address: string) => {
	return `${scanUrl}/${scanType}/${address}`
}

export const providerRedirect = (provider: string, platform?: string) => {
	if (platform) {
		return String(API_URL) + '/auth/' + provider + '/redirect' + '?platform=' + platform
	}

	return String(API_URL) + '/auth/' + provider + '/redirect'
}

/*
|--------------------------------------------------------------------------
| Services
|--------------------------------------------------------------------------
*/
class ProjectUrlBuilder {
	private currentProjectId: string | undefined

	create(param: ProjectUrlBuilderCreateUrlParams) {
		const route = typeof param === 'function' ? param(UrlsConfig) : param

		return `/projects/${this.currentProjectId}${route}`
	}

	setProjectId(projectId: string) {
		this.currentProjectId = projectId
	}
}

export const projectUrl = new ProjectUrlBuilder()
