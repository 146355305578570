/*
| Developed by Starton
| Filename : tipsSlice.ts
| Author : Calixte De Tourris (calixte@starton.io)
*/

import { EntityId, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { TipsAttributes } from '@/services/fapi'

/*
|--------------------------------------------------------------------------
| Entity adapter
|--------------------------------------------------------------------------
*/
export const tipAdapter = createEntityAdapter<TipsAttributes>({
	// @ts-ignore
	selectId: (tip: { id: EntityId }) => tip.id,
	sortComparer: (a, b) => a.title.localeCompare(b.title),
})

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/
export const tipSlice = createSlice({
	name: 'tips',
	initialState: tipAdapter.getInitialState(),
	reducers: {
		tipsSetMany: tipAdapter.setMany,
	},
})

/*
|--------------------------------------------------------------------------
| Export selectors
|--------------------------------------------------------------------------
*/
export const tipsSelectors = tipAdapter.getSelectors<RootState>((state) => state.tips)
export const tipsGetAllCached = (state: RootState) => tipsSelectors.selectAll(state)

/*
|--------------------------------------------------------------------------
| Export
|--------------------------------------------------------------------------
*/
export const { tipsSetMany } = tipSlice.actions
export default tipSlice.reducer
