/*
| Developed by Starton
| Filename : useGlobalTranslation.tsx
| Author : Philippe DESPLATS (philippe@starton.com)
*/

import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { TranslationQuery } from 'next-translate'
import { useWhiteLabel } from '@/components/white-labelling/context/whitelabel.context'

export const useGlobalTranslation = (defaultNS?: string) => {
	const whiteLabel = useWhiteLabel()
	const { t, lang } = useTranslation(defaultNS)

	const newT = React.useCallback(
		<T = string,>(
			i18nKey: string | TemplateStringsArray,
			query?: TranslationQuery | null,
			options?: {
				returnObjects?: boolean
				fallback?: string | Array<string>
				default?: T | string
				ns?: string
			},
		) => {
			return t(
				i18nKey,
				{
					...query,
					presetName: whiteLabel.name,
				},
				options,
			)
		},
		[t, whiteLabel.name],
	)

	return { t: newT, lang }
}
