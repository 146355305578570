/*
| Developed by Starton
| Filename: SettingsPassword.tsx
| Author: Calixte DE TOURRIS (calixte.dndt@gmail.com)
*/

import React from 'react'
import { Box, Typography } from '@mui/material'
import useTranslation from 'next-translate/useTranslation'
import { StartonButton } from '@starton/react-ui'
import { AxiosError } from 'axios'
import { useWhoami } from '@/services/auth-api/endpoints/users'
import { passwordUpdate } from '@/services/auth-api/endpoints/password'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
export interface SettingsPasswordProps {}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const SettingsPassword: React.FC<SettingsPasswordProps> = () => {
	const { t } = useTranslation()
	const [emailSent, setEmailSent] = React.useState(false)
	const [isSubmitting, setIsSubmitting] = React.useState(false)
	const [error, setError] = React.useState('')
	const { data: user } = useWhoami()

	// Methods
	//--------------------------------------------------------------------------
	const handleResetPassword = async () => {
		setEmailSent(false)
		setIsSubmitting(true)
		setError('')
		try {
			await passwordUpdate()
			setEmailSent(true)
		} catch (error) {
			if (error instanceof AxiosError) {
				if (error.response?.status === 429) {
					setError(t('auth/settings:password.emailAlreadySent'))
					setIsSubmitting(false)
					return
				}
			}
			setError(t('auth/settings:password.unknownError'))
		}
		setIsSubmitting(false)
	}

	// Render
	//--------------------------------------------------------------------------
	return (
		<Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
			<Typography variant={'h2'} marginBottom={1}>
				{user?.connections.includes('password')
					? t('auth/settings:password.updateTitle')
					: t('auth/settings:password.createTitle')}
			</Typography>
			<StartonButton
				onClick={handleResetPassword}
				variant="contained"
				isLoading={isSubmitting}
				disabled={isSubmitting}
			>
				{user?.connections.includes('password')
					? t('auth/settings:password.updateButton')
					: t('auth/settings:password.createButton')}
			</StartonButton>
			{emailSent ? (
				<Typography variant="body2" color="success.main">
					{t('auth/settings:password.emailSent')}
				</Typography>
			) : null}
			{error ? (
				<Typography variant="body2" color="error">
					{error}
				</Typography>
			) : null}
		</Box>
	)
}
